import styles from "./ContactUsPage.module.scss";
import { Button, Input, TextArea } from "../../components";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { contactUsSchema } from "@podcasts/schemas";
import { useContactUs } from "@podcasts/hooks";

export function ContactUsPage() {
  const { sendContactUsForm } = useContactUs();
  
  return (
    <div
      className={`${styles["contact-us-container"]} container page mobile-container`}
    >
      <section className={`${styles["contact-us"]}`}>
        <div className={`${styles["contact-us-mobile"]} text-center`}>
          <h2 className={`${styles["mabile-title"]}`}>
            <span className="orange-yellow-color">Contact</span> us
          </h2>
          <p>
            Below is the list of approved podcasts. To suggest a podcast, please{" "}
            <Link className={` ${styles["link"]}`} to="/link">
              send us an email
            </Link>{" "}
            with your suggestion.
          </p>
        </div>
        <div className={`${styles["row-mobile"]} row`}>
          <div className="col-lg-6 col-12">
            <h2 className={`${styles["stepTitle"]}`}>
              <span className="orange-yellow-color">Contact</span> us
            </h2>
            <div className={`${styles["contact-us__info"]}`}>
              <div className={`${styles["contact-us__info-ico"]}`}>
                <i className="icon-contact-info"></i>
              </div>
              <p>info@podcastfellowship.org</p>
              <p>(650) 427-9680</p>
            </div>
            <div className={`${styles["contact-us__info"]}`}>
              <div className={`${styles["contact-us__info-ico"]}`}>
                <i className="icon-map-pin"></i>
              </div>
              <p>The Podcast Fellowship</p>
              <p>24786 Sussex St</p>
              <p>Oak Park MI 48237</p>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <Formik
              initialValues={{
                email: "",
                message: "",
                first_name: "",
                last_name: "",
                phone: "",
              }}
              validationSchema={contactUsSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                sendContactUsForm(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <div className={`${styles["form"]}`}>
                  <div className={`${styles["formRow"]} row`}>
                    <div className="col-lg-6 col-12">
                      <Input
                        label="First Name *"
                        name="first_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        error={
                          errors.first_name && touched.first_name
                            ? "First name is required"
                            : null
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-12">
                      <Input
                        label="Last Name *"
                        name="last_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        error={
                          errors.last_name && touched.last_name
                            ? "Last name is required"
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className={`${styles["formRow"]} row`}>
                    <div className="col-lg-6 col-12">
                      <Input
                        label="Email *"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={
                          errors.email && touched.email
                            ? errors.email
                            : null
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-12">
                      <Input
                        label="Phone Number *"
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        error={
                          errors.phone && touched.phone
                            ? errors.phone
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className={`${styles["formRow"]} row`}>
                    <div className="col-12">
                      <TextArea
                        rows={7}
                        label="Message"
                        placeholder="Write your message..."
                        name="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        error={
                          errors.message && touched.message
                            ? "Please include a message"
                            : null
                        }
                      />
                    </div>
                  </div>

                  <div className={`${styles["button-row"]} row`}>
                    <div className="col-lg">
                      <Button name="Submit" onClick={handleSubmit} />
                    </div>
                    <div className="col-lg"></div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
}
export default ContactUsPage;
