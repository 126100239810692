// import styles from './Footer.module.scss';
import React, { useEffect, useState } from "react";
import styles from "./LoginPage.module.scss";
import { Button, Input } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { signIn } from "@podcasts/store/user";
import { useDispatch, useSelector } from "react-redux";
import { loginSchema } from "@podcasts/schemas";
import { useIsAuthenticated } from "@podcasts/hooks";
import { userSelector } from "@podcasts/store/user";
import { usePrevious } from "../../hooks/usePrevious";

export function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  const [error, setError ] = useState(null);
  const { error: loginErrorMessage } = useSelector(userSelector) as any;
  const prevError = usePrevious(loginErrorMessage);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/podcasts/featured");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (loginErrorMessage) {
      setError(loginErrorMessage);
    }
  }, [loginErrorMessage]);

  return (
    <div className={`${styles["login-wrapp"]}`}>
      <div className={`${styles["login-decor"]}`}>
        <div></div>
      </div>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const { error } = await dispatch(signIn(values));
          if (!error) {
            navigate("/podcasts/featured", { replace: true });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <div className={`${styles["form"]}`}>
            <div className="flex-column d-flex align-items-center">
              <h2 className={styles["header"]}>Login</h2>
              {error && error !== prevError && (<span className={`${styles["errorMessage"]}`}>{error}</span>)}
              <div className={`${styles["row"]}`}>
                <Input
                  label="Email *"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={
                    errors.email && touched.email ? errors.email : null
                  }
                />
              </div>
              <div className={`${styles["row"]}`}>
                <div className={`${styles["password-wrapp"]}`}>
                  <Input
                    label="Password *"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={
                      errors.password && touched.password
                        ? "Password is required"
                        : null
                    }
                  />
                  <Link to={"/forgot-password"}>Forgot password?</Link>
                </div>
              </div>
              <div className={`${styles["row"]}`}>
                <Button name="Log in" onClick={handleSubmit} />
              </div>
              <p className={` ${styles["applyNow"]}`}>
                <span>Don't have an account? </span>
                <Link className={` ${styles["link"]}`} to="/register">
                  Apply today
                </Link>
              </p>
            </div>
          </div>
        )}
      </Formik>
      <div className={`${styles["login-decor"]}`}>
        <div></div>
      </div>
    </div>
  );
}

export default LoginPage;
