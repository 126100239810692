import FeaturedItem from "./components/FeaturedItem/FeaturedItem";
import styles from "./FeaturedPage.module.scss";
import { Button } from "../../../../components";
import { userSelector } from "@podcasts/store/user"
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FeaturedSliderItem from "./components/FeaturedSliderItem/FeaturedSliderItem";
import { Link } from "react-router-dom";
import FeaturedTopicItem from "./components/FeaturedTopicItem/FeaturedTopicItem";
import { UserLevel } from "../../../../components/UserLevel/UserLevel";
import { useFeaturedPage } from "@podcasts/hooks";
import { resetState } from "@podcasts/store/dashboard";

export function FeaturedPage() {

  const { 
    topic,
    course, 
    speaker,
    topicsCount,
    coursesCount,
    speakersCount,
    podcasts,
    isSavedCourse,
    isSavedTopic,
    isSavedSpeaker,
    onSaveSpeaker,
    onSavePodcast,
    onSaveTopic,
    onSaveCourse,
    saved_podcasts_ids
  } = useFeaturedPage()
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: (dots: any) => (
      <div className={`${styles["dots-wrapp"]}`}>
        <ul className={`${styles["dots-list"]}`}> {dots} </ul>
      </div>
    ),
    customPaging: (i: number) => <></>,
    afterChange: (current: number) => setActiveSlide(current),
  };

  const { user } = useSelector(userSelector) as any;
  const [activeSlide, setActiveSlide] = useState(0);

  const dispatch = useDispatch();

  const sliderRef: any = useRef();

  const ReactSlider = Slider as any;

  useEffect( () => () => dispatch(resetState()), [] );

  return (
    <div className={`${styles["featured"]}`}>
      <div
        className={`${styles["featured-header"]} d-flex justify-content-between align-items-center`}
      >
        <h2>Featured Podcasts</h2>
        <UserLevel level={podcasts[activeSlide]?.level || " --"} />
      </div>
      <div className={`${styles["slider-top-box"]}`}>
        <ReactSlider
          ref={sliderRef}
          className={`${styles["slider-top"]}`}
          {...settings}
        >
          {podcasts.map((s: any, i: number) => (
            <FeaturedSliderItem key={i} {...s} />
          ))}
        </ReactSlider>
        <div className={`${styles["btn-wrap"]}`}>
          <button
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
            className={`${styles["prev"]}`}
          >
            <i className="icon-chevron-right"></i>
          </button>
          <button
            onClick={() => {
              sliderRef.current.slickNext();
            }}
            className={`${styles["next"]}`}
          >
            <i className="icon-chevron-right"></i>
          </button>
        </div>
      </div>
      <div className={`${styles["featured-topic-box"]}`}>
        <div
          className={`${styles["header"]} d-flex justify-content-between align-items-center`}
        >
          <h3 className={styles["title"]}>Featured Topic</h3>
          <div>
            <Link to={"/podcasts/topics"}><Button name="View all Topics" onClick={() => { }} /></Link>
          </div>
          
        </div>
        {topic && <FeaturedTopicItem {...topic} podcastsCount={topicsCount} isUser={user} saved={isSavedTopic(topic.id)} saved_ids={saved_podcasts_ids} onSavePodcast={onSavePodcast} onToggleFavorite={() => onSaveTopic()}
        />}
      </div>

      <div className={`${styles["featured-topic-box"]}`}>
        <div
          className={`${styles["header"]} d-flex justify-content-between align-items-center`}
        >
          <h3 className={styles["title"]}>Featured  Course</h3>
          <div>
            <Link to={"/podcasts/courses"}><Button name="View all Courses" onClick={() => { }} /></Link>
          </div>
        </div>
        {course && <FeaturedTopicItem {...course} podcastsCount={coursesCount} isUser={user} saved={isSavedCourse(course.id)} saved_ids={saved_podcasts_ids} type="courses" onSavePodcast={onSavePodcast} onToggleFavorite={() => onSaveCourse()} />}
      </div>

      <div className={`${styles["featured-topic-box"]}`}>
        <div
          className={`${styles["header"]} d-flex justify-content-between align-items-center`}
        >
          <h3 className={styles["title"]}>Featured  Speaker</h3>
          <div>
            <Link to={"/podcasts/speakers"}><Button name="View all Speakers" onClick={() => { }} /></Link>
          </div>
        </div>
        {speaker && <FeaturedTopicItem {...speaker} podcastsCount={speakersCount} title={`${speaker.title} ${speaker.fname} ${speaker.lname}`} saved_ids={saved_podcasts_ids} isUser={user} onSavePodcast={(onSavePodcast)} saved={isSavedSpeaker(speaker.id)} type="speakers" onToggleFavorite={() => onSaveSpeaker()} />}
      </div>
    </div>
  );
}

export default FeaturedPage;
