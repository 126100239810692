import { Input, InputSelect } from "../../../../components";
import styles from "./EditProfileForm.module.scss";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "@podcasts/store/user";
import { updateStudentProfile } from "@podcasts/store/user";
import { editProfileSchema } from '@podcasts/schemas';
import { get, omitBy, isNil } from "lodash";
import { levelOptions } from '@podcasts/constants';

export function EditProfileForm() {
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector) as any;
  let navigate = useNavigate();

  return (
    <div>
      <Formik
        initialValues={{
          email: {
            new_email: null,
            confirm_email: null,
          },
          password: {
            current_password: null,
            new_password: null,
            confirm_password: null,
          },
          phone: get(user, "phone", null),
          city: get(user, "city", null),
          school: get(user, "school", null),
          // jewish_education: '',
          difficulty: get(user, "difficulty", null),
        }}
        validationSchema={editProfileSchema}
        onSubmit={(values, { setSubmitting }) => {
          const email = get(values, "email.new_email", null);
          const password = get(values, "password.new_password", null);
          const current_password = get(
            values,
            "password.current_password",
            null
          );

          const payload = { ...values, email, password, current_password };

          dispatch(updateStudentProfile(omitBy(payload, isNil)));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <div className={`${styles["edit-profile-form"]}`}>
            <div
              className={`${styles["back-box"]} justify-content-between align-items-center`}
            >
              <Link to={"/profile"} className="d-flex align-items-center">
                <span className={`${styles["chevron-box"]}`}>
                  <i className="icon-chevron-right"></i>
                </span>
                <span>Edit profile</span>
              </Link>
              <button
                className={`${styles["save-btn"]} d-flex align-items-center`}
              >
                <span>Save</span>
                <i className="icon-save"></i>
              </button>
            </div>
            <div className="row">
              <div className={`${styles["formCol"]} col-lg-6`}>
                <div className={`${styles["formFieldset"]}`}>
                  <h5 className={`${styles["fieldset-title"]}`}>
                    Edit your email:
                  </h5>
                  <div className="row">
                    <div className="col-lg">
                      <Input
                        type="email"
                        label="Your new email address:"
                        name="email.new_email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email.new_email}
                        error={
                          errors.email?.new_email && touched.email?.new_email
                            ? errors.email?.new_email
                            : null
                        }
                      />
                    </div>
                    <div className="col-lg">
                      <Input
                        type="email"
                        label="Confirm email:"
                        name="email.confirm_email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email?.confirm_email}
                        error={
                          errors.email?.confirm_email &&
                          touched.email?.confirm_email
                            ? errors.email?.confirm_email
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={`${styles["formFieldset"]}`}>
                  <h5 className={`${styles["fieldset-title"]}`}>
                    Edit your password:
                  </h5>
                  <div className="row">
                    <div className={`${styles["mobile-mb"]} col-12`}>
                      <Input
                        type="password"
                        label="Enter your current password:"
                        name="password.current_password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password.current_password}
                        error={
                          errors.password?.current_password &&
                          touched.password?.current_password
                            ? errors.password?.current_password
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg">
                      <Input
                        type="password"
                        label="Your new password:"
                        name="password.new_password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password.new_password}
                        error={
                          errors.password?.new_password &&
                          touched.password?.new_password
                            ? errors.password?.new_password
                            : null
                        }
                      />
                    </div>
                    <div className="col-lg">
                      <Input
                        type="password"
                        label="Confirm password:"
                        name="password.confirm_password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password.confirm_password}
                        error={
                          errors.password?.confirm_password &&
                          touched.password?.confirm_password
                            ? errors.password?.confirm_password
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles["formCol"]} col-lg-6`}>
                <div className={`${styles["formFieldset"]}`}>
                  <h5 className={`${styles["fieldset-title"]}`}>
                    Edit your personal information:
                  </h5>
                  <div className="row">
                    <div className="col-lg">
                      <Input
                        type="tel"
                        label="Phone number:"
                        placeholder="(123) 123 1234"
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        error={errors["phone"] && touched["phone"] ? (errors["phone"] as any) : null}
                      />
                    </div>
                    <div className="col-lg">
                      <Input
                        label="City:"
                        placeholder="Toronto, ON"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        error={errors.city && touched.city ? "Required" : null}
                      />
                    </div>
                  </div>
                  <div className="row">
                            <div className={`${styles['mobile-mt']} col-12`}>
                                <Input 
                                    label='Education:' 
                                    placeholder='University of Toronto'                            
                                    name='school'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.school}
                                    error={errors.school && touched.school ? "Required" : null}
                                />
                            </div>
                        </div>
                </div>
                <div className={`${styles["formFieldset"]}`}>
                  <h5 className={`${styles["fieldset-title"]}`}>
                    Choose your level:
                  </h5>
                  <div className="row">
                    <div className="col-12">
                      <InputSelect
                        label="&nbsp;"
                        options={levelOptions}
                        placeholder="Please select:"
                        name="difficulty"
                        onChange={({ value }: any) => {
                          setFieldValue("difficulty", value);
                        }}
                        onBlur={handleBlur}
                        value={values.difficulty}
                        error={
                          errors.difficulty && touched.difficulty
                            ? "Required"
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles["save-box"]}`}>
              <button
                onClick={() => {
                  handleSubmit();
                  navigate('../profile');
                }}
                className={`${styles["save-btn"]} d-flex justify-content-center align-items-center`}
              >
                <span>Save</span>
                <i className="icon-save"></i>
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default EditProfileForm;
