/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { Link } from "react-router-dom";
import styles from "./ReferFriendForm.module.scss";
import ReferForm from "./FormItem/ReferForm";
import { refereAfriend, useUserRole } from "@podcasts/hooks";
import { userSelector } from "@podcasts/store/user";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export function ReferFriendForm() {
  const initialValue = {
    data: [{
      email: "",
      first_name: "",
      last_name: "",
      phone: ""
    }]
  };

  const referFriendsRequest = refereAfriend();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useSelector(userSelector) as any;

  const { isStudent } = useUserRole();

  const onSubmit = async (errors, values) => {
    if (errors?.data?.length) return false;
    try {
      await referFriendsRequest.mutateAsync(values.data);
      setSuccess(true);
    } catch (e: any) {
      setError(e.response.data.message);
    }
  };

  const copyReferralLink = async(e) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(user.referral_link);
    toast("Link copied", { position: "top-center", hideProgressBar: true });
  }

  return (
    <div className={`${styles["refer-friend"]}`}>
      {success ? (
        <section>
          <h3>Awesome job!</h3>
          <p>
            You've just referred a friend to Podcast Fellowship.
            { isStudent ? (<span> When your friend completes their first learning session,
            you'll receive $15 as a thank you. Keep spreading the joy of Jewish learning!</span>) 
              : null}
          </p>
        </section>
      ) : (
        <section>
          <div className={`${styles["back-box"]}`}>
            <Link to={"/profile"}>
              <i className="icon-chevron-right"></i>
            </Link>
          </div>
          <h3>Refer a friend</h3>
          { isStudent ? (
            <div className={`${styles["refer"]}`}><p>
                Receive a $15 referral bonus for every friend that joins the
                program!
            </p>
            <p className="">
              Copy your custom referral link:
            </p>
            <p><b>{user.referral_link}</b> <a href="#" onClick={copyReferralLink}><span className="icon-copy"/></a></p>
            <p >Or fill in their contact information and we will email them with your custom referral link and information about the program.
            </p>
            </div>
          ) : null}
          <div>
            {" "}
            <ReferForm
              initialValue={initialValue}
              onSubmit={onSubmit}
              error={error}
            />
          </div>
        </section>
      )}
      <div className={`${styles["social-box"]}`}>
        <p>Share on socials:</p>
        <ul className="d-flex align-items-center">
          <li>
            <Link to={"#"}>
              <i className="icon-facebook"></i>
            </Link>
          </li>
          <li>
            <Link to={"#"}>
              <i className="icon-instagram"></i>
            </Link>
          </li>
          <li>
            <Link to={"#"}>
              <i className="icon-linkedin"></i>
            </Link>
          </li>
          <li>
            <Link to={"#"}>
              <i className="icon-twitter"></i>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ReferFriendForm;
