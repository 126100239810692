import { getIntroCourses } from "@podcasts/store/dashboard"
import { dashboardSelector } from "@podcasts/store/dashboard"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export function IntroCoursesPage() {
  const { featured } = useSelector(dashboardSelector) as any;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIntroCourses());
  }, []);

  return <>{/* <RequestPayoutPage /> */}</>;
}

export default IntroCoursesPage;
