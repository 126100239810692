
import { useCallback } from "react";
import { useSpeakerLibraryBase } from './library-base';
import { useSpeaker, usePodcastSpeaker } from './api';
import { useSpeakerLibraryAppToast } from './useAppLibraryToast';
import { useSpeakerLibraryWebToast } from './useWebLibraryToast';

export const useSpeakerBasePage = (slug,props = {}) => {
  const { isSavedSpeaker, toggleSpeakerLibrary, isAuthenticated }  = useSpeakerLibraryBase(props);
  const  { data } = useSpeaker({ slug })

  const onSaveSpeaker = useCallback(() => {
    toggleSpeakerLibrary(data)
 },[data]) 

  return { data, isSavedSpeaker, onSaveSpeaker, isAuthenticated }
}

export const usePodcastSpeakerPage = (slug) => {
  const toast = useSpeakerLibraryAppToast();
  const { isSavedSpeaker, toggleSpeakerLibrary, isAuthenticated }  = useSpeakerLibraryBase(toast);
  const  { data } = usePodcastSpeaker(slug)

  const onSaveSpeaker = useCallback(() => {
    toggleSpeakerLibrary(data)
 },[data]) 

  return { data, isSavedSpeaker, onSaveSpeaker, isAuthenticated }
}



export const useSpeakerPage = (slug) => {
  const toast = useSpeakerLibraryWebToast();
  return useSpeakerBasePage(slug,toast);
}

export const useSpeakerAppPage = (slug) => {
  const toast = useSpeakerLibraryAppToast();
  return useSpeakerBasePage(slug,toast);
}
