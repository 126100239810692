/* eslint-disable max-len */
// import styles from './Footer.module.scss';
import React from "react";
import styles from "../../../assets/scss/global.module.scss";
import Select, { StylesConfig, components } from "react-select";
import classNames from "classnames";

interface SelectProps {
  label?: string;
  placeholder?: string;
  value?: any;
  onChange?: any;
  onBlur?: any;
  error?: string | null;
  name?: string;
  options: any[];
  disabled?: boolean;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
      >
        <path
          d="M9.08008 8.92792C8.49014 9.54011 7.50984 9.54011 6.91989 8.92792L1.61737 3.42553C0.699315 2.47287 1.37444 0.884669 2.69747 0.884669L13.3025 0.88467C14.6255 0.88467 15.3007 2.47287 14.3826 3.42553L9.08008 8.92792Z"
          stroke="black"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",
});

const colourStyles = (error: any): StylesConfig<any> => ({
  //@ts-ignore
  control: (styles) => ({
    ...styles,
    height: "45px",
    background: "#FFFFFF",
    border: "1px solid ",
    borderRadius: "10px",
    borderColor: error ? "red" : "#000000",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
  //@ts-ignore
  input: (styles) => ({ ...styles, ...dot() }),
  //@ts-ignore
  placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
  //@ts-ignore
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
});

export function InputSelect({
  name = "",
  label = "",
  placeholder = "",
  value = "",
  onChange,
  onBlur,
  error,
  options = [],
  disabled = false,
}: SelectProps) {
  const classNamesForInput = classNames(
    styles["inputContainer"],
    error ? styles["inputError"] : ""
  );
  return (
    <div>
      <div className={classNamesForInput}>
        <label>{label}</label>
        <Select
          isDisabled={disabled}
          name={name}
          options={options}
          placeholder={placeholder}
          styles={colourStyles(error)}
          components={{ DropdownIndicator }}
          defaultValue={options.find((d) => d.value === value) || null}
          onChange={(e: any) => onChange(e)}
          onBlur={onBlur}
        />
        {error && <span className={styles["errorMessage"]}>{error}</span>}
      </div>
    </div>
  );
}

export default InputSelect;
