import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  userAuth,
  updateStudentProfileApi,
  requestPayOutApi,
  updateStudentImageApi,
} from "@podcasts/api";
import { get } from "lodash";

let storage = {};

// const user = get(storage?.user ? JSON.parse(storage?.user) : {}, "user", null);

const user = null;
const initialState = {
  user: {},
  loading: false,
  error: null,
};

export const signIn = createAsyncThunk(
  "user/saveUser",
  async (options, { rejectWithValue }) => {
    try {
      return  userAuth(options);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStudentImage = createAsyncThunk(
  "user/updateStudentImage",
  async (file, { rejectWithValue }) => {
    try {
      const {
        data: { image },
      } = await updateStudentImageApi(file);

      return image;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStudentProfile = createAsyncThunk(
  "user/updateStudentProfile",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await updateStudentProfileApi(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signUpFirstStep = createAsyncThunk(
  "user/signUpFirstStep",
  async (options, { rejectWithValue }) => {
    try {
      console.log("signUpFirstStep");
      return null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signUpSecondStep = createAsyncThunk(
  "user/signUpSecondStep",
  async (options, { rejectWithValue }) => {
    try {
      console.log("signUpSecondStep");
      return null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signUpThirdStep = createAsyncThunk(
  "user/signUpTrirdStep",
  async (options, { rejectWithValue }) => {
    try {
      console.log("signUpTrirdStep");
      return null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logOut: (state) => {
      state.user = null;
    },
  },
  extraReducers: {
    [updateStudentProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateStudentProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = { ...state.user, ...action.payload };
    },
    [updateStudentProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Update student profile error";
    },

    [signIn.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [signIn.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    },
    [signIn.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Email or password is invalid";
    },
    // first step
    [signUpFirstStep.pending]: (state) => {
      state.loading = true;
    },
    [signUpFirstStep.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [signUpFirstStep.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Sing up first step error";
    },
    // second step
    [signUpSecondStep.pending]: (state) => {
      state.loading = true;
    },
    [signUpSecondStep.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [signUpSecondStep.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Sing up second step error";
    },
    [updateStudentImage.fulfilled]: (state, action) => {
      state.user.image = action.payload;
    },
    // third step
    [signUpThirdStep.pending]: (state) => {
      state.loading = true;
    },
    [signUpThirdStep.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [signUpThirdStep.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Sing up third step error";
    },
  },
});

export const userReducer = userSlice.reducer;

export const { logOut } = userSlice.actions;
