import { useCallback, useState } from "react";
import { usePodcastsLibraryBase } from './library-base';
import { useSavedPodcasts } from './api';
import { usePodcastLibraryAppToast } from './useAppLibraryToast';
import { usePodcastLibraryWebToast } from './useWebLibraryToast';

export const useSavedPodcastsBasePage = (props) => {
   
    const { 
        per_page = 12,
        defaultOrderBy = 'title',
        defaultOrder = 'asc',
        onAddFinish,
        onRemoveFinish,
        onUnathorized } = props;

    const [page,setPage] = useState(1);
    const [order, setOrder] = useState(defaultOrder)
    const [order_by,setOrderBy] = useState(defaultOrderBy);
    const {isSavedPodcast, togglePodcastLibrary, saved_podcasts_ids, isAuthenticated } = usePodcastsLibraryBase({ onAddFinish, onRemoveFinish, onUnathorized});
    const { 
      data,
      fetchNextPage,
      isFetchingPreviousPage,
      isFetchingNextPage,
      isFetching,
      isRefetching } = useSavedPodcasts({ page, per_page, order_by, order, saved_podcasts_ids });
      
  
    const onNextPage = useCallback(() => {
      if (!isFetchingPreviousPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, [isFetchingPreviousPage, isFetchingNextPage]);
  
    const onSavePodcast = useCallback((item) => {
        togglePodcastLibrary(item)
    },[]);

    const onPageChange = useCallback((p) => {
      setPage(p)
     },[])
   
    return {
        data,
        order,
        order_by,
        setOrderBy,
        setOrder,
        isSavedPodcast,
        fetchNextPage,
        isFetchingPreviousPage,
        isFetchingNextPage,
        isFetching,
        isRefetching,
        onNextPage,
        onPageChange,
        onSavePodcast,
        isAuthenticated
    }
}

export const useSavedPodcastsPage = ({per_page = 12, defaultOrderBy = 'title', defaultOrder = 'asc'}) => {
  const toast = usePodcastLibraryWebToast();
  return useSavedPodcastsBasePage({ per_page, defaultOrderBy, defaultOrder,...toast })
}

export const useSavedPodcastsAppPage = ({per_page = 12, defaultOrderBy = 'title', defaultOrder = 'asc'}) => {
  const toast = usePodcastLibraryAppToast();
  return useSavedPodcastsBasePage({ per_page, defaultOrderBy, defaultOrder, ...toast })
}