import { Link, useParams } from "react-router-dom";
import styles from "./SpeakerPage.module.scss";
import { useRef } from "react";
import SearchablePodcastTable from "../../../../components/PodcastsTable/SearchablePodcastTable";
import { useSpeakerPage, useIsAuthenticated } from "@podcasts/hooks";

export function SpeakerPage() {
  const myRef: any = useRef(null);
  const { slag } = useParams();
  const { data: speaker, isSavedSpeaker, onSaveSpeaker } = useSpeakerPage(slag);
  const isAuthenticated = useIsAuthenticated();
  
  return (
    <div ref={myRef} className={`${styles["container"]}`}>
      <div className={`${styles["speaker"]}`}>
        <div
          className={`${styles["speaker-header"]} d-flex justify-content-between align-items-center`}
        >
          <div
            className={`${styles["speaker-header-info"]} d-flex align-items-center`}
          >
            <div>
              <div>
                <img src={speaker?.image} alt="" />
              </div>
            </div>
            <p>
              {speaker?.title} {speaker?.fname} {speaker?.lname}
            </p>
          </div>
          <div
            className={`${styles["speaker-header-actions"]} d-flex align-items-center`}
          >
            <Link
              to={"/podcasts/speakers"}
              className="d-flex justify-content-center align-items-center"
            >
                Back to all speakers
            </Link>
            {isAuthenticated && (
              <div onClick={onSaveSpeaker}>
                <a
                  className={`${styles["speaker-save"]} ${
                    isSavedSpeaker(speaker?.id) ? styles["saved"] : ""
                  } d-flex justify-content-center align-items-center`}
                >
                  {
                    isSavedSpeaker(speaker?.id) ? (
                      <span>Saved</span>
                    ) : (
                      <><span>Save speaker</span><i className="icon-bookmark-empty"></i></> 
                    )
                  }
                </a>
              </div>
            )}
          </div>
        </div>
        <p className={`${styles["speaker-descr"]}`}>{speaker?.bio}</p>
        <div className={`${styles["speaker-table"]}`}>
          <SearchablePodcastTable params={{ speaker_slug: [slag] }}/>
        </div>
      </div>
    </div>
  );
}

export default SpeakerPage;
