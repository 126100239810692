import styles from "./PodcastNote.module.scss";
import moment from "moment";

export function PodcastNote({noteDate, noteText}: any) {
    return (
        <div className={`${styles["notes"]}`}>
            <p className={`${styles["notes-time"]}`}>
                {moment(noteDate).format("LL")}
            </p>
            <div
                className={`${styles["notes-text"]}`}
                dangerouslySetInnerHTML={{ __html: noteText }}
            >
            </div>
        </div>
  );
}

export default PodcastNote;