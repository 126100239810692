import styles from "./SavedSpeakersPage.module.scss";
import spStyles from "../SpeakersPage/inners/SpeakersPage/SpeakersPage.module.scss";
import { useRef } from "react";
import { Pagination } from "../../../../components";
import { useSavedSpeakersPage } from "@podcasts/hooks";
import SpeakerCard from "../SpeakersPage/components/SpeakerCard/SpeakerCard";

export function SavedSpeakersPage() {
  const myRef: any = useRef(null);
  const {
    data: { data = [], last_page = 0, current_page = 1 },
    order,
    setOrder,
    isSavedSpeaker,
    onSaveSpeaker,
    onPageChange
  } = useSavedSpeakersPage({ per_page: 12, defaultOrder: "asc", order_by: "fname" });

  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth" });
  
  return (
    <div ref={myRef} className={`${styles["container"]}`}>
      <div
        className={`${styles["title-box"]} d-flex justify-content-between align-items-center`}
      >
        <h2>Saved Speakers</h2>
        <div className={`${styles["sort"]} d-flex align-items-center`} onClick={() => setOrder(order === "asc" ? "desc" : "asc")} >
          <span>Sort&nbsp;</span>
          <div className={`${order === "desc" ? styles["up"] : styles["down"]}`}>
            <i className="icon-chevron-sown"></i>
          </div>
        </div>
      </div>
      <div className={`${spStyles["topics-items"]}`}>
        {data.map((item: any, i: number) => (
          <div key={i} className={`${spStyles["topic-wrapp"]}`}>
            <SpeakerCard
              {...item}
              saved={isSavedSpeaker(item.id)}
              onToggleFavorite={(e) => {
                e.stopPropagation();
                onSaveSpeaker(item);
              }}
              {...item}
            />
          </div>
        ))}
      </div>
      <Pagination
        pages={last_page}
        page={current_page}
        onPageChange={(page: number) => {
          onPageChange(page)
          executeScroll();
        }}
      />
    </div>
  );
}

export default SavedSpeakersPage;
