import { pick } from "lodash";

export const mentorSelector = (state) => state.mentor;

export const firsStepInitialValue = (state) =>
  pick(state.mentor.user, [
    "email",
    "fname",
    "lname",
    "phone",
    "gender",
    "dob",
    "calendly_url"
  ]);

export const secondStepInitialValue = (state) =>
  pick(state.mentor.user, [
    "country",
    "school",
    "city",
    "state",
    "bio"
  ]);
