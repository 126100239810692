import axios from "axios";

const qs = require("qs");

export const saveCourseApi = async (slug) => {
  return await axios.post(`courses/${slug}`);
};

export const getCourseApi = async ({slug }) => {
  return await axios.get(`courses/${slug}`);
};

export const getAllCoursesApi = async (options = {}) => {
  return await axios.get(`courses?${qs.stringify(options)}`);
};

export const getSavedCoursesApi = async () => {
  return await axios.get(`courses/saved`);
};
