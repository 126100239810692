import axios from "axios";
const qs = require("qs");

export class LibraryService {
  static async getSavedTopics(options = {}) {
    delete options.saved_topics_ids;
    return await axios.get(`library/topics?${qs.stringify(options)}`);
  }

  static async getSavedCourses(options = {}) {
    delete options.saved_courses_ids;
    return await axios.get(`library/courses?${qs.stringify(options)}`);
  }

  static async savedTopic(topicSlug) {
    return await axios.post(`library/topics/${topicSlug}`);
  }

  static async savedCourse(courseSlug) {
    return await axios.post(`library/courses/${courseSlug}`);
  }

  static async getSavedTopicsIds() {
    return await axios.get(`library/ids/topic`);
  }

  static async getSavedCoursesIds() {
    return await axios.get(`library/ids/course`);
  }

  static async getSavedSpeakersApi(options = {}) {
    return await axios.get(`library/speakers?${qs.stringify(options)}`);
  }
}
