import { pick } from "lodash";
import { memoize } from 'proxy-memoize'

export const appSelector = (state) => state.application;


export const firsStepInitialValue = memoize((state) =>
  pick(state.application.user, [
    "email",
    "fname",
    "lname",
    "phone",
    "gender",
    "dob",
    "city_residence",
    "state_residence",
    "country",
  ]));

export const secondStepInitialValue = memoize((state) =>
  pick(state.application.user, [
    "formal_jewish",
    "religion_born",
    "religion_identify_with",
    "father_religion",
    "mother_religion",
    "jewish_story",
    "jewish_education_description",
    "jewish_education",
  ]));

export const thirdStepInitialValue = memoize((state) =>
  pick(state.application.user, [
    "referral_method",
    "position",
    "school",
    "city",
    "state",
    "internship",
    "referral_name",
    "other_referral_method",
    "current_position",
    "other_details"
  ]));
