export const countriesList = [
    {
        "isoCode": "AF",
        "name": "Afghanistan"
    },
    {
        "isoCode": "AX",
        "name": "Aland Islands"
    },
    {
        "isoCode": "AL",
        "name": "Albania"
    },
    {
        "isoCode": "DZ",
        "name": "Algeria"
    },
    {
        "isoCode": "AS",
        "name": "American Samoa"
    },
    {
        "isoCode": "AD",
        "name": "Andorra"
    },
    {
        "isoCode": "AO",
        "name": "Angola"
    },
    {
        "isoCode": "AI",
        "name": "Anguilla"
    },
    {
        "isoCode": "AQ",
        "name": "Antarctica"
    },
    {
        "isoCode": "AG",
        "name": "Antigua And Barbuda"
    },
    {
        "isoCode": "AR",
        "name": "Argentina"
    },
    {
        "isoCode": "AM",
        "name": "Armenia"
    },
    {
        "isoCode": "AW",
        "name": "Aruba"
    },
    {
        "isoCode": "AU",
        "name": "Australia"
    },
    {
        "isoCode": "AT",
        "name": "Austria"
    },
    {
        "isoCode": "AZ",
        "name": "Azerbaijan"
    },
    {
        "isoCode": "BS",
        "name": "The Bahamas"
    },
    {
        "isoCode": "BH",
        "name": "Bahrain"
    },
    {
        "isoCode": "BD",
        "name": "Bangladesh"
    },
    {
        "isoCode": "BB",
        "name": "Barbados"
    },
    {
        "isoCode": "BY",
        "name": "Belarus"
    },
    {
        "isoCode": "BE",
        "name": "Belgium"
    },
    {
        "isoCode": "BZ",
        "name": "Belize"
    },
    {
        "isoCode": "BJ",
        "name": "Benin"
    },
    {
        "isoCode": "BM",
        "name": "Bermuda"
    },
    {
        "isoCode": "BT",
        "name": "Bhutan"
    },
    {
        "isoCode": "BO",
        "name": "Bolivia"
    },
    {
        "isoCode": "BA",
        "name": "Bosnia and Herzegovina"
    },
    {
        "isoCode": "BW",
        "name": "Botswana"
    },
    {
        "isoCode": "BV",
        "name": "Bouvet Island"
    },
    {
        "isoCode": "BR",
        "name": "Brazil"
    },
    {
        "isoCode": "IO",
        "name": "British Indian Ocean Territory"
    },
    {
        "isoCode": "BN",
        "name": "Brunei"
    },
    {
        "isoCode": "BG",
        "name": "Bulgaria"
    },
    {
        "isoCode": "BF",
        "name": "Burkina Faso"
    },
    {
        "isoCode": "BI",
        "name": "Burundi"
    },
    {
        "isoCode": "KH",
        "name": "Cambodia"
    },
    {
        "isoCode": "CM",
        "name": "Cameroon"
    },
    {
        "isoCode": "CA",
        "name": "Canada"
    },
    {
        "isoCode": "CV",
        "name": "Cape Verde"
    },
    {
        "isoCode": "KY",
        "name": "Cayman Islands"
    },
    {
        "isoCode": "CF",
        "name": "Central African Republic"
    },
    {
        "isoCode": "TD",
        "name": "Chad"
    },
    {
        "isoCode": "CL",
        "name": "Chile"
    },
    {
        "isoCode": "CN",
        "name": "China"
    },
    {
        "isoCode": "CX",
        "name": "Christmas Island"
    },
    {
        "isoCode": "CC",
        "name": "Cocos (Keeling) Islands"
    },
    {
        "isoCode": "CO",
        "name": "Colombia"
    },
    {
        "isoCode": "KM",
        "name": "Comoros"
    },
    {
        "isoCode": "CG",
        "name": "Congo"
    },
    {
        "isoCode": "CD",
        "name": "Democratic Republic of the Congo"
    },
    {
        "isoCode": "CK",
        "name": "Cook Islands"
    },
    {
        "isoCode": "CR",
        "name": "Costa Rica"
    },
    {
        "isoCode": "CI",
        "name": "Cote D'Ivoire (Ivory Coast)"
    },
    {
        "isoCode": "HR",
        "name": "Croatia"
    },
    {
        "isoCode": "CU",
        "name": "Cuba"
    },
    {
        "isoCode": "CY",
        "name": "Cyprus"
    },
    {
        "isoCode": "CZ",
        "name": "Czech Republic"
    },
    {
        "isoCode": "DK",
        "name": "Denmark"
    },
    {
        "isoCode": "DJ",
        "name": "Djibouti"
    },
    {
        "isoCode": "DM",
        "name": "Dominica"
    },
    {
        "isoCode": "DO",
        "name": "Dominican Republic"
    },
    {
        "isoCode": "TL",
        "name": "East Timor"
    },
    {
        "isoCode": "EC",
        "name": "Ecuador"
    },
    {
        "isoCode": "EG",
        "name": "Egypt"
    },
    {
        "isoCode": "SV",
        "name": "El Salvador"
    },
    {
        "isoCode": "GQ",
        "name": "Equatorial Guinea"
    },
    {
        "isoCode": "ER",
        "name": "Eritrea"
    },
    {
        "isoCode": "EE",
        "name": "Estonia"
    },
    {
        "isoCode": "ET",
        "name": "Ethiopia"
    },
    {
        "isoCode": "FK",
        "name": "Falkland Islands"
    },
    {
        "isoCode": "FO",
        "name": "Faroe Islands"
    },
    {
        "isoCode": "FJ",
        "name": "Fiji Islands"
    },
    {
        "isoCode": "FI",
        "name": "Finland"
    },
    {
        "isoCode": "FR",
        "name": "France"
    },
    {
        "isoCode": "GF",
        "name": "French Guiana"
    },
    {
        "isoCode": "PF",
        "name": "French Polynesia"
    },
    {
        "isoCode": "TF",
        "name": "French Southern Territories"
    },
    {
        "isoCode": "GA",
        "name": "Gabon"
    },
    {
        "isoCode": "GM",
        "name": "Gambia The"
    },
    {
        "isoCode": "GE",
        "name": "Georgia"
    },
    {
        "isoCode": "DE",
        "name": "Germany"
    },
    {
        "isoCode": "GH",
        "name": "Ghana"
    },
    {
        "isoCode": "GI",
        "name": "Gibraltar"
    },
    {
        "isoCode": "GR",
        "name": "Greece"
    },
    {
        "isoCode": "GL",
        "name": "Greenland"
    },
    {
        "isoCode": "GD",
        "name": "Grenada"
    },
    {
        "isoCode": "GP",
        "name": "Guadeloupe"
    },
    {
        "isoCode": "GU",
        "name": "Guam"
    },
    {
        "isoCode": "GT",
        "name": "Guatemala"
    },
    {
        "isoCode": "GG",
        "name": "Guernsey and Alderney"
    },
    {
        "isoCode": "GN",
        "name": "Guinea"
    },
    {
        "isoCode": "GW",
        "name": "Guinea-Bissau"
    },
    {
        "isoCode": "GY",
        "name": "Guyana"
    },
    {
        "isoCode": "HT",
        "name": "Haiti"
    },
    {
        "isoCode": "HM",
        "name": "Heard Island and McDonald Islands"
    },
    {
        "isoCode": "HN",
        "name": "Honduras"
    },
    {
        "isoCode": "HK",
        "name": "Hong Kong S.A.R."
    },
    {
        "isoCode": "HU",
        "name": "Hungary"
    },
    {
        "isoCode": "IS",
        "name": "Iceland"
    },
    {
        "isoCode": "IN",
        "name": "India"
    },
    {
        "isoCode": "ID",
        "name": "Indonesia"
    },
    {
        "isoCode": "IR",
        "name": "Iran"
    },
    {
        "isoCode": "IQ",
        "name": "Iraq"
    },
    {
        "isoCode": "IE",
        "name": "Ireland"
    },
    {
        "isoCode": "IL",
        "name": "Israel"
    },
    {
        "isoCode": "IT",
        "name": "Italy"
    },
    {
        "isoCode": "JM",
        "name": "Jamaica"
    },
    {
        "isoCode": "JP",
        "name": "Japan"
    },
    {
        "isoCode": "JE",
        "name": "Jersey"
    },
    {
        "isoCode": "JO",
        "name": "Jordan"
    },
    {
        "isoCode": "KZ",
        "name": "Kazakhstan"
    },
    {
        "isoCode": "KE",
        "name": "Kenya"
    },
    {
        "isoCode": "KI",
        "name": "Kiribati"
    },
    {
        "isoCode": "KP",
        "name": "North Korea"
    },
    {
        "isoCode": "KR",
        "name": "South Korea"
    },
    {
        "isoCode": "KW",
        "name": "Kuwait"
    },
    {
        "isoCode": "KG",
        "name": "Kyrgyzstan"
    },
    {
        "isoCode": "LA",
        "name": "Laos"
    },
    {
        "isoCode": "LV",
        "name": "Latvia"
    },
    {
        "isoCode": "LB",
        "name": "Lebanon"
    },
    {
        "isoCode": "LS",
        "name": "Lesotho"
    },
    {
        "isoCode": "LR",
        "name": "Liberia"
    },
    {
        "isoCode": "LY",
        "name": "Libya"
    },
    {
        "isoCode": "LI",
        "name": "Liechtenstein"
    },
    {
        "isoCode": "LT",
        "name": "Lithuania"
    },
    {
        "isoCode": "LU",
        "name": "Luxembourg"
    },
    {
        "isoCode": "MO",
        "name": "Macau S.A.R."
    },
    {
        "isoCode": "MK",
        "name": "Macedonia"
    },
    {
        "isoCode": "MG",
        "name": "Madagascar"
    },
    {
        "isoCode": "MW",
        "name": "Malawi"
    },
    {
        "isoCode": "MY",
        "name": "Malaysia"
    },
    {
        "isoCode": "MV",
        "name": "Maldives"
    },
    {
        "isoCode": "ML",
        "name": "Mali"
    },
    {
        "isoCode": "MT",
        "name": "Malta"
    },
    {
        "isoCode": "IM",
        "name": "Man (Isle of)"
    },
    {
        "isoCode": "MH",
        "name": "Marshall Islands"
    },
    {
        "isoCode": "MQ",
        "name": "Martinique"
    },
    {
        "isoCode": "MR",
        "name": "Mauritania"
    },
    {
        "isoCode": "MU",
        "name": "Mauritius"
    },
    {
        "isoCode": "YT",
        "name": "Mayotte"
    },
    {
        "isoCode": "MX",
        "name": "Mexico"
    },
    {
        "isoCode": "FM",
        "name": "Micronesia"
    },
    {
        "isoCode": "MD",
        "name": "Moldova"
    },
    {
        "isoCode": "MC",
        "name": "Monaco"
    },
    {
        "isoCode": "MN",
        "name": "Mongolia"
    },
    {
        "isoCode": "ME",
        "name": "Montenegro"
    },
    {
        "isoCode": "MS",
        "name": "Montserrat"
    },
    {
        "isoCode": "MA",
        "name": "Morocco"
    },
    {
        "isoCode": "MZ",
        "name": "Mozambique"
    },
    {
        "isoCode": "MM",
        "name": "Myanmar"
    },
    {
        "isoCode": "NA",
        "name": "Namibia"
    },
    {
        "isoCode": "NR",
        "name": "Nauru"
    },
    {
        "isoCode": "NP",
        "name": "Nepal"
    },
    {
        "isoCode": "BQ",
        "name": "Bonaire, Sint Eustatius and Saba"
    },
    {
        "isoCode": "NL",
        "name": "Netherlands"
    },
    {
        "isoCode": "NC",
        "name": "New Caledonia"
    },
    {
        "isoCode": "NZ",
        "name": "New Zealand"
    },
    {
        "isoCode": "NI",
        "name": "Nicaragua"
    },
    {
        "isoCode": "NE",
        "name": "Niger"
    },
    {
        "isoCode": "NG",
        "name": "Nigeria"
    },
    {
        "isoCode": "NU",
        "name": "Niue"
    },
    {
        "isoCode": "NF",
        "name": "Norfolk Island"
    },
    {
        "isoCode": "MP",
        "name": "Northern Mariana Islands"
    },
    {
        "isoCode": "NO",
        "name": "Norway"
    },
    {
        "isoCode": "OM",
        "name": "Oman"
    },
    {
        "isoCode": "PK",
        "name": "Pakistan"
    },
    {
        "isoCode": "PW",
        "name": "Palau"
    },
    {
        "isoCode": "PS",
        "name": "Palestinian Territory Occupied"
    },
    {
        "isoCode": "PA",
        "name": "Panama"
    },
    {
        "isoCode": "PG",
        "name": "Papua new Guinea"
    },
    {
        "isoCode": "PY",
        "name": "Paraguay"
    },
    {
        "isoCode": "PE",
        "name": "Peru"
    },
    {
        "isoCode": "PH",
        "name": "Philippines"
    },
    {
        "isoCode": "PN",
        "name": "Pitcairn Island"
    },
    {
        "isoCode": "PL",
        "name": "Poland"
    },
    {
        "isoCode": "PT",
        "name": "Portugal"
    },
    {
        "isoCode": "PR",
        "name": "Puerto Rico"
    },
    {
        "isoCode": "QA",
        "name": "Qatar"
    },
    {
        "isoCode": "RE",
        "name": "Reunion"
    },
    {
        "isoCode": "RO",
        "name": "Romania"
    },
    {
        "isoCode": "RU",
        "name": "Russia"
    },
    {
        "isoCode": "RW",
        "name": "Rwanda"
    },
    {
        "isoCode": "SH",
        "name": "Saint Helena"
    },
    {
        "isoCode": "KN",
        "name": "Saint Kitts And Nevis"
    },
    {
        "isoCode": "LC",
        "name": "Saint Lucia"
    },
    {
        "isoCode": "PM",
        "name": "Saint Pierre and Miquelon"
    },
    {
        "isoCode": "VC",
        "name": "Saint Vincent And The Grenadines"
    },
    {
        "isoCode": "BL",
        "name": "Saint-Barthelemy"
    },
    {
        "isoCode": "MF",
        "name": "Saint-Martin (French part)"
    },
    {
        "isoCode": "WS",
        "name": "Samoa"
    },
    {
        "isoCode": "SM",
        "name": "San Marino"
    },
    {
        "isoCode": "ST",
        "name": "Sao Tome and Principe"
    },
    {
        "isoCode": "SA",
        "name": "Saudi Arabia"
    },
    {
        "isoCode": "SN",
        "name": "Senegal"
    },
    {
        "isoCode": "RS",
        "name": "Serbia"
    },
    {
        "isoCode": "SC",
        "name": "Seychelles"
    },
    {
        "isoCode": "SL",
        "name": "Sierra Leone"
    },
    {
        "isoCode": "SG",
        "name": "Singapore"
    },
    {
        "isoCode": "SK",
        "name": "Slovakia"
    },
    {
        "isoCode": "SI",
        "name": "Slovenia"
    },
    {
        "isoCode": "SB",
        "name": "Solomon Islands"
    },
    {
        "isoCode": "SO",
        "name": "Somalia"
    },
    {
        "isoCode": "ZA",
        "name": "South Africa"
    },
    {
        "isoCode": "GS",
        "name": "South Georgia"
    },
    {
        "isoCode": "SS",
        "name": "South Sudan"
    },
    {
        "isoCode": "ES",
        "name": "Spain"
    },
    {
        "isoCode": "LK",
        "name": "Sri Lanka"
    },
    {
        "isoCode": "SD",
        "name": "Sudan"
    },
    {
        "isoCode": "SR",
        "name": "Suriname"
    },
    {
        "isoCode": "SJ",
        "name": "Svalbard And Jan Mayen Islands"
    },
    {
        "isoCode": "SZ",
        "name": "Swaziland"
    },
    {
        "isoCode": "SE",
        "name": "Sweden"
    },
    {
        "isoCode": "CH",
        "name": "Switzerland"
    },
    {
        "isoCode": "SY",
        "name": "Syria"
    },
    {
        "isoCode": "TW",
        "name": "Taiwan"
    },
    {
        "isoCode": "TJ",
        "name": "Tajikistan"
    },
    {
        "isoCode": "TZ",
        "name": "Tanzania"
    },
    {
        "isoCode": "TH",
        "name": "Thailand"
    },
    {
        "isoCode": "TG",
        "name": "Togo"
    },
    {
        "isoCode": "TK",
        "name": "Tokelau"
    },
    {
        "isoCode": "TO",
        "name": "Tonga"
    },
    {
        "isoCode": "TT",
        "name": "Trinidad And Tobago"
    },
    {
        "isoCode": "TN",
        "name": "Tunisia"
    },
    {
        "isoCode": "TR",
        "name": "Turkey"
    },
    {
        "isoCode": "TM",
        "name": "Turkmenistan"
    },
    {
        "isoCode": "TC",
        "name": "Turks And Caicos Islands"
    },
    {
        "isoCode": "TV",
        "name": "Tuvalu"
    },
    {
        "isoCode": "UG",
        "name": "Uganda"
    },
    {
        "isoCode": "UA",
        "name": "Ukraine"
    },
    {
        "isoCode": "AE",
        "name": "United Arab Emirates"
    },
    {
        "isoCode": "GB",
        "name": "United Kingdom"
    },
    {
        "isoCode": "US",
        "name": "United States"
    },
    {
        "isoCode": "UM",
        "name": "United States Minor Outlying Islands"
    },
    {
        "isoCode": "UY",
        "name": "Uruguay"
    },
    {
        "isoCode": "UZ",
        "name": "Uzbekistan"
    },
    {
        "isoCode": "VU",
        "name": "Vanuatu"
    },
    {
        "isoCode": "VA",
        "name": "Vatican City State (Holy See)"
    },
    {
        "isoCode": "VE",
        "name": "Venezuela"
    },
    {
        "isoCode": "VN",
        "name": "Vietnam"
    },
    {
        "isoCode": "VG",
        "name": "Virgin Islands (British)"
    },
    {
        "isoCode": "VI",
        "name": "Virgin Islands (US)"
    },
    {
        "isoCode": "WF",
        "name": "Wallis And Futuna Islands"
    },
    {
        "isoCode": "EH",
        "name": "Western Sahara"
    },
    {
        "isoCode": "YE",
        "name": "Yemen"
    },
    {
        "isoCode": "ZM",
        "name": "Zambia"
    },
    {
        "isoCode": "ZW",
        "name": "Zimbabwe"
    },
    {
        "isoCode": "XK",
        "name": "Kosovo"
    },
    {
        "isoCode": "CW",
        "name": "Curaçao"
    },
    {
        "isoCode": "SX",
        "name": "Sint Maarten (Dutch part)"
    }
]