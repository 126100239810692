import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <footer className={styles["footer"]}>
      <div className={styles["footer-rights"]}>
        <p>©The Podcast Fellowship 2024 - all rights reserved</p>
        <Link to={"/privacy-policy"}>Privacy Policy</Link>
      </div>
    </footer>
  );
}

export default Footer;
