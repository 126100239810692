import React from 'react';

const PodcastCount = ({ count = 0 }) => {
    return (
        <>
            {count} podcast{count !== 1 &&  's'}
        </>
    );
};

export default PodcastCount;