// import styles from './Footer.module.scss';
import {
  Button,
  CheckBox,
  Input,
  InputSelect,
  RadioButton,
} from "../../../components";
import styles from "../RegisterPage.module.scss";
import { Formik } from "formik";
import { thirdApplicationStepSchema } from "@podcasts/schemas";
import React from "react";
import { referralMethods, intershipOptions } from "@podcasts/constants";

export function ThirdStep({ initialValues, onNext, onPrev }: any) {
  const { position } = initialValues;

  return (
    <div className={styles["step"]}>
      <div className="row">
        <div className="col-lg-6">
          <div className={styles["step-number"]}>
            <span>3</span>
          </div>
          <h2 className={`${styles["stepTitle"]}`}>
            Are you a student or <br></br>
            <span className="orange-yellow-color">a professional?</span>
          </h2>
        </div>
        <div className="col-lg-6">
          <Formik
            initialValues={{
              ...initialValues,
              position: position || "Student",
            }}
            validationSchema={thirdApplicationStepSchema}
            onSubmit={(values, { setSubmitting }) => {
              onNext(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }: /* and other goodies */
            any) => (
              <div className={styles["mobile-form"]}>
                <div className={`${styles["formRow"]} row`}>
                  <label
                    className="col-12"
                    style={{
                      fontWeight: 300,
                      fontSize: "13px",
                      lineHeight: "23PX",
                    }}
                  >
                    I am a... *
                  </label>
                  <div className="col-5 mt-0">
                    <RadioButton
                      name="position"
                      value={"Student"}
                      label="Student"
                      checked={values.position === "Student"}
                      onChange={(value: any) => {
                        setFieldValue("position", "Student");
                      }}
                      onBlur={handleBlur}
                      error={null}
                    />
                  </div>
                  <div className="col-5 mt-0">
                    <RadioButton
                      name="position"
                      value={"Professional"}
                      label="Professional"
                      checked={values.position === "Professional"}
                      onChange={(value: any) => {
                        setFieldValue("position", "Professional");
                      }}
                      onBlur={handleBlur}
                      error={null}
                    />
                  </div>
                </div>
                {values.position !== "Student" ? (
                  <div className={`${styles["formRow"]} row`}>
                    <div className="col-sm">
                      <Input
                        label="Current Position *"
                        name="current_position"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.current_position}
                        error={
                          errors.current_position && touched.current_position
                            ? "Required"
                            : null
                        }
                      />
                    </div>
                  </div>
                ) : null}
                {values.position === "Student" ? (
                  <div className={`${styles["formRow"]} row`}>
                    <div className="col-sm">
                      <Input
                        label="Where do you go to school? *"
                        name="school"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.school}
                        error={
                          errors.school && touched.school ? "Required" : null
                        }
                      />
                    </div>
                  </div>
                ) : null}

                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="City (Current Residence)"
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      error={errors.city && touched.city ? "Required" : null}
                    />
                  </div>

                  <div className="col-lg">
                    <Input
                      label="State (Current Residence)"
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      error={errors.state && touched.state ? "Required" : null}
                    />
                  </div>
                </div>
                {/* <div className={`${styles["formRow"]} row`}>
                  <div className="col-sm">
                    <InputSelect
                      options={intershipOptions}
                      label="Whom can wwe thank for referring you to The Podcast Fellowship? *"
                      placeholder="Please select:"
                      name="internship"
                      onChange={({ value }: any) => {
                        setFieldValue("internship", value);
                      }}
                      // onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.internship}
                      error={
                        errors.internship && touched.internship
                          ? "Required"
                          : null
                      }
                    />
                  </div>
                </div> */}
                <div className={`${styles["formRow"]} row`}>
                  <InputSelect
                    options={referralMethods}
                    label="Whom can we thank for referring you to The Podcast Fellowship? *"
                    placeholder="Please select:"
                    name="referral_method"
                    onChange={({ value }: any) => {
                      setFieldValue("referral_method", value);
                    }}
                    onBlur={handleBlur}
                    value={values.referral_method}
                    error={
                      errors.referral_method && touched.referral_method
                        ? "Required"
                        : null
                    }
                  />
                </div>

                {(values.referral_method === "friend" || values.referral_method === "educator") ? (
                  <div className={`${styles["formRow"]} row`}>
                    <div className="col-sm">
                      <Input
                        label={`Name of ${values.referral_method.toLowerCase()}:`}
                        name="referral_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.referral_name}
                        error={
                          errors.referral_name && touched.referral_name
                            ? "Required"
                            : null
                        }
                      />
                    </div>
                  </div>
                ) : values.referral_method === "advertisement" ? (
                  <div className={`${styles["formRow"]} row`}>
                    <div className="col-sm">
                      <Input
                        label="Where did you see the ad?"
                        name="other_referral_method"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.other_referral_method}
                        error={
                          errors.other_referral_method &&
                          touched.other_referral_method
                            ? "Required"
                            : null
                        }
                      />
                    </div>
                  </div>
                ) : values.referral_method === "other" ? (
                  <div className={`${styles["formRow"]} row`}>
                    <div className="col-sm">
                      <Input
                        label="Please tell us how you heard about the Podcast Fellowship."
                        name="other_referral_method"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.other_referral_method}
                        error={
                          errors.other_referral_method &&
                          touched.other_referral_method
                            ? "Required"
                            : null
                        }
                      />
                    </div>
                  </div>
                ) : null}
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-sm">
                    <Input
                      label="Other details you would like to share with us"
                      name="other_details"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.other_details}
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-sm">
                    <CheckBox
                      name="accept"
                      value={false}
                      // eslint-disable-next-line max-len
                      label="With programs in over 30 countries, Olami and its funding partners sometimes use independent verification firms to assure that programs are attended and impactful. If I am accepted into this program I understand that I might be contacted by a verification firm for quality control purposes."
                      checked={false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.accept && touched.accept ? "Required" : null
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div
                    className={`${styles["prev-step"]} d-flex align-items-center col-lg`}
                  >
                    <span onClick={onPrev}>Previous step</span>
                  </div>
                  <div className="col-lg mt-0">
                    <Button name="Next step" onClick={handleSubmit} />
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ThirdStep;
