import { useEffect } from 'react'
import { usePodcast, saveToRecentlyViewed } from './api';

export const usePodcastPageBase = (id, isAuthenticated) => {
   const podcast = usePodcast(id);
   const saveToRvMutation = saveToRecentlyViewed(id);

   useEffect(() => {
      if (isAuthenticated) {
         saveToRvMutation.mutate(id);
      }
   }, [id]);

   return { ...podcast };
}