import { useCallback, useState } from "react";
import { useTopicLibraryBase } from './library-base';
import { useSavedTopics } from './api';
import { useTopicLibraryAppToast } from './useAppLibraryToast';
import { useTopicLibraryWebToast } from './useWebLibraryToast';

export const useSavedTopicsPageBase = (props) => {
  
  const {
    per_page = 12, 
    order_by = 'title', 
    defaultOrder = 'asc',
    onAddFinish,
    onRemoveFinish,
    onUnathorized,
  } = props;
  
  const [page,setPage] = useState(1);
  const [order, setOrder] = useState(defaultOrder)
  const { isSavedTopic, toggleTopicLibrary, isAuthenticated, saved_topics_ids }  = useTopicLibraryBase({ onAddFinish, onRemoveFinish, onUnathorized});
  const { 
    data,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    isFetching,
    isRefetching } = useSavedTopics({ page, per_page, order_by, order, saved_topics_ids });

  const onNextPage = useCallback(() => {
    if (!isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage]);

  const onSaveTopic = useCallback((item) => {
      toggleTopicLibrary(item)
  },[]);

  const onPageChange = useCallback((p) => {
    setPage(p)
   },[])

   const toggleOrder = useCallback(() => {
    setOrder(order === "asc" ? "desc" : "asc");
    setPage(1)
  },[order])
 

  return {
      data,
      order,
      toggleOrder,
      setOrder,
      isSavedTopic,
      fetchNextPage,
      isFetchingPreviousPage,
      isFetchingNextPage,
      isFetching,
      isRefetching,
      onNextPage,
      onPageChange,
      onSaveTopic,
      isAuthenticated
  }
}

export const useSavedTopicsPage = ({ per_page = 12, order_by = 'title', defaultOrder = 'asc' }) => {
  const toast = useTopicLibraryWebToast();
  return useSavedTopicsPageBase({ per_page, order_by, defaultOrder,...toast })
}

export const useSavedTopicsAppPage = ({ per_page = 12, order_by = 'title', defaultOrder = 'asc' }) => {
   const toast = useTopicLibraryAppToast()
   return useSavedTopicsPageBase({ per_page, order_by, defaultOrder, ...toast })
}