import styles from "./PodcastNoteBlock.module.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import PodcastNote from "../PodcastNote/PodcastNote";

export function PodcastNoteBlock({ podcast }: any) {
  const [isNotesOpen, setIsNotesOpen] = useState(false);
    
  return (
    <div className={`${styles["notes-item"]}`}>
      <div className={`${styles["notes-item-header"]}`}>
        <div>
          <Link to={`/podcasts/${podcast.slug}`} className={`${styles["title"]}`}>
            {podcast.title}
          </Link>
        </div>
        <span onClick={() => setIsNotesOpen(!isNotesOpen)}>
          <i className={isNotesOpen ? `icon-chevron-right ${styles["up"]}` : "icon-chevron-right"}></i>
        </span>
      </div>

      {isNotesOpen ? (<div className={`${styles["notes-item-body"]}`}>
        {podcast.notes.map((n: any) => (
          <PodcastNote
            key={n.id}
            noteDate={n.created_at}
            noteText={n.note}
          />
        ))}
      </div>) : null }
    </div>
  );
}

export default PodcastNoteBlock;
