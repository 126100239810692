import { useRef } from "react";
import TopicsItem from "../TopicsPage/components/TopicsItem/TopicsItem";
import styles from "./SavedTopicsPage.module.scss";
import { Pagination } from "../../../../components";
import { useSavedTopicsPage } from '@podcasts/hooks';

export function SavedTopicsPage() {
  const myRef: any = useRef(null);
  const {
    data: { data = [], last_page = 0, current_page = 1 },
    order, 
    setOrder,
    isSavedTopic,
    onSaveTopic,
    onPageChange
  } = useSavedTopicsPage({ per_page: 12, defaultOrder: 'asc', order_by: 'title'});

  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <div ref={myRef} className={`${styles["container"]}`}>
      <div
        className={`${styles["title-box"]} d-flex justify-content-between align-items-center`}
      >
        <h2>Saved Topics</h2>
        <div className={`${styles["sort"]} d-flex align-items-center`} onClick={() => setOrder(order === "asc" ? "desc" : "asc")} >
          <span>Sort&nbsp;</span>
          <div className={`${order === "desc" ? styles["up"] : styles["down"]}`}>
            <i className="icon-chevron-sown"></i>
          </div>
        </div>
      </div>
      <div className={`${styles["topics-items"]}`}>
        {data.map((item: any, i: number) => (
          <div key={i} className={`${styles["topic-wrapp"]}`}>
            <TopicsItem
                saved={isSavedTopic(item.id)}
                {...item}
                onToggleFavorite={(e) => {
                  e.stopPropagation();
                  onSaveTopic(item);
                }}
            />
          </div>
        ))}
      </div>
      <Pagination
        pages={last_page}
        page={current_page}
        onPageChange={(page: number) => {
          onPageChange(page)
          executeScroll();
        }}
      />
    </div>
  );
}

export default SavedTopicsPage;
