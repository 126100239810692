import styles from "./TopicDetailsPage.module.scss";
import { useParams } from "react-router-dom";
import { InnerPageHeader } from "../../components/InnerPageHeader/InnerPageHeader";
import { useRef } from "react";
import { useTopicPage } from "@podcasts/hooks";
import SearchablePodcastTable from "../../../../components/PodcastsTable/SearchablePodcastTable";

export function TopicDetailsPage() {
  const { slug } = useParams();
  const myRef: any = useRef(null);
  const { data: topic = {}, isSavedTopic, onSaveTopic } = useTopicPage(slug);

  return (
    <div ref={myRef} className={`${styles["container"]}`}>
      <InnerPageHeader
        {...topic}
        backBtn="topics"
        backUrl="/podcasts/topics"
        saveBtn="topic"
        backgroundImg={topic?.image}
        handleClick={onSaveTopic}
        isSaved={isSavedTopic(topic.id)}
      />
      <SearchablePodcastTable params={{ topic_slug: [slug] }}/>
    </div>
  );
}
export default TopicDetailsPage;
