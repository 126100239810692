import TopicsItem from "../../../TopicsPage/components/TopicsItem/TopicsItem";
import PodcastTrackItem from "../PodcastTrackItem/PodcastTrackItem";
import styles from "./FeaturedTopicItem.module.scss";

export function FeaturedTopicItem({ title = '', podcasts, podcastsCount = podcasts.length, id, slug, image, description, bio, isUser, saved, onSavePodcast = () => {}, onToggleFavorite = () => { }, type, saved_ids }: any) {

  return (
    <div
      className={`${styles["featured-topic-item"]} d-flex align-items-center`}
    >
      <div className={`${styles["topic-wrapp"]}`}>
        <TopicsItem title={title} podcasts_count={podcastsCount} image={image} id={id} slug={slug} description={description} bio={bio} saved={saved} type={type} onToggleFavorite={onToggleFavorite} />
      </div>
      <div className={`${styles["podcasts-box"]}`}>
        {podcasts.slice(0, 4).map((p: any, i: number) => (
          <div key={i} className={`${styles["podcasts-wrapp"]}`}>
            <PodcastTrackItem {...p}
              saved={saved_ids.includes(p.id)}
              onToggleFavorite={() => {
                onSavePodcast(p)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeaturedTopicItem;
