// import styles from './Footer.module.scss';
import classNames from "classnames";
import React from "react";
import { bool } from "yup";
import { boolean } from "yup/lib/locale";
import styles from "../../../assets/scss/global.module.scss";

interface InputProps {
  label?: string;
  placeholder?: string;
  type?: string;
  value?: any;
  onChange?: any;
  onBlur?: any;
  error?: string | null;
  name?: string;
  disabled?: boolean;
}

export function Input({
  name = "",
  label = "",
  placeholder = "",
  type = "text",
  value = "",
  onChange,
  onBlur,
  error,
  disabled = false,
}: InputProps) {
  // console.log(disabled);

  const classNamesForInput = classNames(
    styles["inputContainer"],
    error ? styles["inputError"] : ""
  );

  return (
    <div>
      <div className={classNamesForInput}>
        <label>{label}</label>
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          autoComplete={type === "password" ? "new-password" : type}
        />
        {error && <span className={styles["errorMessage"]}>{error}</span>}
      </div>
    </div>
  );
}

export default Input;
