import { useCallback } from "react";
import { useCoursesLibraryBase } from './library-base';
import { useCourse } from './api';
import { useCourseLibraryAppToast } from './useAppLibraryToast';
import { useCourseLibraryWebToast } from './useWebLibraryToast';

export const useCourseBasePage = (slug,props = {}) => {
  const { isSavedCourse, toggleCourseLibrary, isAuthenticated }  = useCoursesLibraryBase(props);
  const  { data } = useCourse({ slug })

  const onSaveCourse = useCallback(() => {
    toggleCourseLibrary(data)
 },[data,isAuthenticated]) 

  return { data, isSavedCourse, onSaveCourse, isAuthenticated }
}


export const useCoursePage = (slug) => {
  const toast = useCourseLibraryWebToast();
  return useCourseBasePage(slug,toast);
}

export const useCourseAppPage = (slug) => {
  const toast = useCourseLibraryAppToast();
  return useCourseBasePage(slug,toast);
}

