import { useCallback } from 'react';

export const useLibraryAppToast = ({ type = "success", addedTitle, removedTitle }) => {
  const onAddFinish = useCallback(() => {
    toast.hideAll();
    toast.show(addedTitle, { placement: "bottom", type, offsetBottom: 150 });
  },[addedTitle]);


  const onRemoveFinish = useCallback(() => {
    toast.hideAll();
    toast.show(removedTitle, { placement: "bottom", type, offsetBottom: 150 });
   },[]);

  const onUnathorized = useCallback(() => {
    toast.hideAll();
    toast.show("Authorization is required", { placement: "bottom", type: "warning", offsetBottom: 150 });
   },[]);

   return { onAddFinish, onRemoveFinish, onUnathorized }
}

export const useTopicLibraryAppToast = () => useLibraryAppToast({ addedTitle: "Added to saved topics", removedTitle: "Removed from saved topics" });
export const useSpeakerLibraryAppToast = () => useLibraryAppToast({ addedTitle: "Added to saved speakers", removedTitle: "Removed from saved speakers" });
export const useCourseLibraryAppToast = () => useLibraryAppToast({ addedTitle: "Added to saved courses", removedTitle: "Removed from saved courses" });
export const usePodcastLibraryAppToast = ({ type } = { type: 'success'}) => useLibraryAppToast({ type, addedTitle: "Added to saved podcasts", removedTitle: "Removed from saved podcasts" });
