import { Link } from "react-router-dom";
import styles from "./InnerPageHeader.module.scss";
import PodcastCount from "../../../../../../components/PodcastCount/PodcastCount";
import { useIsAuthenticated } from "@podcasts/hooks";

interface InnerPageHeader {
  title: string;
  backBtn: string;
  backUrl: string;
  saveBtn: string;
  saveUrl?: string;
  backgroundImg: string;
  podcasts: any[];
}

export function InnerPageHeader({
  title = "",
  podcasts_count = 0,
  backBtn = "",
  saveBtn = "",
  backgroundImg = "",
  backUrl = "#",
  handleClick = null,
  isSaved = false,
}: any) {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div
      className={`${styles["inner-page-header"]}`}
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      <div className="d-flex align-items-start justify-content-between">
        <div className={`${styles["icon-title"]} d-flex align-items-start`}>
          <div>
            <h2 className="mb-0">{title}</h2>
            <p className="mb-0">
              <PodcastCount count={podcasts_count}/>
            </p>
          </div>
        </div>
        <div className={`${styles["actions"]} d-flex align-items-center`}>
          <Link
            to={backUrl}
            className="d-flex justify-content-center align-items-center"
          >
              Back to all {backBtn}
          </Link>
          {
            isAuthenticated && (
              <div
                onClick={handleClick}
                className={`${styles["speaker-save"]} ${
                  isSaved ? styles["saved"] : ""
                } d-flex justify-content-center align-items-center`}
              >
                {isSaved ?
                  <span>Saved</span>
                  :
                  (<><span>Save {saveBtn}</span><i className="icon-bookmark-empty"></i></>)
                }
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default InnerPageHeader;
