import { useEffect, useRef } from "react";

export const usePrevious = (servings) => {
  const ref = useRef(servings);

  useEffect(() => {
    ref.current = servings;
  }, [servings]);

  return ref.current;
}
