// import styles from './Footer.module.scss';
import React from "react";
import { map } from "lodash";
import { Button, Input, TextArea, InputSelect } from "../../../components";
import styles from "../MentorApply.module.scss";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { mentorSelector } from "@podcasts/store/mentor";
import { secondStepMentorSchema } from "@podcasts/schemas";
import { countriesList, genderSelectOptions } from "@podcasts/constants"

const countries = map(countriesList, (v) => ({
  value: v.isoCode,
  label: v.name,
}));

export function SecondStep({ initialValues, onNext, onPrev }: any) {
  const { error: registerErrorMessage } = useSelector(mentorSelector) as any;
  return (
    <div className={styles["step"]}>
      <div className="row">
        <div className="col-lg-6">
          <div className={styles["step-number"]}>
            <span>2</span>
          </div>
          <h2 className={`${styles["stepTitle"]}`}>
            Tell us about <br></br>
            <span className="orange-yellow-color">yourself</span>
          </h2>
        </div>
        <div className="col-lg-6">
          {registerErrorMessage && (
            <div className={`${styles["errorMessage"]}`}>
              { registerErrorMessage }
            </div>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={secondStepMentorSchema}
            onSubmit={(values, { setSubmitting }) => {
              onNext(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }: /* and other goodies */
            any) => (
              <div className={`${styles["mobile-form"]}`}>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <InputSelect
                      options={countries}
                      label="Country"
                      name="country"
                      onChange={({ value }: any) => {
                        setFieldValue("country", value);
                      }}
                      onBlur={handleBlur}
                      value={values.country}
                      error={
                        errors.country && touched.country ? "Required" : null
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="School"
                      name="school"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.school}
                      error={
                        errors.school && touched.school ? "Required" : null
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="City"
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      error={
                        errors.city && touched.city ? "Required" : null
                      }
                    />
                  </div>
                  <div className="col-lg">
                    <Input
                      label="State"
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      error={
                        errors.state && touched.state ? "Required" : null
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <TextArea
                      rows={7}
                      label="Bio:"
                      name="bio"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bio}
                      error={
                        errors.bio &&
                          touched.bio
                          ? "Required"
                          : null
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div
                    className={`${styles["prev-step"]} d-flex align-items-center col-lg`}
                  >
                    <span onClick={() => onPrev(values)}>Previous step</span>
                  </div>
                  <div className="col-lg mt-0">
                    <Button name="Submit" onClick={handleSubmit} />
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SecondStep;
