import React, { useEffect } from "react";
import styles from "../ResetPasswordPage/ResetPasswordPage.module.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "../../components";
import { forgetPassword } from "@podcasts/store/auth";
import { showToastMessage } from "../../utils/common";
import { authSelector } from "@podcasts/store/auth";
import { usePrevious } from "../../hooks/usePrevious";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

export function ForgotPasswordPage() {
  const dispatch = useDispatch();

  const { successMessage, error: errorMessage } = useSelector(authSelector) as any;
  const prevSuccessMessage = usePrevious(successMessage);
  const prevErrorMessage = usePrevious(successMessage);

  useEffect(() => {
    if (successMessage && successMessage !== prevSuccessMessage) {
      showToastMessage(successMessage);
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage && errorMessage !== prevErrorMessage) {
      showToastMessage(errorMessage, "error");
    }
  }, [errorMessage]);


  const handleForgetPasswordEmail = ({ email }) => {
    dispatch(forgetPassword({ email }));
  }

  return (
    <div className={`${styles["login-wrapp"]}`}>
      <div className={`${styles["login-decor"]}`}>
        <div></div>
      </div>
      <div className={`${styles["form"]} }`}>
        <div className="flex-column d-flex align-items-center">
          <h2 className={styles["header"]}>Forgot password?</h2>
          <p className={styles["header-subtitle"]}>
              Type the email address to recover your password.
          </p>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleForgetPasswordEmail(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <>
                <div className={`${styles["form-row"]}`}>
                  <Input
                    label="Email *"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={
                      errors.email && touched.email ? errors.email : null
                    }
                  />
                </div>
                <div className={`${styles["button-row"]}`}>
                  <Button
                    name="Send"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            )}

          </Formik>

        </div>
      </div>
      <div className={`${styles["login-decor"]}`}>
        <div></div>
      </div>
    </div>
  );
}
export default ForgotPasswordPage;
