import { Link, useNavigate } from "react-router-dom";
import styles from "./SpeakerCard.module.scss";
import PodcastCount from "../../../../../../components/PodcastCount/PodcastCount";
import { useIsAuthenticated } from "@podcasts/hooks";

export function SpeakerCard({
  title,
  fname,
  lname,
  podcasts_count,
  slug,
  image,
  saved,
  type = "speakers",
  description,
  bio,
  onToggleFavorite = () => {},
}: any) {
  
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const handleViewSpeaker = () => {
    navigate(`/podcasts/speakers/${slug}`);
  }

  return (
    <div className={`${styles["item"]} orange-after-box ${styles["cursor-pointer"]}`} onClick={handleViewSpeaker}>
      <div className={`${styles["item-header"]}`}>
        <img src={image} alt={`${title} ${fname} ${lname}`} />
      </div>
      <div className={`${styles["item-body"]}`}>
        <p className={styles["item-body-name"]}>{title} {fname} {lname}</p>
        <div className={`${styles["item-body-episode"]}`}>
          <p>
            <PodcastCount count={podcasts_count}/>
          </p>
          <p className={styles["item-body-desc"]}>{description ? description : bio}</p>
        </div>
        <div
          className={`${styles["item-body-link"]} d-flex justify-content-between align-items-center`}
        >
          <Link to={`/podcasts/speakers/${slug}`}>View speaker</Link>
          {/* <button className="normalize-btn">
            <i className="icon-play-random"></i>
          </button> */}
          {
            isAuthenticated && (
              <button onClick={onToggleFavorite}>
                <i
                  className={`${
                    saved ? "icon-bookmark-full" : "icon-bookmark-empty"
                  }`}
                ></i>
                
              </button>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default SpeakerCard;
