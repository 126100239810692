// import styles from './Footer.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { appSelector } from "@podcasts/store/application";
import styles from "./RegisterPage.module.scss";
import { FirstStep, SecondStep, ThirdStep, ThankYou } from "./Steps";
import Schedule from "./Steps/Schedule";
import {
  prevStep,
  setFirstStep,
  setThirdStep,
  setSecondStep,
  nextStep,
  getIncompleteUser
} from "@podcasts/store/application";
import { pick } from "lodash";
import ErrorPage from "./ErrorPage/ErrorPage";
import { useQueryParam } from "../../hooks/useQueryParam";
import { useEffect } from "react";
import { usePrevious } from "../../hooks/usePrevious";
import { showToastMessage } from "../../utils/common";
import { useIsAuthenticated } from "@podcasts/hooks";
import { useNavigate } from "react-router-dom";

export function RegisterPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { step, user, isBornJewish, getUserSuccessMessage: successMessage } = useSelector(appSelector) as any;
  const prevSuccessMessage = usePrevious(successMessage);
  const token = useQueryParam("token");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (token) {
      dispatch(getIncompleteUser({ token }));
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      showToastMessage("No need to register, you already have an account!", "error");
      navigate("/podcasts/featured");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (successMessage && successMessage !== prevSuccessMessage) {
      showToastMessage(successMessage);
    }
  }, [successMessage]);

  const firsStepInitialValue = pick(user, [
    "email",
    "fname",
    "lname",
    "phone",
    "gender",
    "dob",
    "city_residence",
    "state_residence",
    "country",
  ]);
  const secondStepInitialValue = pick(user, [
    "formal_jewish",
    "religion_born",
    "religion_identify_with",
    "father_religion",
    "mother_religion",
    "jewish_story",
    "jewish_education_description",
    "jewish_education",
  ]);
  const thirdStepInitialValue = pick(user, [
    "referral_method",
    "current_position",
    "position",
    "school",
    "city",
    "state",
    "internship",
    "referral_name",
    "other_details"
  ]);

  const commonProp: any = {
    onPrev: () => {
      dispatch(prevStep());
    },
  };

  const renderSteps = (n: number): any => {
    switch (n) {
    case 1:
      return (
        <FirstStep
          {...commonProp}
          initialValues={firsStepInitialValue}
          onNext={(values: any) => {
            dispatch(setFirstStep(values));
          }}
        ></FirstStep>
      );
    case 2:
      return (
        <SecondStep
          {...commonProp}
          initialValues={secondStepInitialValue}
          onNext={(values: any) => {
            dispatch(setSecondStep(values));
          }}
        ></SecondStep>
      );
    case 3:
      return (
        <ThirdStep
          {...commonProp}
          initialValues={{ ...thirdStepInitialValue, accept: false }}
          onNext={(values: any) => {
            dispatch(setThirdStep(values));
          }}
        ></ThirdStep>
      );
    case 4:
      return (
        <Schedule
          user={user}
          {...commonProp}
          onNext={(values: any) => {
            dispatch(nextStep());
          }}
        />
      );
    case 5:
      return <ThankYou isBornJewish={isBornJewish}></ThankYou>;
    case 6:
      return <ErrorPage></ErrorPage>;
    }
  };
  return (
    <div
      className={`${styles["spaceBetweenHeaderFooter"]} container-fluid mobile-container`} >
      {renderSteps(step)}
    </div>
  );
}

export default RegisterPage;
