import PlayIcon from "../../../../components/PlayIcon/PlayIcon";
import StarNameItem from "../../../../components/StarNameItem/StarNameItem";
import styles from "./PodcastsTable.module.scss";
import { Link, useLocation } from "react-router-dom";
import { usePodcastsLibraryBase  } from "@podcasts/hooks";
import { useEffect, useState } from "react";

export function PodcastsTable({
  items = [],
  handleSortChange,
  order_by,
  order,
  pageName = null
}: any) {
  const [hasSpeaker, setHasSpeaker] = useState(true);

  const { togglePodcastLibrary, isSavedPodcast } = usePodcastsLibraryBase();
  const params = useLocation();

  let tableHeaderCol = [
    {
      name: "Title",
      filter: true,
      sort: true,
      isShow: true
    },
    {
      name: "Topic",
      filter: true,
      sort: true,
      isShow: hasSpeaker
    },
    {
      name: "Level",
      filter: true,
      sort: true,
      isShow: true
    },
    {
      name: "Duration",
      filter: true,
      sort: true,
      isShow: true

    },
  ];

  useEffect(() => {
    if (params.pathname.includes("/podcasts/courses")) {
      setHasSpeaker(false);
    }
  }, [params]);

  return (
    <div className={`${styles["saved-podcasts-table"]}`}>
      <table className="table mb-4">
        <thead>
          <tr>
            {tableHeaderCol.filter(col => col.isShow).map((th: any, i: number) => (
              <th
                key={i}
                className={`${th.filter ? styles["filter"] : ""} border-top-0`}
                scope="col"
                onClick={th.sort ? handleSortChange : null}
              >
                <div className={`${th.sort ? styles["sort"] : "d-flex"}`}>
                  <span>{th.name}</span>{" "}
                  <div className={`${order_by === th.name.toLowerCase() && order === "desc" ? styles["up"] : ""}`}>
                    {th.filter && <i className="icon-chevron-sown"></i>}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((sp: any, i: number) => (
            <tr key={i}>
              <th scope="row">
                <StarNameItem {...sp} saved={isSavedPodcast(sp.podcast_id ?? sp.id)} onToggleFavorite={() => {
                  togglePodcastLibrary(sp);
                }} />
              </th>
              {sp.speaker_id ? (<td className={`${styles["topic-column-item"]}`}>
                <span className={`${styles["saved-podcasts-topic"]}`}>
                  {sp.topics.map((t: any, i: number) => (
                    <span key={i}>{t.title} </span>
                  ))}
                </span>
              </td>) : (pageName === "recently_viewed" || pageName === "saved" ? ( <td></td>) : null)}

              <td className={`${styles["level-column-item"]}`}>
                <span className="d-inline-flex align-items-center">
                  <i className="icon-rocket red-icon me-2"></i>
                  <span>{sp.level}</span>
                </span>
              </td>
              {/* <td className={`${styles['date-column-item']}`}>
                                    <span className={`${styles['saved-podcasts-date']}`}>
                                       { sp.created_at}
                                    </span>
                                </td> */}
              <td>
                <div className="d-flex align-items-center justify-content-end">
                  <span className="d-inline-flex align-items-center">
                    <span className="me-4">{sp.duration}</span>
                    <Link to={`/podcasts/${sp.slug}`}>
                      <PlayIcon />
                    </Link>
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PodcastsTable;
