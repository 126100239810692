import classNames from "classnames";
import styles from "../../../assets/scss/global.module.scss";

interface TextAreaProps {
  label?: string;
  rows?: number;
  placeholder?: string;
  value?: any;
  onChange?: any;
  onBlur?: any;
  error?: string | null;
  name?: string;
}

export function TextArea({
  label,
  rows = 5,
  placeholder = "",
  value = "",
  onChange,
  onBlur,
  error,
  name,
}: TextAreaProps) {
  const classNamesForTextarea = classNames(
    styles["inputContainer"], styles["textAreaContainer"],
    error ? styles["inputError"] : ""
  );

  return (
    <div>
      <div className={classNamesForTextarea}>
        <label>{label}</label>
        <textarea
          rows={rows}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        ></textarea>
      </div>
      {error && <span className={styles["errorMessage"]}>{error}</span>}
    </div>
  );
}
export default TextArea;
