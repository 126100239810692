import { configureStore } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
import { userReducer } from "./user";
import { profileReducer } from "./profile";
import { dashboardReducer } from "./dashboard";
import { apiReducer } from "./api";
import { appReducer } from "./application";
import { searchReducer } from "./search";
import { authReducer } from "./auth";
import { mentorReducer } from "./mentor";
import Interceptor from "./config";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";

import AsyncStorage from "@react-native-async-storage/async-storage";

const persistConfig = {
  key: "user",
  storage: AsyncStorage,
};
const persisterReducer = persistReducer(persistConfig, userReducer);

const searchPersistReducer = persistReducer({
  key: "search",
  storage: AsyncStorage,
}, searchReducer);

const reducers = {
  user: persisterReducer,
  search: searchPersistReducer,
  profile: profileReducer,
  dashboard: dashboardReducer,
  api: apiReducer,
  application: appReducer,
  auth: authReducer,
  mentor: mentorReducer
};


export const store = configureStore({
  reducer: reducers,
  middleware: [thunk],
});

export const persistor = persistStore(store);

Interceptor.interceptor(store);