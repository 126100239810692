import React from "react";
import {
  Button,
  Input,
  InputSelect,
  DatePickerInput,
} from "../../../components";
import styles from "../MentorApply.module.scss";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { appSelector } from "@podcasts/store/application";
import { Link } from "react-router-dom";
import { firstStepMentorSchema } from "@podcasts/schemas";
import { genderSelectOptions } from "@podcasts/constants"


export function FirstStep({ initialValues, onNext }: any) {

  const { error: registerErrorMessage } = useSelector(appSelector) as any;

  return (
    <div className={`${styles["step"]}`}>
      <div className="row">
        <div className={`${styles["step-text"]} col-lg-6`}>
          <div className={styles["step-number"]}>
            <span>1</span>
          </div>
          <h2 className={`${styles["stepTitle"]}`}>
            Let's <span className="orange-yellow-color">get started</span>
          </h2>
          <p>
          Thank you for applying! 
          Join our community of mentors and help shape the Jewish learning journey of aspiring students at The Podcast Fellowship.
          </p>
        </div>
        <div className="col-lg-6">
          <Formik
            initialValues={initialValues}
            validationSchema={firstStepMentorSchema}
            onSubmit={(values, { setSubmitting }) => {
              onNext({ ...values });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }: /* and other goodies */
            any) => (
              <div className={`${styles["mobile-form"]}`}>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="First Name *"
                      name="fname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fname}
                      error={errors.fname && touched.fname ? "Required" : null}
                    />
                  </div>
                  <div className="col-lg">
                    <Input
                      label="Last Name *"
                      name="lname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lname}
                      error={errors.lname && touched.lname ? "Required" : null}
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="Email *"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email ? errors.email : null}
                    />
                    {registerErrorMessage && (
                      <div className={`${styles["errorMessage"]}`}>
                        { registerErrorMessage }
                        <Link to={"/login"}> Login</Link>
                      </div>
                    )}
                  </div>
                  <div className="col-lg">
                    <Input
                      label="Phone Number *"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      error={errors.phone && touched.phone ? errors.phone : null}
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <InputSelect
                      options={genderSelectOptions}
                      label="Gender *"
                      placeholder="Please select:"
                      name="gender"
                      onChange={({ value }: any) => {
                        setFieldValue("gender", value);
                      }}
                      onBlur={handleBlur}
                      value={values.gender}
                      error={
                        errors.gender && touched.gender ? "Required" : null
                      }
                    />
                  </div>
                  <div className="col-lg">
                    <DatePickerInput
                      label="Date of Birth *"
                      placeholder="mm/dd/yyyy"
                      name="dob"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.dob}
                      error={errors.dob && touched.dob ? errors.dob : null}
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="Calendly url *"
                      name="calendly_url"
                      onChange={handleChange}
                      placeholder="https://calendly.com/"
                      onBlur={handleBlur}
                      value={values.calendly_url}
                      error={errors.calendly_url && touched.calendly_url ? errors.calendly_url : null}
                    />
                  </div>
                </div>
                
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg mt-0"></div>
                  <div className="col-lg mt-0">
                    <Button name="Next step" onClick={handleSubmit} />
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default FirstStep;
