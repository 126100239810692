import { useCallback, useState } from "react";
import { useRecentNotes } from './api';
  
export const useRecentNotesPage = () => {

    const [page, setPage] = useState(1);
    const [order, setOrder] = useState("asc");
    const [order_by, setOrder_By] = useState("title");

    const { 
      data,
      fetchNextPage,
      isFetchingPreviousPage,
      isFetchingNextPage,
      isFetching,
      isRefetching } = useRecentNotes({ page, order, order_by });
  
    const onNextPage = useCallback(() => {
      if (!isFetchingPreviousPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, [isFetchingPreviousPage, isFetchingNextPage]);

    const onPageChange = useCallback((p) => {
       setPage(p)
    },[])

    const toggleOrder = useCallback(() => {
        setOrder(order === "asc" ? "desc" : "asc");
        setPage(1);
    }, [order]);

    return {
        data,
        order,
        toggleOrder,
        fetchNextPage,
        isFetchingPreviousPage,
        isFetchingNextPage,
        isFetching,
        isRefetching,
        onNextPage,
        onPageChange,
    }
}