import { useEffect, useState, useRef } from "react";
import { userSelector } from  "@podcasts/store/user";
import { updateStudentImage } from "@podcasts/store/user";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ProfileDetails.module.scss";
import { upperFirst } from "lodash";
import Modal from "react-modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { EditImage } from "./components/EditImage/EditImage";
import { useDispatch } from "react-redux";

const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    with: "60%",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

const urltoFile = (url: string, filename: string, mimeType: string): any => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export function ProfileDetails() {
  const { user } = useSelector(userSelector) as any;
  const dispatch = useDispatch();
  const params = useParams();
  const cropperRef = useRef<HTMLImageElement>(null);
  const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState<any>();

  const editMode = params["*"] === "edit";

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      closeModal();

      urltoFile(
        cropper.getCroppedCanvas().toDataURL(),
        "hello.png",
        "image/png"
      ).then(function (file: any) {
        console.log(file);

        dispatch(updateStudentImage(file))
      });
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const img =
    "https://www.kettler.co.uk/cms2/wp-content/uploads/2020/01/panalsol-elba-corner-side-table-lifestyle-2019_1.jpg";
  const navigate = useNavigate();

  function openModal(img: string) {
    setImage(img); 
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  const ReactModal = Modal as any;


  useEffect(() => {
    Modal.setAppElement("body");
  }, []);
  return (
    <div className={`${styles["profile-details"]}`}>
      <div>
        <ReactModal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          ariaHideApp={true}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div>
            <Cropper
              style={{ height: 400, width: "500px" }}
              zoomTo={0.5}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
            <button onClick={closeModal}>close</button>
            <button onClick={getCropData}>Crop image</button>
          </div>
        </ReactModal>
      </div>
      <div className={`${styles["profile-details-info"]}`}>
        <EditImage image={user?.image} onClick={openModal} edit={true} />
        <div className={`${styles["user-details"]}`}>
          <div className={`${styles["user-details-box"]}`}>
            <p className={`${styles["user-details-name"]}`}>
              {user?.fname} {user?.lname}
            </p>
            <ul className={`${styles["user-details-list"]}`}>
              <li>
                <i
                  className={`${styles["user-details-rocket"]} icon-rocket`}
                ></i>
                <span>
                  <strong>Level: </strong>
                  {upperFirst(user?.difficulty)}
                </span>
              </li>
              <li>
                <i className="icon-mail"></i>
                <span>{user?.email}</span>
              </li>
              <li>
                <i className="icon-telephone"></i>
                <span>{user?.phone ? user?.phone : "---"}</span>
              </li>
              <li>
                <i className="icon-pin"></i>
                <span>{user?.city}</span>
              </li>
              <li>
                <i className="icon-bank"></i>
                <span>{user?.school}</span>
              </li>
            </ul>
          </div>
          {
            !editMode && (
              <div className={`${styles["profile-details-btn"]}`}>
                <button
                  onClick={() => {
                    navigate("/profile/edit");
                  }}
                  className="normalize-btn"
                >
                      Edit profile
                </button>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default ProfileDetails;
