import axios from "axios";
const qs = require("qs");

export const getFeaturedPodcasts = async () => {
  return await axios.get(`podcasts/featured`);
};

export const getSavedPodcastsApi = async (options = {}) => {
  delete options.saved_podcasts_ids
  return await axios.get(`library/podcasts?${qs.stringify(options)}`);
};

export const getPodcastApi = async (podcastId) => {
  return await axios.get(`podcasts/${podcastId}`);
};

export const getPodcastSpeakerApi = async (slag) => {
  return await axios.get(`podcasts/speaker/${slag}`);
};

export const getPodcastTopicApi = async (slag) => {
  return await axios.get(`podcasts/topic/${slag}`);
};

export const youMayAlsoLikeApi = async (podcastSlug) => {
  return await axios.get(`podcasts/${podcastSlug}/may-also-like`);
};

export const saveRecentlyViewedApi = async (podcastSlug) => {
  return await axios.post(`students/recently-viewed/${podcastSlug}`);
};

export const getRecentlyViewedApi = async (options = {}) => {
  return await axios.get(`students/recently-viewed?${qs.stringify(options)}`);
};
