import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import styles from "./ProfilePayments.module.scss";
import PaymentsTable from "./components/ReferralsTable/ReferralsTable";
import { useReferralsPage } from "@podcasts/hooks";
import { Pagination } from "../../../../components";

const tableHeaderCol = [
  {
    name: "Date Paid",
    filter: true,
    order: "created_at",
  },
  {
    name: "Date Referral",
    filter: true,
    order: "created_at",
  },
  {
    name: "Payment Amount",
    filter: false,
  },
  {
    name: "Referred",
    filter: false,
  },
  {
    name: "Referred School",
    filter: false,
  },
  {
    name: "Referred City",
    filter: false,
  },
];

export function ProfileReferrals() {
  const myRef: any = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth" });
  const {
    data: { data = [], last_page, current_page },
    total_amount,
    last_request_on,
    onPageChange
  } = useReferralsPage({ per_page: 15 });

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");

  return (
    <div className={`${styles["profile-payments"]}`}>
      <div className={`${styles["back-box"]}`}>
        <Link to={"/profile"}>
          <i className="icon-chevron-right"></i>
        </Link>
      </div>
      <div className={`${styles["profile-payments-top"]}`}>
        <div className={`${styles["total-box"]}`}>
          <h3>Referrals</h3>
          <p>
            Total to date: <strong>${total_amount}</strong>
          </p>
        </div>

      </div>
      {(data && data.length) ? (
        <><PaymentsTable header={tableHeaderCol} rows={data} order={order} setOrder={setOrder} setOrderBy={setOrderBy}/>
          <Pagination
            pages={last_page}
            page={current_page}
            onPageChange={(page: number) => {
              onPageChange(page);
              executeScroll();
            }}
          />
        </>
      ) : null }
    </div>
  );
}

export default ProfileReferrals;
