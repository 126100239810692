import { Provider } from "react-redux";
import { store } from "@podcasts/store";
import AuthGuard from "./components/Main/Main";

import { ToastContainer } from "react-toastify";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";

import ReactGA from "react-ga4";

import {
  QueryClient,
  QueryClientProvider,
} from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false
    },
  },
} as any)

export function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AuthGuard />
        <ToastContainer />
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
