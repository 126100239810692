import { Link } from "react-router-dom";
import styles from "./SidebarList.module.scss";
import PodcastCount from "../../../../../../components/PodcastCount/PodcastCount";
import PlayIcon from "../../../../../../components/PlayIcon/PlayIcon";

export function SidebarList({ title, limit = 3, list = [], addressViewAll, addressForPodcast }: any) {

  return (
    <div className={`${styles["list-container"]} right-sidebar-component`}>
      <div
        className={`${styles["header"]} d-flex justify-content-between align-items-center`}
      >
        <p className={styles["title"]}>{title}</p>
        <Link to={`/podcasts/${addressViewAll}`}>View all</Link>
      </div>
      {list.length === 0 ? <p className={styles["no-items"]}>No items to display</p> :
        <ul className={styles["list-container-list"]}>
          {list
            .map((l: any, i: number) => (
              <li key={i}>
                <Link
                  to={title !== "Saved Podcasts" ? `../${addressForPodcast || addressViewAll}/${l.slug}` : `/podcasts/${l.slug}`}
                >
                  {title !== "Saved Podcasts" ?
                    <div className={`${styles["item-image"]}`}>
                      <img src={l.image} alt={title === "Speakers" ? `${l.title} ${l.fname} ${l.lname}` : `${l.title}`} />
                    </div> :
                    <div className={`${styles["item-podcast-image"]}`}>
                      <PlayIcon />
                    </div>}              
                  <div>
                    {title === "Speakers" || "Saved Speakers" ? <p>{l.title} {l.fname} {l.lname}</p> : <p>{l.title}</p>}
                    {title !== "Saved Podcasts" && <span><PodcastCount count={l.podcasts_count} /></span>}
                
                  </div>
                </Link>
              </li>
            ))}
        </ul>}
    </div>
  );
}

export default SidebarList;
