import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getNotesByPodcastId,
  createNote,
  getAllNotesApi,
  deleteNodeApi,
  updateNoteApi,
  sendNotesToMentorApi,
  requestPayOutApi,
} from "@podcasts/api";
import {
  getAllSpeakers,
  getAllSpeakerPodcasts,
} from "@podcasts/api";
import {
  savePodcastApi,
  getSavedPodcastIdsApi,
  saveSpeakersApi,
  getSavedSpeakersIdsApi,
  referFriendsApi,
  getRequestPayOutApi,
} from "@podcasts/api";
import {
  getAllTopics,
  getTopicPodcastsApi,
} from "@podcasts/api";
import {
  getSavedPodcastsApi,
  getPodcastApi,
  youMayAlsoLikeApi,
  saveRecentlyViewedApi,
  getRecentlyViewedApi,
} from "@podcasts/api";

import { getCourseApi, getAllCoursesApi } from '@podcasts/api';
import { LibraryService } from "@podcasts/api";
import { get } from "lodash";

const FeaturedItems = [];

export const getRequestPayOut = createAsyncThunk(
  "dashboard/getRequestPayOut",
  async (podcastId, { rejectWithValue }) => {
    try {
      const {
        data: { is_sent = false, total_request = 0 },
      } = await getRequestPayOutApi(podcastId);
      return {is_sent, total_request};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const clearSelectedPodcast = createAsyncThunk(
  "dashboard/clearSelectedPodcast",
  async (podcastId, { rejectWithValue }) => {
    try {
      return {};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSavedPodcastIds = createAsyncThunk(
  "dashboard/getSavedPodcastIds",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await getSavedPodcastIdsApi();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSavedSpeakersIds = createAsyncThunk(
  "dashboard/getSavedSpeakersIds",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getSavedSpeakersIdsApi();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSavedCoursesIds = createAsyncThunk(
  "dashboard/getSavedCoursesIds",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await LibraryService.getSavedCoursesIds();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllNotes = createAsyncThunk(
  "dashboard/getSavedSpeakersIds",
  async (options, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getAllNotesApi();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const savePodcast = createAsyncThunk(
  "dashboard/savePodcast",
  async (podcast, { rejectWithValue, getState }) => {
    try {
      const ids = get(getState(), "dashboard.saved_podcasts_ids");
      const removed = ids.includes(podcast.id);

      const { data } = await savePodcastApi(podcast.slug);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveSpeaker = createAsyncThunk(
  "dashboard/saveSpeaker",
  async (speaker, { rejectWithValue, getState }) => {
    try {
      const ids = get(getState(), "dashboard.saved_speakers_ids");
      const removed = ids.includes(speaker.id);

      const { data } = await saveSpeakersApi(speaker.slug);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSavePodcast = createAsyncThunk(
  "dashboard/getSavePodcast",
  async (options, { rejectWithValue, getState }) => {
    try {
      const { data } = await getSavedPodcastsApi(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSavedSpeakers = createAsyncThunk(
  "dashboard/getSavedSpeakers",
  async (options, { rejectWithValue, getState }) => {
    try {
      const { data } = await LibraryService.getSavedSpeakersApi(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTopics = createAsyncThunk(
  "dashboard/getTopics",
  async (options = {}, { rejectWithValue }) => {
    try {
      const { data } = await getAllTopics(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSpeakers = createAsyncThunk(
  "dashboard/getSpeakers",
  async (options = {}, { rejectWithValue }) => {
    try {
      const { data } = await getAllSpeakers(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSpeakerPodcasts = createAsyncThunk(
  "dashboard/getSpeakerPodcasts",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getAllSpeakerPodcasts(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTopicPodcasts = createAsyncThunk(
  "dashboard/getTopicPodcasts",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getTopicPodcastsApi(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSeries = createAsyncThunk(
  "dashboard/getSeries",
  async (options, { rejectWithValue }) => {
    try {
      console.log("Get Series - ", FeaturedItems);
      return FeaturedItems;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSearch = createAsyncThunk(
  "dashboard/getSearch",
  async (options, { rejectWithValue }) => {
    try {
      console.log("Get Search - ", FeaturedItems);
      return FeaturedItems;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPodcast = createAsyncThunk(
  "dashboard/getPodcast",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getPodcastApi(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getIntroCourses = createAsyncThunk(
  "dashboard/getIntroCourses",
  async (options, { rejectWithValue }) => {
    try {
      console.log("Get IntroCourses - ", FeaturedItems);
      return FeaturedItems;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPodcastNotes = createAsyncThunk(
  "dashboard/getNotes",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await getNotesByPodcastId(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const youMayAlsoLike = createAsyncThunk(
  "dashboard/youMayAlsoLike",
  async (podcastSlug, { rejectWithValue }) => {
    try {
      const { data } = await youMayAlsoLikeApi(podcastSlug);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteNode = createAsyncThunk(
  "dashboard/deleteNode",
  async (noteId, { rejectWithValue }) => {
    try {
      const { data } = await deleteNodeApi(noteId);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendNotesToMentor = createAsyncThunk(
  "dashboard/sendNotesToMentor",
  async (podcastSlug, { rejectWithValue }) => {
    try {
      const {
        data,
      } = await sendNotesToMentorApi(podcastSlug);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNote = createAsyncThunk(
  "dashboard/addNote",
  async ({ id, note }, { rejectWithValue }) => {
    try {
      const { data } = await createNote(id, note);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateNote = createAsyncThunk(
  "dashboard/updateNote",
  async ({ id, note }, { rejectWithValue }) => {
    try {
      const { data } = await updateNoteApi(id, note);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveRecentlyViewed = createAsyncThunk(
  "dashboard/saveRecentlyViewed",
  async (podcastSlug, { rejectWithValue }) => {
    try {
      const { data } = await saveRecentlyViewedApi(podcastSlug);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRecentlyViewed = createAsyncThunk(
  "dashboard/getRecentlyViewed",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await getRecentlyViewedApi(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllCourses = createAsyncThunk(
  "dashboard/getAllCourses",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await getAllCoursesApi();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCourse = createAsyncThunk(
  "dashboard/getCourse",
  async (courseSlug, { rejectWithValue }) => {
    try {
      const { data } = await getCourseApi(courseSlug);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveTopic = createAsyncThunk(
  "dashboard/saveTopic",
  async (topic, { rejectWithValue, getState }) => {
    try {
      const ids = get(getState(), "dashboard.saved_topics_ids");
      const removed = ids.includes(topic.id);

      const { data } = await LibraryService.savedTopic(topic.slug);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveCourse = createAsyncThunk(
  "dashboard/saveCourse",
  async (course, { rejectWithValue, getState }) => {
    try {
      const ids = get(getState(), "dashboard.saved_courses_ids");
      const removed = ids.includes(course.id);

      const { data } = await LibraryService.savedCourse(course.slug);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSavedTopicsIds = createAsyncThunk(
  "dashboard/getSavedTopicsIds",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await LibraryService.getSavedTopicsIds();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSavedTopics = createAsyncThunk(
  "dashboard/getSavedTopics",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await LibraryService.getSavedTopics(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSavedCourses = createAsyncThunk(
  "dashboard/getSavedCourses",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await LibraryService.getSavedCourses(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const requestPayout = createAsyncThunk(
    "user/requestPayout",
    async (options, { rejectWithValue }) => {
        try {
            const { data } = await requestPayOutApi(options);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const resetState = createAsyncThunk(
    "user/resetState",
    async (options, { rejectWithValue }) => {
        try {
            return {};
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
