import { useCallback } from "react";
import { useTopicLibraryBase,
         usePodcastsLibraryBase,
         useCoursesLibraryBase, 
         useSpeakerLibraryBase } from './library-base';
import { useFeatured } from './api'
import { useCourseLibraryAppToast,
         useSpeakerLibraryAppToast,
         useTopicLibraryAppToast,
         usePodcastLibraryAppToast } from './useAppLibraryToast';
  import {  useCourseLibraryWebToast,
            useSpeakerLibraryWebToast,
            useTopicLibraryWebToast,
            usePodcastLibraryWebToast } from './useWebLibraryToast';

export const useFeaturedBasePage = (props) => {
  const {
     togglePodcastLibrary, 
     isSavedPodcast,
     saved_podcasts_ids,
     isSavedCourse, 
     toggleCourseLibrary,
     isSavedTopic, 
     toggleTopicLibrary,
     isSavedSpeaker,
     toggleSpeakerLibrary,
     isAuthenticated
    } = props;

    const { data: {
      topic,
      course, 
      speaker,
      podcasts,
      topicsCount,
      coursesCount,
      speakersCount,
    } } = useFeatured({  });

    const onSaveSpeaker = useCallback(() => {
      toggleSpeakerLibrary(speaker)
    },[speaker,isAuthenticated]);
  
    const onSavePodcast = useCallback((podcast) => {
          togglePodcastLibrary(podcast)
    },[isAuthenticated]);
      
    const onSaveTopic = useCallback(() => {
       toggleTopicLibrary(topic)
    },[topic,isAuthenticated]) 
  
    const onSaveCourse = useCallback(() => {
      toggleCourseLibrary(course)
   },[course,isAuthenticated]) 

   return {  
    topic,
    course, 
    speaker,
    topicsCount,
    isAuthenticated,
    coursesCount,
    speakersCount,
    podcasts,
    isSavedCourse,
    isSavedTopic,
    isSavedPodcast,
    isSavedSpeaker,
    onSaveSpeaker,
    onSavePodcast,
    onSaveTopic,
    onSaveCourse,
    saved_podcasts_ids}
}

export const useFeaturedPage = () => {
  const courseToast = useCourseLibraryWebToast()
  const speakerToast = useSpeakerLibraryWebToast();
  const topicToast = useTopicLibraryWebToast();
  const podcastToast = usePodcastLibraryWebToast()

  const podcastLibrary = usePodcastsLibraryBase(podcastToast)
  const courseLibrary  = useCoursesLibraryBase(courseToast);
  const topicLibrary  = useTopicLibraryBase(topicToast);
  const speakerLibrary = useSpeakerLibraryBase(speakerToast);

  return useFeaturedBasePage({...podcastLibrary,...courseLibrary,...topicLibrary,...speakerLibrary})
}

export const useFeaturedAppPage = () => {
  const courseToast = useCourseLibraryAppToast()
  const speakerToast = useSpeakerLibraryAppToast();
  const topicToast = useTopicLibraryAppToast();
  const podcastToast = usePodcastLibraryAppToast()

  const podcastLibrary = usePodcastsLibraryBase(podcastToast)
  const courseLibrary  = useCoursesLibraryBase(courseToast);
  const topicLibrary  = useTopicLibraryBase(topicToast);
  const speakerLibrary = useSpeakerLibraryBase(speakerToast);

  return useFeaturedBasePage({...podcastLibrary,...courseLibrary,...topicLibrary,...speakerLibrary})
}