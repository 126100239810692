import axios from "axios";

const qs = require("qs");

export const getAllSpeakers = async (options = {}) => {
  return await axios.get(`speakers?${qs.stringify(options)}`).then((d) => {
    return d;
  });
};

export const getAllSpeakerPodcasts = async ({ slug }) => {
  return await axios.get(`speakers/${slug}`);
};

export const getSavedSpeakersApi = async () => {
  return await axios.get(`library/ids/speaker`);
};
