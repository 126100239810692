/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import styles from "../RegisterPage.module.scss";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import moment from "moment";

export function Schedule({ user, onNext, onPrev }: any) {
  const [calendlyUrl, setCalendlyUrl] = useState("https://calendly.com/podcastfellow/interview");
  const [prefillData, setPrefillData] = useState({});

  useEffect(() => {
    if (user) {
      if (moment().diff(user.dob, "years") >= 32) {
        setCalendlyUrl("https://calendly.com/dhaas-1-1-learning/15min");
      }
      setPrefillData({
        name: `${user.fname} ${user.lname}`,
        email: user.email,
        phone: user.phone,
        a1: user.phone,
        customAnswers: {
          a1: user.phone,
        }
      });
    }
  }, [user]);

  useCalendlyEventListener({
    onEventScheduled: (e) => { 
      setTimeout(() => onNext(), 3000); 
    },
  });

  return (
    <div className={styles["step"]}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className={styles["step-number"]}>
              <span>4</span>
            </div>
            <h2 className={`${styles["stepTitle"]}`}>
              Schedule an onboarding meeting to{" "}
              <span className="orange-yellow-color">
                complete your application.
              </span>
            </h2>
            <p>Please select time to meet. We look forward to getting to know you and guiding you through the program!</p>
          </div>
          <div className="col-lg-8 col-md-6">
            {/* <p>Please select a time for your intake interview.  An interview is required in order to be accepted to the Podcast Fellowship</p> */}
            <InlineWidget url={calendlyUrl} prefill={prefillData} styles={{ height: "900px" }}/>
            {/* <div className="mt-5 row">
              <div className="col-sm">
                <CheckBox
                  name=""
                  value={false}
                  label="I have selected a timeslot for my intake interview from the calendar above."
                  checked={false}
                />
              </div>
            </div> */}
            <div className={`${styles["formRow"]} row`}>
              {/* <div
                className={`${styles["prev-step"]} d-flex align-items-center col-lg`}
              >
                <span onClick={onPrev}>
                    Previous step
                </span>
              </div> */}
              <div className="col-lg mt-0">
                {/* <Button
                  name="Submit"
                  onClick={() => {
                    onNext();
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schedule;
