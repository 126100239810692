import styles from "./UserLevel.module.scss";
import { levelLabels } from '@podcasts/constants'

export function UserLevel({ level = levelLabels.BEGINNER }) {
  return (
    <div className={`${styles["user-level"]} d-flex align-items-center`}>
      <i className="icon-rocket"></i>
      <div className={`${styles["user-level-text"]}`}>
        <span>
          <strong>Level: </strong>
          {level}
        </span>
      </div>
    </div>
  );
}
