import React from "react";
import styles from "./TriviaPage.module.scss";

export function TriviaPage() {
  return (
    <div className={styles["trivia-page"]}>
      <div className={styles["content-container"]}>
        <section className={styles["title-container"]}>
          <div className={styles["title-text"]}>
            <h1>
              WhatsApp<span className="orange-yellow-color"> Trivia</span>
            </h1>
            <p>
              Weekly winners are announced in our WhatsApp group!
            </p>
          </div>
        </section>
        <iframe // Google Form iframe
          className={styles["trivia-iframe"]}
          src="https://docs.google.com/forms/d/e/1FAIpQLScITtGfo62dfhn-HfwQoFsCkoIaMBcALb3T8D1yeKWkPx8u9Q/viewform?embedded=true">
          Loading…
        </iframe>
      </div>
    </div>
  );
}

export default TriviaPage;
