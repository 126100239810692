import axios from "axios";

const qs = require("qs");

export const getAllTopics = async (options = {}) => {
  return await axios.get(`topics?${qs.stringify(options)}`);
};

export const getTopicPodcastsApi = async ({ slug }) => {
  return await axios.get(`topics/${slug}`);
};
