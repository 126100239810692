/* eslint-disable max-len */
// import styles from './Footer.module.scss';
import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  InputSelect,
  DatePickerInput,
} from "../../../components";
import styles from "../RegisterPage.module.scss";
import { Formik } from "formik";
import map from "lodash/map";
import { useSelector } from "react-redux";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { appSelector } from "@podcasts/store/application";
import { firstApplicationStepSchema } from "@podcasts/schemas";
import { countriesList, genderSelectOptions } from "@podcasts/constants";
import Modal from "react-modal";

const countries = map(countriesList, (v) => ({
  value: v.isoCode,
  label: v.name,
}));

export function FirstStep({ initialValues, onNext }: any) {
  const { error: registerErrorMessage } = useSelector(appSelector) as any;
  const [modalIsOpen, setIsOpen] = useState(false);
  const referralToken = useQueryParam("referral");
  useEffect(() => {
    if (registerErrorMessage && registerErrorMessage === "Email is already taken.") {
      setIsOpen(true);
    }
  }, [registerErrorMessage])

  return (
    <div className={`${styles["step"]}`}>
      <div className="row">
        <div className={`${styles["step-text"]} col-lg-6`}>
          <div className={styles["step-number"]}>
            <span>1</span>
          </div>
          <h2 className={`${styles["stepTitle"]}`}>
            Let's <span className="orange-yellow-color">get started</span>
          </h2>
          <p>
            All are welcome to apply to all parts of the Podcast Fellowship,
            however the $15 stipend per podcast comes from a private foundation
            which only pays a stipend for Jewish people to encourage them to
            understand Judaism better.
          </p>
        </div>
        <div className="col-lg-6">
          <Formik
            initialValues={initialValues}
            validationSchema={firstApplicationStepSchema()}
            onSubmit={(values, { setSubmitting }) => {
              onNext({ ...values, referral_token: referralToken });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }: /* and other goodies */
            any) => (
              <div className={`${styles["mobile-form"]}`}>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="First Name *"
                      name="fname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fname}
                      error={errors.fname && touched.fname ? "Required" : null}
                    />
                  </div>
                  <div className="col-lg">
                    <Input
                      label="Last Name *"
                      name="lname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lname}
                      error={errors.lname && touched.lname ? "Required" : null}
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="Email *"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={
                        errors.email && touched.email ? errors.email : null
                      }
                    />
                    {/* {registerErrorMessage && (
                      <div className={`${styles["errorMessage"]}`}>
                        {registerErrorMessage}
                        <Link to={"/login"}> Login</Link>
                      </div>
                    )} */}
                  </div>
                  <div className="col-lg">
                    <Input
                      label="Phone Number *"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      error={
                        errors.phone && touched.phone ? errors.phone : null
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <InputSelect
                      options={genderSelectOptions}
                      label="Gender *"
                      placeholder="Please select:"
                      name="gender"
                      onChange={({ value }: any) => {
                        setFieldValue("gender", value);
                      }}
                      onBlur={handleBlur}
                      value={values.gender}
                      error={
                        errors.gender && touched.gender ? "Required" : null
                      }
                    />
                  </div>
                  <div className="col-lg">
                    <DatePickerInput
                      label="Date of Birth *"
                      placeholder="mm/dd/yyyy"
                      name="dob"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.dob}
                      error={errors.dob && touched.dob ? errors.dob : null}
                    />
                  </div>
                </div>

                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <InputSelect
                      options={countries}
                      label="Country (Current Residence)"
                      name="country"
                      onChange={({ value }: any) => {
                        setFieldValue("country", value);
                      }}
                      onBlur={handleBlur}
                      value={values.country}
                      error={
                        errors.country && touched.country ? "Required" : null
                      }
                    />
                  </div>
                </div>

                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="Hometown (if different than current residence)"
                      name="city_residence"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city_residence}
                    />
                  </div>
                  <div className="col-lg">
                    <Input
                      label="Home state (if different than current residence)"
                      name="state_residence"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state_residence}
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg mt-0"></div>
                  <div className="col-lg mt-0">
                    <Button name="Next step" onClick={handleSubmit} />
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
        <Modal
          isOpen={modalIsOpen}
          closeTimeoutMS={200}
          style={{
            overlay: {
              zIndex: 99
            },
            content: {
              width: 600,
              height: 300,
              borderRadius: 20,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "20% auto",
              zIndex: 1
            }
          }}
        >
          <div><span className="icon-notice"/></div>
          <div>It looks like you've  applied before. If there's a reason why you are submitting another application, or if you are receiving this message in error, please reach out to <a href="mailto:leah@podcastfellowship.org">leah@podcastfellowship.org</a></div>
          <div style={{ width: 256, marginTop: 28 }}>
            <Button name="Ok" onClick={() => setIsOpen(false)} />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default FirstStep;
