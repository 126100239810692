import {useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import { userSelector } from "@podcasts/store/user";

export const useUserRole = () => {
    const { user } = useSelector(userSelector);
    const [isStudent, setIsStudent] = useState(false);

    useEffect(() => {
        if (user?.role === "Student") {
            setIsStudent(true);
        }
    },[user]);

    return { isStudent }
}