import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllNotesApi } from "@podcasts/api";
import { getPaymentsApi, getReferralsApi } from "@podcasts/api";

const initialState = {
  userPayments: { data: [] },
  resentNotes: [],
  savedPodcast: null,
  loadingUserPayments: null,
  loadingResentNotes: null,
  loadingSavedPodcast: null,
  error: null,
  refers: [],
};

export const getUserPayments = createAsyncThunk(
  "profile/getUserPayments",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await getPaymentsApi();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getResentNotes = createAsyncThunk(
  "profile/getResentNotes",
  async (options, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getAllNotesApi();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSavedPodcast = createAsyncThunk(
  "profile/getSavedPodcast",
  async (options, { rejectWithValue }) => {
    try {
      console.log("getSavedPodcast");
      return "data for getSavedPodcast";
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserReferrals = createAsyncThunk(
  "profile/getUserReferrals",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await getReferralsApi();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    addRefer(state, action) {
      state.refers.push(action.payload);
    },
    removeRefer(state, action) {
      state.refers.filter((refer) => refer.email != action.payload);
    },
  },
  extraReducers: {
    // getUserPayments
    [getUserPayments.pending]: (state) => {
      state.loadingUserPayments = true;
    },
    [getUserPayments.fulfilled]: (state, action) => {
      state.loadingUserPayments = false;
      state.userPayments = action.payload;
    },
    [getUserPayments.rejected]: (state, action) => {
      state.loadingUserPayments = false;
      state.error = "Get User Payments error";
    },
    // getResentNotes
    [getResentNotes.pending]: (state) => {
      state.loadingResentNotes = true;
    },
    [getResentNotes.fulfilled]: (state, action) => {
      state.loadingResentNotes = false;
      state.resentNotes = action.payload;
    },
    [getResentNotes.rejected]: (state, action) => {
      state.loadingResentNotes = false;
      state.error = "Get Resent Notes error";
    },
    // getSavedPodcast
    [getSavedPodcast.pending]: (state) => {
      state.loadingSavedPodcast = true;
    },
    [getSavedPodcast.fulfilled]: (state, action) => {
      state.loadingSavedPodcast = false;
      state.resentNotes = action.payload;
    },
    [getSavedPodcast.rejected]: (state, action) => {
      state.loadingSavedPodcast = false;
      state.error = "Get Resent Notes error";
    },
    // getUserReferrals
    [getUserReferrals.pending]: (state) => {
      state.loadingUserReferrals = true;
    },
    [getUserReferrals.fulfilled]: (state, action) => {
      state.loadingUserReferrals = false;
      state.userReferrals = action.payload;
    },
    [getUserReferrals.rejected]: (state, action) => {
      state.loadingUserReferrals = false;
      state.error = "Get User Referrals error";
    },
  },
});

export const { addRefer, removeRefer } = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
