import { Link } from "react-router-dom";
import { useRef } from "react";
import styles from "./ProfileRecentNotes.module.scss";
import PodcastNoteBlock from "./PodcastNoteBlock/PodcastNoteBlock";
import { Pagination } from "../../../../components";
import { useRecentNotesPage } from "@podcasts/hooks";

export function ProfileRecentNotes() {
  const { data: { data = [], last_page, current_page }, order, 
    onPageChange, toggleOrder } = useRecentNotesPage();

  const myRef: any = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth" })

  return (
    <div className={`${styles["profile-notes"]}`} ref={myRef}>
      <div className={`${styles["back-box"]}`}>
        <Link to={"/profile"}>
          <i className="icon-chevron-right"></i>
        </Link>
      </div>
      <div className={`${styles["profile-notes-top"]}`}>
        <h3>Recent Notes</h3>
        <Link
          to={"/podcasts/recent"}
          className="blue-icon-btn d-flex justify-content-center align-items-center"
        >
          <span>View all recently viewed</span>
          <i className="icon-clock"></i>
        </Link>
      </div>
      <div className={`${styles["profile-notes-wrapp"]}`}>
        <div className={`${styles["notes-header"]}`}>
          <button className={`${styles["filter-btn"]}`} onClick={toggleOrder}>
            Title&nbsp;
            <div className={`${order === "desc" ? styles["up"] : styles["down"]}`}>
              <i className="icon-chevron-sown"></i>
            </div>
          </button>
        </div>
        <div className={`${styles["notes-body"]}`}>
          {data.map((podcast: any) => (
            <PodcastNoteBlock
              key={podcast.id}
              podcast={podcast}
            />
          ))}
        </div>
      </div>
      <Pagination
        pages={last_page}
        page={current_page}
        onPageChange={(page: number) => {
          onPageChange(page)
          executeScroll();
        }}
      />
    </div>
  );
}

export default ProfileRecentNotes;
