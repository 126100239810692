import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPodcast,
  saveSpeaker,
  savePodcast,
  getSavedSpeakersIds,
  getSavedPodcastIds,
  getSavedCoursesIds,
  getSavePodcast,
  getSavedSpeakers,
  getSavedTopicsIds,
  getSavedTopics,
  addNote,
  youMayAlsoLike,
  updateNote,
  deleteNode,
  getPodcastNotes,
  sendNotesToMentor,
  getIntroCourses,
  getAllCourses,
  getSearch,
  getSavedCourses,
  saveCourse,
  saveTopic,
  getRequestPayOut,
  getTopicPodcasts,
  getSpeakerPodcasts,
  getRecentlyViewed,
  getSpeakers,
  getCourse,
  getTopics,
  requestPayout,
  resetState,
  clearSelectedPodcast,
} from "./actions";

const initialState = {
  featured_podcasts: [],
  featured_topic: null,
  featured_course: null,
  featured_speaker: null,
  topics: { data: [] },
  speakers: { data: [] },
  courses: { data: [] },
  series: [],
  search: [],
  introCourses: [],
  speaker: null,
  topic: null,
  podcast: null,
  course: null,
  notes: { data: [] },
  loading: null,
  payout_is_sent: false,
  requestedMoreThanAllowed: false,
  error: null,
  saved_podcasts: { data: [] },
  saved_speakers: { data: [] },
  saved_topics: [],
  saved_courses: [],
  saved_podcasts_ids: [],
  saved_speakers_ids: [],
  saved_topics_ids: [],
  saved_courses_ids: [],
  may_also_like: [],
  recently_viewed: { data: [] },
  payoutRequestSuccess: null,
  payoutRequestError: null,
  sendAllNotesSuccess: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetSpeakerPodcasts: (state) => {
      state.speakers = [];
    },
    resetSavedSpeakers: (state) => {
      state.saved_speaker = { data: [] };
    },
    resetSavedPodcasts: (state) => {
      state.saved_podcasts = { data: [] };
    },
    resetSavedTopic: (state) => {
      state.saved_topics = { data: [] };
    },
    resetNotes: (state) => {
      state.notes = { data: [] };
    },
    resetSavedCourses: (state) => {
      state.saved_courses = { data: [] };
    },
  },
  extraReducers: {
    [clearSelectedPodcast.fulfilled]: (state) => {
      state.podcast = {};
    },
    [youMayAlsoLike.pending]: (state) => {
      state.loading = true;
    },
    [youMayAlsoLike.fulfilled]: (state, action) => {
      state.loading = false;
      state.may_also_like = action.payload;
    },
    [youMayAlsoLike.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get featured error";
    },
    [getRequestPayOut.pending]: (state) => {
      state.loading = true;
      state.payout_is_sent = false;
      state.total_payout_request = 0;
      state.payoutRequestError = null;
    },
    [getRequestPayOut.fulfilled]: (state, action) => {
      state.loading = false;
      state.payout_is_sent = action.payload.is_sent;
      state.total_payout_request = action.payload.total_request;
      if (action.payload.is_sent) {
        state.payoutRequestError = "Already requested payment for this podcast.";
      }
    },
    [getRequestPayOut.rejected]: (state, action) => {
      state.loading = false;
      state.payout_is_sent = false;
      state.payoutRequestError = null;
      state.error = "Get featured error";
    },
    [getRecentlyViewed.pending]: (state) => {
      state.loading = true;
    },
    [getRecentlyViewed.fulfilled]: (state, action) => {
      state.loading = false;
      state.recently_viewed = action.payload;
    },
    [getRecentlyViewed.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get featured error";
    },
    // topics
    [getTopics.pending]: (state) => {
      state.loading = true;
    },
    [getTopics.fulfilled]: (state, action) => {
      state.loading = false;
      state.topics = action.payload;
    },
    // speakers
    [getSpeakers.pending]: (state) => {
      state.loading = true;
    },
    [getSpeakers.fulfilled]: (state, action) => {
      state.loading = false;
      state.speakers = action.payload;
    },
    [getSpeakers.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get speakers error";
    },
    // speaker podcasts
    [getSpeakerPodcasts.pending]: (state) => {
      state.loading = true;
    },
    [getSpeakerPodcasts.fulfilled]: (state, action) => {
      state.loading = false;
      state.speaker = action.payload;
    },
    [getSpeakerPodcasts.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get speakers error";
    },
    // topic podcasts
    [getTopicPodcasts.pending]: (state) => {
      state.loading = true;
    },
    [getTopicPodcasts.fulfilled]: (state, action) => {
      state.loading = false;
      state.topic = action.payload;
    },
    [getTopicPodcasts.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get speakers error";
    },
    // Search
    [getSearch.pending]: (state) => {
      state.loading = true;
    },
    [getSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.search = action.payload;
    },
    [getSearch.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get search error";
    },
    // IntroCourses
    [getIntroCourses.pending]: (state) => {
      state.loading = true;
    },
    [getIntroCourses.fulfilled]: (state, action) => {
      state.loading = false;
      state.introCourses = action.payload;
    },
    [getIntroCourses.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get IntroCourses error";
    },
    // notes
    [deleteNode.pending]: (state) => {
      state.loading = true;
    },
    [deleteNode.fulfilled]: (state, action) => {
      state.loading = false;
      state.notes.data = state.notes.data.filter(
        (n) => n.id !== action.payload.id
      );
    },
    [deleteNode.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get speakers error";
    },
    [getPodcastNotes.fulfilled]: (state, action) => {
      state.notes = { ...action.payload, ...state.notes.data };
    },
    [getPodcastNotes.rejected]: (state, action) => {
      state.error = "Get speakers error";
    },
    [sendNotesToMentor.pending]: (state) => {
      state.sendAllNotesSuccess = false;
    },
    [sendNotesToMentor.fulfilled]: (state, action) => {
      state.sendAllNotesSuccess = true;
      state.notes = action.payload;
    },
    [sendNotesToMentor.rejected]: (state, action) => {
      state.sendAllNotesSuccess = false;
      state.error = "Get speakers error";
    },
    // create note
    [addNote.pending]: (state) => {
      state.loading = true;
    },
    [addNote.fulfilled]: (state, action) => {
      state.loading = false;
      state.notes.data = [action.payload, ...state.notes.data];
    },
    [addNote.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get speakers error";
    },

    [updateNote.pending]: (state) => {
      state.loading = true;
    },
    [updateNote.fulfilled]: (state, action) => {
      state.loading = false;
      const index = state.notes.data.findIndex(
        (n) => n.id === action.payload.id
      );
      const notes = state.notes.data;
      notes[index] = action.payload;
      state.notes.data = notes;
    },
    [updateNote.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get speakers error";
    },
    [savePodcast.pending]: (state) => {
      state.loading = true;
    },
    [savePodcast.fulfilled]: (state, action) => {
      state.loading = false;
      state.saved_podcasts_ids = action.payload;
    },
    [savePodcast.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get SavePodcast error";
    },
    [getSavedPodcastIds.pending]: (state) => {
      state.loading = true;
    },
    // getSavePodcast
    [getSavePodcast.pending]: (state) => {
      state.loading = true;
    },
    [getSavePodcast.fulfilled]: (state, action) => {
      state.loading = false;
      state.saved_podcasts = action.payload;
    },
    [getSavePodcast.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get getSavePodcast error";
    },
    [getSavedCourses.fulfilled]: (state, action) => {
      state.saved_courses = action.payload;
    },
    [getSavedCourses.rejected]: (state, action) => {
      state.error = "Get getSavePodcast error";
    },
    [getSavedTopics.fulfilled]: (state, action) => {
      state.saved_topics = action.payload;
    },
    [getSavedTopics.rejected]: (state, action) => {
      state.error = "Get getSavePodcast error";
    },
    // getSaveSpeakers
    [getSavedSpeakers.fulfilled]: (state, action) => {
      state.saved_speakers = action.payload;
    },
    [getSavedSpeakers.rejected]: (state, action) => {
      state.error = "Get getSavePodcast error";
    },
    [getSavedPodcastIds.fulfilled]: (state, action) => {
      state.saved_podcasts_ids = action.payload;
    },
    [getSavedPodcastIds.rejected]: (state, action) => {
      state.error = "Get getSavePodcast ids error";
    },
    [getSavedCoursesIds.fulfilled]: (state, action) => {
      state.saved_courses_ids = action.payload;
    },
    [getSavedCoursesIds.rejected]: (state, action) => {
      state.error = "Get getSavePodcast ids error";
    },
    [getSavedSpeakersIds.fulfilled]: (state, action) => {
      state.saved_speakers_ids = action.payload;
    },
    [getSavedSpeakersIds.rejected]: (state, action) => {
      state.error = "Get getSavePodcast ids error";
    },
    [getSavedTopicsIds.fulfilled]: (state, action) => {
      state.saved_topics_ids = action.payload;
    },
    [getSavedTopicsIds.rejected]: (state, action) => {
      state.error = "Get getSavePodcast ids error";
    },

    [saveSpeaker.pending]: (state) => {
      state.loading = true;
    },
    [saveSpeaker.fulfilled]: (state, action) => {
      state.loading = false;
      state.saved_speakers_ids = action.payload;
    },
    [saveSpeaker.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get SavePodcast error";
    },

    [saveTopic.pending]: (state) => {
      state.loading = true;
    },
    [saveTopic.fulfilled]: (state, action) => {
      state.loading = false;
      state.saved_topics_ids = action.payload;
    },
    [saveTopic.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get SavePodcast error";
    },
    [saveCourse.pending]: (state) => {
      state.loading = true;
    },
    [saveCourse.fulfilled]: (state, action) => {
      state.loading = false;
      state.saved_courses_ids = action.payload;
    },
    [saveCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get SavePodcast error";
    },

    [getPodcast.pending]: (state) => {
      state.loading = true;
    },
    [getPodcast.fulfilled]: (state, action) => {
      state.loading = false;
      state.podcast = action.payload;
    },
    [getPodcast.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get SavePodcast error";
    },
    [getAllCourses.pending]: (state) => {
      state.loading = true;
    },
    [getAllCourses.fulfilled]: (state, action) => {
      state.loading = false;
      state.courses = action.payload;
    },
    [getAllCourses.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get SavePodcast error";
    },
    // Serias
    [getCourse.pending]: (state) => {
      state.loading = true;
    },
    [getCourse.fulfilled]: (state, action) => {
      state.loading = false;
      state.course = action.payload;
    },
    [getCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Get series error";
    },
    [requestPayout.pending]: (state, action) => {
      state.payoutRequestSuccess = null;
      state.payoutRequestError = null;
    },
    [requestPayout.fulfilled]: (state, action) => {
      state.payoutRequestSuccess = "Your payment request has been submitted! We will get back to you as soon as we can.";
      state.payoutRequestError = null;
      state.requestedMoreThanAllowed = false;
    },
    [requestPayout.rejected]: (state, action) => {
      state.payoutRequestSuccess = null;
      state.payoutRequestError = action.payload.message;
      state.requestedMoreThanAllowed = action.payload.type && action.payload.type === 'requested_more_than_allowed';
    },
    [resetState.fulfilled]: (state, action) => {
      state.payoutRequestSuccess = null;
    },
  },
});

export const {
  resetSpeakerPodcasts,
  resetSavedSpeakers,
  resetSavedPodcasts,
  resetSavedCourses,
  resetNotes,
  resetSavedTopic,
} = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
