import { useCallback, useState } from 'react';
import { useSearch } from '@podcasts/hooks';
import PaginatedPodcastsTable from './PaginatedPodcastsTable';
import { useSelector } from "react-redux";
import { searchSelector } from "@podcasts/store/search"
import { orderValues } from '@podcasts/constants';

const SearchablePodcastTable = ({ params = {}}) => {
    const { ASC, DESC } = orderValues;
    const [order_by, setOrder_by] = useState("title");
    const [order, setOrder] = useState(ASC);
    const [page,setPage] = useState(1)
    const { level } = useSelector(searchSelector) as any;

    const {
        data: { data, last_page, current_page  },
        fetchNextPage,
        isFetchingPreviousPage,
        isFetchingNextPage,
        isFetching,
        isRefetching } = useSearch({ ...params, page, order, order_by, level });

    const handleSortChange = (e: any) => {
        let sortBy = e.target.innerText.toLowerCase();
        if (sortBy === order_by) {
          let sortOrder = order === ASC ? DESC : ASC;
          setOrder(sortOrder);
        } else {
          setOrder_by(sortBy);
          setOrder(ASC);
        }
      }

      const onPageChange = useCallback((p) => {
        setPage(p)
       },[])
      
      return (<PaginatedPodcastsTable
        items={data}
        handleSortChange={handleSortChange}
        order_by={order_by}
        order={order}
        pages={last_page}
        page={current_page}
        onPageChange={(page: number) => {
          onPageChange(page)
        //   executeScroll();
        }}/>)
}

export default SearchablePodcastTable;