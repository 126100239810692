import axios from "axios";
import qs from "qs";

export const userAuth = async (body) => {
  return axios.post(`login`, body).then((d) => d.data);
};

export const applyFirstStep = async (body) => {
  return await axios.post(`application/signup`, body);
};

export const applySecondStep = async (body, token) => {
  return await axios.put(`application/beliefs`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const applytThirdStep = async (body, token) => {
  return await axios.put(`application/you`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const savePodcastApi = async (podcastId) => {
  return await axios.post(`library/podcasts/${podcastId}`);
};

export const getSavedPodcastIdsApi = async () => {
  return await axios.get(`library/ids/podcast`);
};
export const updateStudentProfileApi = async (body) => {
  return await axios.put(`students`, body);
};

export const updateStudentImageApi = async (image) => {
  const formData = new FormData();

  formData.append("image", image);

  return await axios.post(`students/image`, formData);
};

export const saveSpeakersApi = async (podcastId) => {
  return await axios.post(`library/speakers/${podcastId}`);
};

export const getSavedSpeakersIdsApi = async () => {
  return await axios.get(`library/ids/speaker`);
};

export const getPaymentsApi = async (options = {}) => {
  return await axios.get(`payouts?${qs.stringify(options)}`);
};

export const getIsForgotPasswordVerified = async (token) => {
  return await axios.get(`auth/forgot-password/verify-token/${token}`);
};

export const referFriendsApi = async (friends) => {
  return await axios.post(`refer`, friends);
};

export const requestPayOutApi = async (payouts) => {
  return await axios.post(`payouts`, { ...payouts, is_web: true });
};

export const getRequestPayOutApi = async (podcastId) => {
  return await axios.get(`payouts/${podcastId}`);
};

export const sendForgetPasswordEmail = async (body) => {
  return await axios.put("auth/forgot-password", body);
};

export const changeUserPassword = async (body) => {
  return await axios.put("auth/change-password", body);
};

export const getIncompleteUserData = async (options) => {
  return await axios.get(
    `students/application-process?${qs.stringify(options)}`
  );
};

export const sendConfirmationEmailToStudent = async (token) => {
  return await axios.post(
    `students/send-confirmation-email`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const sendContactUsForm = async (body) => {
  return await axios.post("contact-us", body);
};

export const deleteProfile = async () => {
  return await axios.delete(`students/delete-account`);
};

export const getReferralsApi = async (options = {}) => {
  return await axios.get(`payouts/referrals?${qs.stringify(options)}`);
};