import styles from "./SavedPodcastsPage.module.scss";
import {  useRef } from "react";
import { useSavedPodcastsAppPage } from '@podcasts/hooks';
import PaginatedPodcastsTable from "../../components/PodcastsTable/PaginatedPodcastsTable";

export function SavedPodcastsPage() {
  const myRef: any = useRef(null);
  const {
    data: { data = [], last_page, current_page },
    order, 
    order_by,
    setOrder,
    setOrderBy,
    onPageChange
  } = useSavedPodcastsAppPage({ per_page: 12, defaultOrder: 'asc', defaultOrderBy: 'title' });

  console.log('====')
  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth"});
  
  const handleSortChange = (e: any) => {
    let sortBy = e.target.innerText.toLowerCase();
    if (sortBy === order_by) {
      let sortOrder = order === "asc" ? "desc" : "asc";
      setOrder(sortOrder);
    } else {
      setOrderBy(sortBy);
      setOrder("asc");
    }
  }

  return (
    <div ref={myRef} className={`${styles["container"]}`}>
      <div className={`${styles["title-box"]} d-flex justify-content-between align-items-center`}>
        <h2>Saved Podcasts</h2>
      </div>
      <PaginatedPodcastsTable 
      items={data}
      handleSortChange={handleSortChange} 
      order_by={order_by} 
      order={order} 
      pages={last_page}
      page={current_page}
      pageName={"saved"}
      onPageChange={(page: number) => {
        onPageChange(page)
        executeScroll();
      }}
      />
    </div>
  );
}

export default SavedPodcastsPage;
