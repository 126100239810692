import React from "react";
import styles from "./SliderItem.module.scss";
import sliderBg from "../../../../assets/images/slider-home-bg.svg";

export function SliderItem({ image, name, role, description }: any) {
  return (
    <div>
      <div className={styles["image-wrap"]}>
        <img src={image} />
        <img
          src={sliderBg}
          className={styles["img-bg"]}
          alt=""
        />
      </div>
      <p className={styles["title"]}>{name}</p>
      <p className={styles["description"]}>{role}</p>
      <p className={styles["review-text"]}>{description}</p>
    </div>
  );
}
