// import styles from './Footer.module.scss';
import React from "react";
import { Button, Input, RadioButton, TextArea } from "../../../components";
import styles from "../RegisterPage.module.scss";
import { Formik } from "formik";
import { secondApplicationStepSchema } from "@podcasts/schemas";

export function SecondStep({ initialValues, onNext, onPrev }: any) {
  const { jewish_education } = initialValues;
  return (
    <div className={styles["step"]}>
      <div className="row">
        <div className="col-lg-6">
          <div className={styles["step-number"]}>
            <span>2</span>
          </div>
          <h2 className={`${styles["stepTitle"]}`}>
            Tell us about your<br></br>
            <span className="orange-yellow-color">religious beliefs</span>
          </h2>
        </div>
        <div className="col-lg-6">
          <Formik
            initialValues={initialValues}
            validationSchema={secondApplicationStepSchema}
            onSubmit={(values, { setSubmitting }) => {
              onNext(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }: /* and other goodies */
            any) => (
              <div className={`${styles["mobile-form"]}`}>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="What religion were you born into? *"
                      name="religion_born"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.religion_born}
                      error={
                        errors.religion_born && touched.religion_born
                          ? "Required"
                          : null
                      }
                    />
                  </div>
                  <div className="col-lg">
                    <Input
                      label="What religion do you identify with today? *"
                      name="religion_identify_with"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.religion_identify_with}
                      error={
                        errors.religion_identify_with &&
                        touched.religion_identify_with
                          ? "Required"
                          : null
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <Input
                      label="What religion was your father born into? *"
                      name="father_religion"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.father_religion}
                      error={
                        errors.father_religion && touched.father_religion
                          ? "Required"
                          : null
                      }
                    />
                  </div>
                  <div className="col-lg">
                    <Input
                      label="What religion was your mother born into? *"
                      name="mother_religion"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mother_religion}
                      error={
                        errors.mother_religion && touched.mother_religion
                          ? "Required"
                          : null
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <div className="col-lg">
                    <TextArea
                      rows={3}
                      label="In 1-2 sentences share your Jewish story. *"
                      name="jewish_story"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.jewish_story}
                      error={
                        errors.jewish_story && touched.jewish_story
                          ? "Required"
                          : null
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["formRow"]} row`}>
                  <label
                    className="col-12"
                    style={{
                      fontWeight: 300,
                      fontSize: "13px",
                      lineHeight: "23PX",
                    }}
                  >
                    Have you ever had any formal Jewish education? *
                  </label>
                  <div className="col-3 mt-0">
                    <RadioButton
                      name="jewish_education"
                      value={true}
                      checked={Boolean(values.jewish_education) === true}
                      label="Yes"
                      onChange={(value: any) => {
                        setFieldValue("jewish_education", true);
                      }}
                      onBlur={handleBlur}
                      error={null}
                    />
                  </div>
                  <div className="col-3 mt-0">
                    <RadioButton
                      name="jewish_education"
                      value={false}
                      checked={Boolean(values.jewish_education) === false}
                      label="No"
                      onChange={(value: any) => {
                        setFieldValue("jewish_education", false);
                      }}
                      onBlur={handleBlur}
                      error={null}
                    />
                  </div>
                </div>
                {values.jewish_education ? (
                  <div className={`${styles["formRow"]} row`}>
                    <div className="col-sm">
                      <TextArea
                        rows={7}
                        label="If so, please describe:"
                        name="jewish_education_description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.jewish_education_description}
                        error={
                          errors.jewish_education_description &&
                          touched.jewish_education_description
                            ? "Required"
                            : null
                        }
                      />
                    </div>
                  </div>
                ) : null}
                <div className={`${styles["formRow"]} row`}>
                  <div
                    className={`${styles["prev-step"]} d-flex align-items-center col-lg`}
                  >
                    <span onClick={onPrev}>Previous step</span>
                  </div>
                  <div className="col-lg mt-0">
                    <Button name="Next step" onClick={handleSubmit} />
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SecondStep;
