import React from "react";
import { Button } from "../../../../components/Button/Button";
import styles from "./ThankYou.module.scss";
import { Link, useNavigate } from "react-router-dom";

export function ThankYou() {
  const navigate = useNavigate();

  return (
    <div className={`${styles["login-wrapp"]}`}>
      <div className={`${styles["login-decor"]}`}>
        <div></div>
      </div>
      <div
        className={`${styles["pass-success-box"]} flex-column d-flex align-items-center`}
      >
        <div className={`${styles["pass-success-ico"]}`}>
          <i className="icon-done"></i>
        </div>
        <h2 className={styles["header"]}>
          <span className="orange-yellow-color">Thank you</span> for your
          application
        </h2>
        <ul className={`${styles["social-list"]} d-flex`}>
          <li>
            <Link className={` ${styles["link"]}`} to="/link">
              {" "}
              <i className="icon-facebook"></i>
            </Link>
          </li>
          <li>
            <Link className={` ${styles["link"]}`} to="/link">
              <i className="icon-instagram"></i>
            </Link>
          </li>
        </ul>
      </div>
      <div className={`${styles["login-decor"]}`}>
        <div></div>
      </div>
    </div>
  );
}

export default ThankYou;
