import { dashboardSelector } from "@podcasts/store/dashboard"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FeaturedItem from "../../FeaturedPage/components/FeaturedItem/FeaturedItem";
import SocialSharing from "./SocialSharing/SocialSharing";
import styles from "./PodcastDetails.module.scss";
import { UserLevel } from "../../../../../components/UserLevel/UserLevel";
import {
  youMayAlsoLike,
  savePodcast,
  getRequestPayOut
} from"@podcasts/store/dashboard";
import { Iframe } from "../../../../../components/Iframe/Iframe";
import { usePodcastWebPage, useRequestPayment } from "@podcasts/hooks";
import { showToastMessage } from "../../../../../utils/common";

export function PodcastDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: podcast, isSavedPodcast, togglePodcastLibrary, isAuthenticated } = usePodcastWebPage(id);
  const { canRequestPayment } = useRequestPayment();
  const { may_also_like, payoutRequestError } = useSelector(dashboardSelector) as any;

  useEffect(() => {
    dispatch(youMayAlsoLike(id));
    if (isAuthenticated) {
       dispatch(getRequestPayOut(id));
    }
  }, [id]);

  const onSavePodcast = () => {
    dispatch(savePodcast(podcast));
  };

  const handleRequestPayment = (e) => {
    e.preventDefault();

    if (podcast.is_payout_request_sent) {
      showToastMessage(payoutRequestError, "error");
    } else {
      navigate(`${location.pathname}/payment`);
    }
  }

  return (
    <div className={styles["podcast"]}>
      <div
        className={`${styles["podcast-actions"]} d-flex align-items-center justify-content-between`}
      >
        <div className="d-flex align-items-center">
          <UserLevel level={podcast?.level || "--"} />
          {
            isAuthenticated && (
              <button className="normalize-btn" onClick={onSavePodcast}>
                <i
                  className={
                    isSavedPodcast(podcast?.id) ? "icon-full-star" : "icon-empty-star"
                  }
                ></i>
              </button>
            )
          }
        </div>
        {isAuthenticated && canRequestPayment && podcast && !podcast?.is_payout_request_sent ? <Link
          onClick={(e) => handleRequestPayment(e)}
          to={"payment"}
          className="d-flex justify-content-center align-items-center"
        >
          <span>Request a payment</span>
          <i className="icon-payment-empty"></i>
        </Link> : null}
      </div>
      {podcast ? (
        <div className={`${styles["podcast-body"]} d-flex`}>
          <div className={styles["podcast-body-info"]}>
            <p className={styles["category"]}>
              {podcast.topics.map((t: any, i: number) => (
                <Link to={`../topics/${t.slug}`} key={i}>
                  <span>{t.title} </span>
                </Link>
              ))}
              {podcast.courses.map((course: any, i: number) => (
                <Link to={`../courses/${course.slug}`} key={i}>
                  <span>{course.title} </span>
                </Link>
              ))}
            </p>
            <p className={styles["podcast-name"]}>{podcast.title}</p>
            { podcast.speaker ? (<div className={`${styles["author"]} d-flex align-items-center`}>
              <div className={`${styles["author-avatar"]}`}>
                <img src={podcast.speaker.image} alt={`${podcast.speaker.title} ${podcast.speaker.fname} ${podcast.speaker.lname}` } />
              </div>
              <p>
                <Link to={`../speakers/${podcast.speaker.slug}`}>
                  {podcast.speaker.title} {podcast.speaker.fname} {podcast.speaker.lname}{" "}
                </Link>
              </p>
            </div>) : null}
          </div>
          <div className={styles["player"]} >
            { <Iframe iframe={podcast.embed_code} allow="autoplay" type={podcast.type}/> }
          </div>
        </div>
      ) : null}
      <div className={styles["recomended-podcast"]}>
        <p>You might also like:</p>
        <div className={`${styles["featured-items"]}`}>
          {may_also_like.map((item: any, i: number) => (
            <FeaturedItem
              key={i}
              onSave={() => {
                togglePodcastLibrary(item)
              }}
              saved={isSavedPodcast(item?.id)}
              {...item}
            />
          ))}
        </div>
      </div>
      <SocialSharing podcast={podcast} />
    </div>
  );
}

export default PodcastDetails;
