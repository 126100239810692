import styles from "./Notes.module.scss";
import  { useState, useEffect } from "react";
import { dashboardSelector } from "@podcasts/store/dashboard";
import { userSelector } from "@podcasts/store/user";
import { useDispatch, useSelector } from "react-redux";
import {
  getPodcastNotes,
  addNote,
  deleteNode,
  sendNotesToMentor,
  updateNote,
} from "@podcasts/store/dashboard";
import { MyEditor } from "../MyEditor/MyEditor";
import { NoteItem } from "../NoteItem/NoteItem";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { size } from "lodash";
import { usePrevious } from "../../../../../../hooks/usePrevious";
import { showToastMessage } from "../../../../../../utils/common";

export function Notes({ componentTitle }: any) {
  const contentHeight = window.innerHeight - 300 + "px";
  const { id, slug } = useParams();
  
  const { notes: { data = [] }, sendAllNotesSuccess } = useSelector(dashboardSelector) as any;
  const { user } = useSelector(userSelector) as any;
  const prevSendAllNotesSuccess = usePrevious(sendAllNotesSuccess);
  const [note, setNote] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPodcastNotes({ slug: id }));
  }, [id]);

  useEffect(() => {
    if (sendAllNotesSuccess && sendAllNotesSuccess !== prevSendAllNotesSuccess) {
      showToastMessage("Notes are sent successfully");
    }
  }, [sendAllNotesSuccess]);

  const [scrollDown, setScrollDown] = useState({ height: contentHeight });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScrollDown({ height: contentHeight });
  }

  const isDisabled = (str: string) => size(str.replace(/<[^>]*>?/gm, "")) === 0;

  const send = () => {
    dispatch(addNote({ id, note })).then(
      () => {
        setNote("");
        toast.success(`Note was added`, {
          position: toast.POSITION.TOP_RIGHT,
          className: `foo-bar ${styles["add-note-success"]}`,
          theme: "colored",
        });
      },
      (e: any) => {
        toast.warn(`Something went wrong`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
          theme: "colored",
        });
      }
    );
  };

  const deleteNoteItem = (id: number) => {
    dispatch(deleteNode(id)).then(
      () => {
        setNote("");
        toast.success(`Note was deleted`, {
          position: toast.POSITION.TOP_RIGHT,
          className: `foo-bar ${styles["delete-note-success"]}`,
          theme: "colored",
        });
      },
      (e: any) => {
        toast.warn(`Something went wrong`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
          theme: "colored",
        });
      }
    );
  };

  const onUpdate = (id: number, note: string) => {
    dispatch(updateNote({ id, note }));
  };


  return (
    <div className={`${styles["notes"]} right-sidebar-component`}>
      { user.role === "Student" ? (<div
        className={`${styles["header"]} d-flex justify-content-between align-items-center`}
      >
        <p className={styles["title"]}>{componentTitle}</p>
        <button
          onClick={() => {
            dispatch(sendNotesToMentor(id));
          }}
          className="normalize-btn"
        >
          Send notes to mentor
        </button>
      </div>) : null}
      
      <div className={styles["notes-wrapp"]} style={scrollDown}>
        {data.map((n: any, i: number) => (
          <NoteItem
            key={i}
            {...n}
            onUpdate={(note: string) => {
              onUpdate(n.id, note);
            }}
            onDelete={() => {
              deleteNoteItem(n.id);
            }}
          ></NoteItem>
        ))}
      </div>


      <section className={`${styles["create-note"]} create-note`}>
        <MyEditor value={note} onChange={(v: any) => setNote(v)} />
        <button
          className={`${styles["save-button"]}`}
          disabled={isDisabled(note)}
          onClick={send}
        >
          Save
        </button>
      </section>
    </div>
  );
}

export default Notes;
