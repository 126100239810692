import styles from "./SliderFirst.module.scss";
import { useNavigate } from "react-router-dom";

const img =
  "https://www.kettler.co.uk/cms2/wp-content/uploads/2020/01/panalsol-elba-corner-side-table-lifestyle-2019_1.jpg";

export function SliderFirstItem({ image, title, description, slug }: any) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/podcasts/topics/${slug}`)
  }

  return (
    <div onClick={handleClick} className={`cursor-pointer ${styles["wrap-item"]}`}>
      <div className={`${styles["wrap-item-img"]}`}>
        <div>
          <img src={image} alt="" />
          <svg width="0" height="0">
            <defs>
              <clipPath id="sliderFirst">
                <path d="M265.933 170.647C263.7 182.453 253.384 191 241.369 191L25 191C11.1929 191 2.85606e-05 179.807 2.61464e-05 166L1.51917e-06 25.1486C-1.03675e-06 10.5304 12.4879 -0.969204 27.0565 0.233277L266.332 19.9829C281.122 21.2036 291.598 34.9635 288.84 49.5447L265.933 170.647Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className={`${styles["wrap-item-text"]}`}>
        <p className={`${styles["item-title"]}`}>{title}</p>
        <p className={`${styles["item-description"]}`}>{description}</p>
      </div>
    </div>
  );
}
