
export * from './countries'
export * from './text'

export const orderValues = {
  ASC: "asc",
  DESC: "desc"
}

export const levelLabels = {
  BEGINNER: "Beginner",
  INTERMEDIATE: "Intermediate",
  ADVANCED: "Advanced",
  ALL: "All"
}

export const levelValues = {
  BEGINNER: "beginner",
  INTERMEDIATE: "intermediate",
  ADVANCED: "advanced",
  All: null
}

export const levelOptions = [
    { value: levelValues.BEGINNER, label: levelLabels.BEGINNER },
    { value: levelValues.INTERMEDIATE, label:levelLabels.INTERMEDIATE },
    { value:levelValues.ADVANCED, label:levelLabels.ADVANCED},
    { value:levelValues.All, label:levelLabels.ALL},
  ];


export const genderSelectOptions = [
  {
    label: "Male",
    value: "m",
  },
  {
    label: "Female",
    value: "f",
  },
];

// export const referralMethods = [
//     { value: "facebook", label: "Facebook" },
//     { value: "instagram", label: "Instagram" },
//     { value: "twitter", label: "Twitter" },
//     { value: "friend", label: "Friend"},
//     { value: "other" , label: "Other" }
// ];

export const referralMethods = [
  { value: "educator", label: "Educator" },
  { value: "friend", label: "Friend" },
  { value: "advertisement", label: "Advertisement" },
  { value: "direct message on instagram", label: "Direct message on Instagram" },
  { value: "jewish trivia watch and win", label: "Jewish Trivia Watch and Win" },
  { value: "other", label: "Other" },
]