import { Button } from "../../../../../../components";
import { Link } from "react-router-dom";
import styles from "./AuthComponent.module.scss";

export function AuthComponent() {
  return (
    <div className={`${styles["auth-box"]}`}>
      <div className={`${styles["auth-box-icon"]}`}>
        <div>
          <i className="icon-lock"></i>
        </div>
      </div>
      <div className={`${styles["auth-box-text"]}`}>
        Apply now to <span>earn $15</span> for each class you listen to and
        discuss with one person.
      </div>
      <Link to="/register">
        <Button name="Apply now" onClick={() => {}} icon="icon-door-hole" />
      </Link>
      <div className={`${styles["login"]}`}>
        <span>Already have an account? </span>
        <Link to={"/login"}>Login</Link>
      </div>
    </div>
  );
}

export default AuthComponent;
