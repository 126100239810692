import React, { useState, useEffect } from "react";
import styles from "./ResetPasswordPage.module.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "../../components";
import { changePassword, isForgotPasswordVerified } from "@podcasts/store/auth";
import { useNavigate } from "react-router-dom";
import { authSelector } from "@podcasts/store/auth";
import { usePrevious } from "../../hooks/usePrevious";
import { showToastMessage } from "../../utils/common";
import { useQueryParam } from "../../hooks/useQueryParam";

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be a minimum of 8 characters")
    .required("Required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please retype your password"),
});

export function ResetPasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { successMessage } = useSelector(authSelector) as any;
  const prevSuccessMessage = usePrevious(successMessage);
  const token = useQueryParam("token");
  const [isTokenVerified, setIsTokenVerified] = useState(null);

  useEffect(() => {
    const checkVerification = async () => {
      if (token) {
        const response = await dispatch(isForgotPasswordVerified(token));
        setIsTokenVerified(response.payload.success);
      }
    };

    checkVerification();
  }, [dispatch, token]);

  useEffect(() => {
    if (successMessage && successMessage !== prevSuccessMessage) {
      showToastMessage("Password set successfully.");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  }, [successMessage]);

  const handleChangePassword = ({ password, confirm_password }) => {
    dispatch(
      changePassword({
        password,
        password_confirmation: confirm_password,
        token,
      })
    );
  };

  return (
    <div>
      {isTokenVerified === true && (
        <div className={`${styles["login-wrapp"]}`}>
          <div className={`${styles["login-decor"]}`}>
            <div></div>
          </div>
          <div className={`${styles["form"]} }`}>
            <div className="flex-column d-flex align-items-center">
              <h2 className={styles["header"]}>Set your password:</h2>
              <p className={styles["header-subtitle"]}>
                Fill out the form below to create your password.
              </p>
              <Formik
                initialValues={{
                  password: "",
                  confirm_password: "",
                }}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleChangePassword(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <>
                    <div className={`${styles["form-row"]}`}>
                      <Input
                        label="Password *"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        error={
                          errors.password && touched.password
                            ? errors.password
                            : null
                        }
                      />
                    </div>
                    <div className={`${styles["form-row"]}`}>
                      <Input
                        label="Confirm Password *"
                        name="confirm_password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirm_password}
                        error={
                          errors.confirm_password && touched.confirm_password
                            ? errors.confirm_password
                            : null
                        }
                      />
                    </div>
                    <div className={`${styles["button-row"]}`}>
                      <Button name="Submit" onClick={handleSubmit} />
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
          <div className={`${styles["login-decor"]}`}>
            <div></div>
          </div>
        </div>
      )}
      {isTokenVerified === false && (
        <div>
          <p>Password reset token is invalid.</p>
          {/* Render additional content for an invalid token */}
        </div>
      )}
    </div>
  );
}
export default ResetPasswordPage;
