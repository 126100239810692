import { userSelector } from "@podcasts/store/user"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const UnauthorizedAction = ({ children, onClick }: any) => {
  const { user } = useSelector(userSelector) as any;
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        if (user) {
          onClick();
        } else {
          toast.warn(`You need to be logged in to do this action`, {
            position: toast.POSITION.BOTTOM_CENTER,
            className: "foo-bar",
            theme: "colored",
          });
          navigate("/login");
        }
      }}
    >
      {children}
    </div>
  );
};
