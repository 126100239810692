// import styles from './Footer.module.scss';
import classNames from "classnames";
import React, { useState, forwardRef } from "react";
import styles from "../../assets/scss/global.module.scss";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";

interface InputProps {
  label?: string;
  placeholder?: string;
  type?: string;
  value?: any;
  onChange?: any;
  onBlur?: any;
  error?: string | null;
  name?: string;
}

export function DatePickerInput({
  name = "",
  label = "",
  placeholder = "",
  type = "text",
  value = null,
  onChange,
  onBlur,
  error,
}: any) {
  const [startDate, setStartDate] = useState(value);
  const classNamesForInput = classNames(
    styles["inputContainer"],
    error ? styles["inputError"] : ""
  );

  const ReactInputMask = InputMask as any;

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, onChange, onBlur }: any, ref: any) => (
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onClick={onClick}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
      />
    )
  );
  return (
    <div>
      <div className={classNamesForInput}>
        <label>{label}</label>
        <ReactInputMask
          mask="99/99/9999"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
        ></ReactInputMask>
        {/* <DatePicker selected={startDate}   customInput={<ExampleCustomInput value={value} />}
        onChange={(date: any)=> {
          console.log(date)
          setStartDate(date);
          onChange(date);
        }}
  /> */}
        {error && <span className={styles["errorMessage"]}>{error}</span>}
      </div>
    </div>
  );
}

export default DatePickerInput;
