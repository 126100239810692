import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import styles from "./MyEditor.module.scss";
const ReactQuill = require("react-quill");

export function MyEditor({ onChange, value }: any) {
  const modules = {
    toolbar: [
      [{ list: "bullet" }, { list: "ordered" }],
      ["bold", "italic", "underline"],
      //[{ size: [] }],
    ],
  };

  const formats = ["bold", "italic", "underline", "list", "bullet", "1px"];

  return (
    <div className={styles["my-editor"]}>
      <ReactQuill
        value={value}
        theme="snow"
        placeholder="Write down your thoughts..."
        modules={modules}
        formats={formats}
        onChange={onChange}
      />
    </div>
  );
}

export default MyEditor;
