// import styles from './Footer.module.scss';
import React from "react";
import mainS from "../../../assets/scss/global.module.scss";
import styles from "./RadioButton.module.scss";

interface InputProps {
  label?: string;
  name: string;
  value: any;
  type?: string;
  onChange?: any;
  onBlur?: any;
  error?: string | null;
  checked?: boolean;
}

export function RadioButton({
  label,
  name,
  checked,
  value,
  onChange,
  onBlur,
  error,
}: InputProps) {
  return (
    <div className={styles["inputContainer"]}>
      <label>{label}</label>
      <div className={`${styles["customRadio"]} ${styles["mainS"]}`}>
        <input
          type="radio"
          defaultChecked={checked}
          value={value}
          name={name}
          onChange={onChange}
        />
        <span></span>
      </div>
    </div>
  );
}

export default RadioButton;
