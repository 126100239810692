import { Routes, Route } from "react-router-dom";
import SeriesListPage from "./inners/SeriesListPage/SeriesListPage";
import SeriesDetailsPage from "./inners/SeriesDetailsPage/SeriesDetailsPage";
export function SeriesPage() {
  return (
    <Routes>
      <Route path={`/`} element={<SeriesListPage />} />
      <Route path={`/:slug`} element={<SeriesDetailsPage />} />
    </Routes>
  );
}

export default SeriesPage;
