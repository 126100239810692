import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  changeUserPassword,
  sendForgetPasswordEmail,
  getIsForgotPasswordVerified,
} from "@podcasts/api/user";

const initialState = {
  loading: false,
  error: "",
  successMessage: null,
};

export const forgetPassword = createAsyncThunk(
  "user/forgetPassword",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await sendForgetPasswordEmail(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await changeUserPassword(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const isForgotPasswordVerified = createAsyncThunk(
  "user/getIsForgotPasswordVerified ",
  async (options, { rejectWithValue, getState }) => {
    try {
      const { data } = await getIsForgotPasswordVerified(options);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [forgetPassword.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.successMessage = null;
    },
    [forgetPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.successMessage = action.payload.message;
    },
    [forgetPassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.successMessage = null;
    },
    [changePassword.pending]: (state) => {
      state.loading = true;
      state.successMessage = null;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.successMessage = action.payload.message;
    },
    [changePassword.rejected]: (state, action) => {
      state.loading = false;
      state.successMessage = null;
    },
  },
});

export const authReducer = userSlice.reducer;
