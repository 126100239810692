import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import pageTabTitles from "../../utils/pageTabTitles";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    console.log(pathname);
    // create browser tab titles every time a new page renders
    const pageTitle = pageTabTitles(pathname);
    document.title = pageTitle + `${pageTitle !== "" ? " - " : ""} The Podcast Fellowship`;

    /* settimeout make sure this run after components have rendered. This will help fixing bug for some views where scroll to top not working perfectly */
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, [pathname]);
  return null;
};

export default ScrollToTop;
