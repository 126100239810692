import { Button, Input } from "../../../../../components";
import styles from "../ReferFriendForm.module.scss";
import { FieldArray, Form, Formik } from "formik";
import { referFriendSchema } from "@podcasts/schemas";

function ReferForm({
  initialValue,
  onSubmit,
  error = null
}: any) {

  return (
    <Formik
      enableReinitialize
      validateOnBlur={true}
      validateOnChange={true}
      validateOnMount={true}
      initialValues={initialValue}
      validationSchema={referFriendSchema}
      onSubmit={() => {}}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }) => {
        const friendsData = values.data;

        return (
          <>
            <Form>
              <FieldArray
                name="data"
                render={({ push, remove }) => (
                  <div>
                    {friendsData.map((friend, index) => (
                      <div className={`${styles["refer-friend-form"]}`}  key={index}>
                        <div className={`${styles["custom-row"]}`}>
                          <div className={`${styles["inputs-box"]}`}>
                            <Input
                              label="First Name *"
                              name={`data[${index}]['first_name']`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["data"][index]["first_name"]}
                              error={
                                (errors["data"] && errors["data"][index] && errors["data"][index]["first_name"] &&
                                                touched["data"] && touched["data"][index] && touched["data"][index]["first_name"])
                                  ? (errors["data"][index]["first_name"] as any)
                                  : null
                              }
                            />
                            <Input
                              label="Last Name *"
                              name={`data[${index}]['last_name']`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["data"][index]["last_name"]}
                              error={
                                (errors["data"] && errors["data"][index] && errors["data"][index]["last_name"] &&
                                                touched["data"] && touched["data"][index] && touched["data"][index]["last_name"])
                                  ? (errors["data"][index]["last_name"] as any)
                                  : null
                              }
                            />
                            <div>
                              <Input
                                type="email"
                                label="Email *"
                                name={`data[${index}]['email']`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values["data"][index]["email"]}
                                error={
                                  (errors["data"] && errors["data"][index] && errors["data"][index]["email"] &&
                                        touched["data"] && touched["data"][index] && touched["data"][index]["email"])
                                    ? ((errors["data"][index]["email"]) as any)
                                    : null
                                }
                              />
                              { error && !index && (
                                <p className={`${styles["error-message"]}`}>{error}</p>
                              )}
                            </div>
                            <Input
                              type="tel"
                              label="Phone number *"
                              name={`data[${index}]['phone']`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["data"][index]["phone"]}
                              error={
                                (errors["data"] && errors["data"][index] && errors["data"][index]["phone"] &&
                                                touched["data"] && touched["data"][index] && touched["data"][index]["phone"])
                                  ? (errors["data"][index]["phone"] as any)
                                  : null
                              }
                            />
                          </div>
                          <div className={`${styles["row-control"]}`}>
                            {!(index < friendsData.length - 1) ? (
                              <div
                                className={`${styles["control-box"]}`}
                                onClick={() => push({ first_name: "", last_name: "", email: "", phone: "" })}
                              >
                                <i className={"icon-plus"} style={{ fontSize: "15px" }}></i>
                              </div>
                            ) : (
                              <div
                                className={`${styles["control-box"]}`}
                                onClick={() => remove(index)}
                              >
                                <i className={"icon-minus"} style={{ fontSize: "3px" }}></i>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
              <div className={`${styles["button"]}`}>
                <Button
                  name="Submit"
                  onClick={() => { onSubmit(errors, values) }}
                />
              </div>
            </Form>
          </>
        )
      }}
    </Formik>
  );
}

export default ReferForm;
