import { boolean } from "yup/lib/locale";
import styles from "./FilterIcon.module.scss";

interface FilterIconProps {
  active?: boolean;
}

export function FilterIcon({ active = false }: FilterIconProps) {
  return (
    <div className={styles["filter-icon"]}>
      <div className={`${active ? styles["active"] : ""}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <circle className={styles["filter-icon-bg"]} cx="11" cy="11" r="10" />
          <path
            className={styles["filter-icon-center"]}
            d="M16.0067 6.66433C15.8108 6.54358 15.5508 6.59812 15.4256 6.78559L10.3791 14.3225C10.2695 14.4858 10.1255 14.5817 9.97378 14.5919C9.82078 14.601 9.6662 14.5262 9.53381 14.3778L6.53957 11.0246C6.38868 10.8559 6.12311 10.8359 5.94659 10.981C5.77033 11.1258 5.74999 11.381 5.90087 11.5502L8.89511 14.9036C9.17971 15.2218 9.55891 15.4001 9.94762 15.4001C9.97563 15.4001 10.0034 15.3993 10.0314 15.3971C10.4489 15.3694 10.8331 15.1368 11.0865 14.7585L16.1327 7.22243C16.2587 7.03471 16.2019 6.78508 16.0067 6.66433Z"
            fill="white"
            stroke="white"
            strokeWidth="0.2"
          />
        </svg>
      </div>
    </div>
  );
}

export default FilterIcon;
