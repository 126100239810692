import linkedinIcon from "../../../../../../assets/images/social/linkedin.svg";
import fbICon from "../../../../../../assets/images/social/fb.svg";
import twitterIcon from "../../../../../../assets/images/social/twitter.svg";
import styles from "./SocialSharing.module.scss";

const SocialSharing = ({ podcast }: any) => {
  const currentFullPath = window.location.href;

  const socials = [
    {
      network: "Linkedin",
      icon: linkedinIcon,
      link: `https://www.linkedin.com/sharing/share-offsite/?url=${currentFullPath}`
    },
    {
      network: "Facebook",
      icon: fbICon,
      link: `https://www.facebook.com/sharer/sharer.php?u=${currentFullPath}`
    },
    {
      network: "Twitter",
      icon: twitterIcon,
      link: `https://twitter.com/intent/tweet?text=Check%20out%20this%20podcast%20from%20the%20Podcast%20Fellowship!%20&url=${currentFullPath}`
    }
  ];
    
  return (
    <div className={`${styles["social-box"]}`}>
      <p>Share:</p>
      <ul className="d-flex align-items-center">
        {
          socials.map((social) => (
            <li key={social.network}>
              <a href={social.link} target="_blank" rel="noreferrer">
                <img src={social.icon} alt={`ICON ${social.network}`} />
              </a>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default SocialSharing;