import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { userSelector } from "@podcasts/store/user";
import { useIsAuthenticated } from "./useIsAuthenticated";

export const useRequestPayment = () => {
    const { user } = useSelector(userSelector);
    const isAuthenticated = useIsAuthenticated()
    const [canRequestPayment, setCanRequestPayment] = useState(true);

    useEffect(() => {
       setCanRequestPayment(isAuthenticated && ['Active', 'Accepted', 'Assigned', 'Dormant', 'Fumble'].includes(user?.status)
           && user?.mentor);
    },[user, isAuthenticated])

    return { canRequestPayment }
}