import React from "react";
import styles from "./ApplicationCTABanners.module.scss";
import { Link } from "react-router-dom";
import { Button } from "../../components";
import { useLocation } from "react-router-dom";
import headphonesImage from "../../assets/images/headphones.png";
import ribbon from "../../assets/images/ribbon.png";

export function ApplicationCTABanners({
  isAuthenticated = false,
  title = "Apply now to earn $15 for each class you listen to and discuss with one person.",
  buttonName = "Apply now",
  buttonLink = "/register",
  rightImage = headphonesImage,
  rightImageAlt = "headphones",
}) {
  const location = useLocation();

  if (location.pathname === "/podcasts/featured" && window.innerWidth >= 1280) {
    return (
      <section className={styles["large-banner"]}>
        <div className={styles["left-content"]}>
          <div className={styles["content-box"]}>
            <img src={ribbon} alt="" />
            <p>{title}</p>
            <Link to={buttonLink}>
              <Button name={buttonName} onClick={() => { }} white={true} />
            </Link>
            {!isAuthenticated &&
              <span>Already have an account? <Link to="/login">Login</Link></span>
            }
          </div>
        </div>
        <div className={styles["right-content"]}>
          <img src={rightImage} alt={rightImageAlt} />
        </div>
      </section>
    );
  } else {
    return (
      <section className={styles["small-banner"]}>
        <div className={styles["left-content"]}>
          <img src={ribbon} alt="" />
          <p>{title}</p>
        </div>
        <div className={styles["right-content"]}>
          <Link to={buttonLink}>
            <Button name={buttonName} onClick={() => { }} white={true} />
          </Link>
          {!isAuthenticated && <span>Already have an account? <Link to="/login">Login</Link></span>}
        </div>
      </section>
    )
  }
}

export default ApplicationCTABanners;