import { useReferrals } from './api';
import { get, last, sumBy, first} from 'lodash';
import { useCallback, useState } from "react";

export const useReferralsPage = ( { per_page = 15 }) => {
    const [page,setPage] = useState(1);

    const {
        data,
        fetchNextPage,
        isFetchingPreviousPage,
        isFetchingNextPage,
        isFetching,
        isRefetching
    } = useReferrals({ page, per_page });
    const { total_amount } = data;
    const last_request_on = get(first(data.data), "created_at", null);

    const onNextPage = useCallback(() => {
        if (!isFetchingPreviousPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [isFetchingPreviousPage, isFetchingNextPage]);

    const onPageChange = useCallback((p) => {
        setPage(p);
    },[]);

    return {
        data,
        last_request_on,
        total_amount,
        fetchNextPage,
        isFetchingPreviousPage,
        isFetchingNextPage,
        isFetching,
        isRefetching,
        onNextPage,
        onPageChange
    };
}