import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import {
  HomePage,
  DonatePage,
  AboutPage,
  TriviaPage,
  LoginPage,
  ResetPasswordPage,
  ContactUsPage,
  RegisterPage,
  ProfilePage,
  DashboardPage,
  PrivacyPolicy,
  MentorApplyPage
} from "../../pages";
import { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollTop";
import LoadingOverlay from "react-loading-overlay-ts";
import { apiSelector } from "@podcasts/store/api"
import { useSelector } from "react-redux";
import ForgotPasswordPage from "../../pages/ForgetPasswordPage/ForgotPasswordPage";

function NotDashBoard() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/contact" element={<ContactUsPage />}></Route>
        <Route path="/reset-password" element={<ResetPasswordPage />}></Route>
        <Route path="/forgot-password" element={<ForgotPasswordPage />}></Route>
        <Route path="/register" element={<RegisterPage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/trivia" element={<TriviaPage />}></Route>
        <Route path="/donate" element={<DonatePage />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/profile/*" element={<ProfilePage />}></Route>
        <Route path="/mentor-apply" element={<MentorApplyPage />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

function Main() {
  const { loading } = useSelector(apiSelector) as any;

  return (
    <Fragment>
      <Router>
        <ScrollToTop></ScrollToTop>
        <Header />
        <Fragment>
          <Routes>
            <Fragment>
              <Route path="/podcasts/*" element={<DashboardPage />}></Route>
            </Fragment>{" "}
            : (
            <Fragment>
              <Route path="/login" element={<LoginPage />}></Route>
            </Fragment>
            )
            <Fragment>
              <Route path="/*" element={<NotDashBoard />}></Route>
            </Fragment>
          </Routes>
        </Fragment>
      </Router>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            position: "fixed",
            display: loading ? "block" : "none",
            width: "100%",
            zIndex: 99,
            height: "100%",
          },
        }}
        text="Loading your content..."
      ></LoadingOverlay>
    </Fragment>
  );
}

export default Main;
