import styles from "./NumberRating.module.scss";

interface InputProps {
  titleStart: string;
  titleEnd: string;
  name: string;
  value: string | number;
  countInput?: string | number;
  onChange: any;
}

export function NumberRating({
  titleStart,
  titleEnd,
  name,
  value,
  countInput,
  onChange = () => {},
}: InputProps) {
  return (
    <div className={`${styles["rating-inputs-box"]} d-flex align-items-center`}>
      <p>{titleStart}</p>
      <div className={styles["rating-inputs"]}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={value == 1}
          onChange={() => onChange(1)}
          id={name + 1}
        />
        <label htmlFor={name + 1}>1</label>
        <input
          type="radio"
          name={name}
          value={value}
          checked={value == 2}
          onChange={() => onChange(2)}
          id={name + 2}
        />
        <label htmlFor={name + 2}>2</label>
        <input
          type="radio"
          name={name}
          value={value}
          checked={value == 3}
          onChange={() => onChange(3)}
          id={name + 3}
        />
        <label htmlFor={name + 3}>3</label>
        <input
          type="radio"
          name={name}
          value={value}
          checked={value == 4}
          onChange={() => onChange(4)}
          id={name + 4}
        />
        <label htmlFor={name + 4}>4</label>
        <input
          type="radio"
          name={name}
          value={value}
          checked={value == 5}
          onChange={() => onChange(5)}
          id={name + 5}
        />
        <label htmlFor={name + 5}>5</label>
      </div>
      <p>{titleEnd}</p>
    </div>
  );
}

export default NumberRating;
