import styles from "./StarNameItem.module.scss";
import { Link } from "react-router-dom";
import { useIsAuthenticated } from "@podcasts/hooks";

export function StarNameItem( {
  saved,
  title,
  duration,
  name,
  speaker,
  separator,
  onClick,
  slug,
  onToggleFavorite,
}: any) {
  const isAuthenticated = useIsAuthenticated();
  
  return (
    <>
      <div className="d-flex align-items-center">
        {
          isAuthenticated && (
            <button className="normalize-btn" onClick={onToggleFavorite}>
              <i className={`${saved ? "icon-full-star" : "icon-empty-star"}`}></i>
            </button>
          )
        }
        <div className={`${styles["saved-podcasts-title"]}`}>
          <p>
            <Link to={`/podcasts/${slug}`}>
              {title}
            </Link>
          </p>
          
          <p className="d-flex align-items-center">
            {speaker && (
              <span className={`${styles["name"]}`}>{speaker?.title} {speaker?.fname} {speaker?.lname}</span>
            )}
          </p>
        </div>
      </div>
    </>
  );
}

export default StarNameItem;
