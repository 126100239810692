import styles from "./PlayIcon.module.scss";

export function PlayIcon() {
  return (
    <div className={styles["play-icon"]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <circle className={styles["play-icon-circle"]} cx="15" cy="15" r="14" />
        <path
          className={styles["play"]}
          d="M21.3682 13.8696L11.9393 8.17958C11.1732 7.71749 10 8.16591 10 9.30884V20.6861C10 21.7115 11.0902 22.3294 11.9393 21.8154L21.3682 16.1281C22.2093 15.6223 22.2119 14.3754 21.3682 13.8696Z"
        />
        <circle cx="15" cy="15" r="14.5" stroke="black" />
        <path
          d="M21.3682 13.8696L11.9393 8.17958C11.1732 7.71749 10 8.16591 10 9.30884V20.6861C10 21.7115 11.0902 22.3294 11.9393 21.8154L21.3682 16.1281C22.2093 15.6223 22.2119 14.3754 21.3682 13.8696ZM20.9342 15.3762L11.5054 21.0634C11.2215 21.2302 10.8572 21.0306 10.8572 20.6861V9.30884C10.8572 8.86315 11.2965 8.80573 11.5027 8.93151L20.9316 14.6215C21.2128 14.791 21.2128 15.2066 20.9342 15.3762Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default PlayIcon;
