import React from "react";
import styles from "../PrivacyPolicyPage/PrivacyPolicy.module.scss";

export function PrivacyPolicy() {

  return (
    <div className="container mobile-container">
      <div className={styles["privacy-and-policy"]}>
        <h2>Privacy Policy</h2>
        <p>This Privacy Policy governs the manner in which The Podcast Fellowship collects, uses, maintains and
            discloses information collected from users (each, a "User") of the The Podcast Fellowship website
            ("Site") and mobile app ("App"). This privacy policy applies to the Site, the App and all products
            and services offered by The Podcast Fellowship</p>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>Personal identification information</p>
        <p>We may collect personal identification information from Users in a variety of ways, including, 
                but not limited to, when Users visit our Site, visit our App, register on the Site, register on 
                the App, place an order, subscribe to the newsletter, respond to a survey, fill out a form, and 
                in connection with other activities, services, features or resources we make available on our Site 
                and on our App. Users may be asked for, as appropriate, name, email address, mailing address, phone 
                number, credit card information. Users may, however, visit our Site or our App anonymously. 
                We will collect personal identification information from Users only if they voluntarily submit 
                such information to us. Users can always refuse to supply personal identification information, 
                except that it may prevent them from engaging in certain Site related activities.</p>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>Non-personal identification information</p>
        <p>We may collect non-personal identification information about Users whenever they interact with our 
                Site or our App. Non-personal identification information may include the browser name, 
                the type of computer and technical information about Users' means of connection to our 
                Site or our App, such as the operating system and the Internet service providers utilized and 
                other similar information.</p>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>Web browser cookies</p>
        <p>Our Site and App may use "cookies" to enhance User experience. User's web browser places cookies on 
                their hard drive for record-keeping purposes and sometimes to track information about them. 
                User may choose to set their web browser to refuse cookies, or to alert you when cookies are 
                being sent. If they do so, note that some parts of the Site or App may not function properly.</p>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>How we use collected information</p>
        <p>The Podcast Fellowship may collect and use Users personal information for the following purposes:</p>
        <ul>
          <li>
            <p>
              <span className={styles["font-style-italic"]}>- To improve customer service</span><br/>
                Information you provide helps us respond to your customer service requests and 
                support needs more efficiently.</p>
            <p>
              <span className={styles["font-style-italic"]}>- To personalize user experience</span><br/>
                  We may use information in the aggregate to understand how our Users as a group use the services and 
                  resources provided on our Site and App.</p>
            <p>
              <span className={styles["font-style-italic"]}>- To improve our Site or App</span><br/>
                  We may use feedback you provide to improve our products and services.</p>
            <p>
              <span className={styles["font-style-italic"]}>- To process payments</span><br/>
                  We may use the information Users provide about themselves when placing an order only to provide 
                  service to that order. We do not share this information with outside parties except to 
                  the extent necessary to provide the service.</p>
            <p>
              <span className={styles["font-style-italic"]}>- To share your information with third parties</span><br/>
                We may share or sell information with third parties for marketing or other purposes.</p>
            <p>
              <span className={styles["font-style-italic"]}>- To run a promotion, contest, survey or
                  other Site or App feature</span><br/>
                To send Users information they agreed to receive about topics we think will be of interest to them.</p>
            <p>
              <span className={styles["font-style-italic"]}>- To send periodic emails</span><br/>
                  We may use the email address to send User information and updates pertaining to their order. 
                  It may also be used to respond to their inquiries, questions, and/or other requests. 
                  If User decides to opt-in to our mailing list, they will receive emails that may include 
                  company news, updates, related product or service information, etc. 
                  If at any time the User would like to unsubscribe from receiving future emails, we include 
                  detailed unsubscribe instructions at the bottom of each email or User may contact 
                  us via our Site or App.</p>
          </li>
        </ul>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>How we protect your information</p>
        <p>We adopt appropriate data collection, storage and processing practices and security measures to
                protect against unauthorized access, alteration, disclosure or destruction of your personal
                information, username, password, transaction information and data stored on our Site and App.</p>
        <p>Sensitive and private data exchange between the Site, the App, and their Users happens over a SSL 
                secured communication channel and is encrypted and protected with digital signatures. 
                Our Site and App are also in compliance with PCI vulnerability standards in order to create as 
                secure of an environment as possible for Users.</p>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>Sharing your personal information</p>
        <p>We may use third party service providers to help us operate our business and the Site and App 
                or administer activities on our behalf, such as sending out newsletters or surveys. We may 
                share your information with these third parties for those limited purposes provided that you 
                have given us your permission.</p>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>Third party websites</p>
        <p>Users may find advertising or other content on our Site or App that link to the sites and services 
                of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do 
                not control the content or links that appear on these sites and are not responsible for the 
                practices employed by websites linked to or from our Site or App. In addition, these sites or 
                services, including their content and links, may be constantly changing. These sites and services 
                may have their own privacy policies and customer service policies. Browsing and interaction on any 
                other website, including websites which have a link to our Site and App, is subject to that website's 
                own terms and policies.</p>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>Advertising</p>
        <p>Ads appearing on our Site or App may be delivered to Users by advertising partners, who may set cookies. 
                These cookies allow the ad server to recognize your computer each time they send you an online 
                advertisement to compile non personal identification information about you or others who use your 
                computer. This information allows ad networks to, among other things, deliver targeted advertisements 
                that they believe will be of most interest to you. This privacy policy does not cover the use of 
                cookies by any advertisers.</p>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>Changes to this privacy policy</p>
        <p>The Podcast Fellowship has the discretion to update this privacy policy at any time. When we do, we 
                will revise the updated date at the bottom of this page. We encourage Users to frequently check 
                this page for any changes to stay informed about how we are helping to protect the personal information 
                we collect. You acknowledge and agree that it is your responsibility to review this privacy policy 
                periodically and become aware of modifications.</p>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>Your acceptance of these terms</p>
        <p>By using this Site or App, you signify your acceptance of this policy. If you do not agree to this
                policy, please do not use our Site or App. Your continued use of the Site or App following the posting
                of changes to this policy will be deemed your acceptance of those changes.</p>
      </div>
      <div>
        <p className={styles["font-weight-bold"]}>Contacting us</p>
        <p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with
                this site, please contact us at:<br/>
        <a href="mailto:info@podcastfellowship.org">info@podcastfellowship.org</a>
        </p>
      </div>
    </div>

  );
}

export default PrivacyPolicy;
