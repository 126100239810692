import PlayIcon from "../../../../../../components/PlayIcon/PlayIcon";

import { Link } from "react-router-dom";
import styles from "./FeaturedItem.module.scss";
import { useIsAuthenticated } from "@podcasts/hooks";

interface FeaturedItemProps {
  items: any[];
}

export function FeaturedItem({
  topics = [],
  courses = [],
  title,
  speaker,
  saved,
  onSave,
  slug,
}: any) {
    
  const isAuthenticated = useIsAuthenticated();
    
  return (
    <div className={`${styles["item"]} orange-after-box`}>
      <div
        className={`${styles["item-header"]} d-flex justify-content-between align-items-center`}
      >
        {topics.length ? <span>{topics[0]?.title}</span> : null}
        {courses.length ? <span>{courses[0]?.title}</span> :  null}
        {
          isAuthenticated && (
            <button className="normalize-btn" onClick={onSave}>
              <i
                className={`${saved ? "icon-full-star" : "icon-empty-star"}`}
              ></i>
            </button>
          )
        }
      </div>
      <p className={styles["item-name"]}>{title}</p>
      { speaker ? ( <div className={`${styles["item-track"]} d-flex align-items-center`}>
        <div className={`${styles["featured-avatar"]}`}>
          <img src={speaker.image} alt="" />
        </div>
        <p>
          {speaker.title} {speaker.fname} {speaker.lname}
        </p>
      </div>) : null}
      <Link
        to={`/podcasts/${slug}`}
        className={`${styles["item-link"]} d-flex align-items-center`}
      >
        <span>Listen here</span>
        <div>
          <PlayIcon />
        </div>
      </Link>
    </div>
  );
}

export default FeaturedItem;
