import { useCallback } from "react";
import { useContact } from "./api";
import { showToastMessage } from "./useWebLibraryToast";

export const useContactUs = () => {
    const sendContactUsFormMutation = useContact(null);

    const sendContactUsForm = useCallback(async (body, resetForm) => {
        try {
            const { data: { message }} = await sendContactUsFormMutation.mutateAsync(body);
            showToastMessage(message);
            resetForm();
        } catch (e) {
            showToastMessage(e.response?.data?.message, "error");
        }
    },[]);

    return {
        sendContactUsForm,
    }
}
