import { useCallback, useState } from "react";
import { usePodcastsLibraryBase } from './library-base';
import { usePodcastLibraryAppToast } from './useAppLibraryToast';
import { useRecentlyViewed } from './api';

import { useIsFocused } from "@react-navigation/native";
import { useEffect } from "react";

export const useRecentlyViewedPage = () => {
  return useRecentlyViewedBase({});
}

export const useRecentlyViewedApp = () => {
  const isFocused = useIsFocused()
  const toast = usePodcastLibraryAppToast({ type: 'success' })

  const base = useRecentlyViewedBase(toast);

  useEffect(() => {
    if(isFocused) {
      base.refetch()
    }
  },[isFocused]);

  return base
}

export const useRecentlyViewedBase = (props = {}) => {
  const [page,setPage] = useState(1);
  const { togglePodcastLibrary, isSavedPodcast } = usePodcastsLibraryBase(props);
  const {
    data,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    isRefetching } = useRecentlyViewed({ page });

  const onNextPage = useCallback(() => {
    if (!isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage]);


  const onSavePodcast = useCallback((item) => {
      togglePodcastLibrary(item)
  },[]);

  const onPageChange = useCallback((p) => {
     setPage(p)
  },[])

  return {
      data,
      refetch,
      isSavedPodcast,
      fetchNextPage,
      isFetchingPreviousPage,
      isFetchingNextPage,
      isFetching,
      isRefetching,
      onNextPage,
      onPageChange,
      onSavePodcast
  }
}
