import { startCase, lowerCase, includes, startsWith, split } from "lodash";

const pageTabTitles = (location: string) => {
    if (startsWith(location, "/profile")) {
        return startCase(location.substring(9));
    } else if (!startsWith(location, "/podcasts")) {
        return startCase(location.substring(1));
    } else if (startsWith(location, "/podcasts/saved-")) {
        return startCase(lowerCase(location.substring(10)));
    } else if (startsWith(location, "/podcasts/recent")) {
        return "Recently viewed";
    } else if (startsWith(location, "/podcasts/")) {
        const pathEnd = location.substring(10);
        if (!includes(pathEnd, "/")) {
            return startCase(lowerCase(pathEnd)); 
        } else {
            return startCase(lowerCase(split(pathEnd, "/")[1]));
        }
    } else {
        return "";
    }
}

export default pageTabTitles;