import * as yup from "yup";
import { levelValues } from '@podcasts/constants'

export const phoneRegex = RegExp(/^[0-9/s(.)+#-]*$/);

export const loginSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
});

export const resetPassSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

export const firstApplicationStepSchema = (isIos = false) => yup.object().shape({
  fname: yup.string().required("Required"),
  lname: yup.string().required("Required"),
  country: yup.string().required("Required").nullable(),
  email: yup.string().typeError("Required").email("Invalid format").required("Required"),
  phone: yup.string().when([], {
    is: () => !isIos,
    then: yup.string().required().typeError("Required").matches(phoneRegex, "Invalid"),
    otherwise: yup.string().matches(phoneRegex, "Invalid")
  }),
  gender: yup.mixed().oneOf(["m", "f"]).nullable(),
  dob: yup.date().when([], {
    is: () => !isIos,
    then: yup.date().required().typeError("Invalid format"),
    otherwise: yup.date().typeError("Invalid format")
  }),
  city_residence: yup.string().nullable(),
  state_residence: yup.string().nullable(),
});

export const secondApplicationStepSchema = yup.object().shape({
  religion_born: yup.string().required("Required").nullable(),
  religion_identify_with: yup.string().required("Required").nullable(),
  father_religion: yup.string().required("Required").nullable(),
  mother_religion: yup.string().required("Required").nullable(),
  jewish_story: yup.string().required("Required").nullable(),
  jewish_education: yup.bool().required("Required").nullable(),
  jewish_education_description: yup
    .string()
    .nullable()
    .test("email-match", "Required", function (value) {
      if (this.parent.jewish_education) {
        return (value || "").length > 0;
      }
      return true;
    }),
});

export const thirdApplicationStepSchema = yup.object().shape({
  position: yup.string().required("Required"),
  school: yup
    .string()
    .nullable().test("email-match", "Required", function (value) {
    if (this.parent.position === "Student") {
      return (value || "").length > 0;
    }
    return true;
  }),
  city: yup.string().required("Required").nullable(),
  state: yup.string().required("Required").nullable(),
  // internship: yup.string().required("Required").nullable(),
  referral_method: yup.string().required("Required").nullable(),
  accept: yup.boolean().required().oneOf([true], "Required"),
  referral_name: yup
    .string()
    .nullable()
    // .test("email-match", "Required", function (value) {
    //   if (this.parent.referral_method === "friend") {
    //     return (value || "").length > 0;
    //   }
    //   return true;
    // })
    ,
   other_referral_method: yup
        .string()
        .nullable()
        // .test("email-match", "Required", function (value) {
        //     if (this.parent.referral_method === "other") {
        //         return (value || "").length > 0;
        //     }
        //     return true;
        // }),
});



export const editProfileSchema = yup.object().shape({
  email: yup.object({
    new_email: yup.string().nullable().email("Invalid format"),
    confirm_email: yup
      .string()
      .nullable()
      .test("email-match", "Does not match new email", function (value) {
        return this.parent.new_email === value;
      })
      .email("Invalid format"),
  }),
  password: yup.object({
    current_password: yup
      .string()
      .nullable()
      .min(8, "Your password is too short.")
      .test(
        "email-matchsa",
        "Current password is required",
        function (value) {
          if ((value || "").length === 0) {
            return (this.parent.new_password || "").length === 0;
          } else {
            return true;
          }
        }
      ),
    new_password: yup
      .string()
      .nullable()
      .min(8, "Your password is too short.")
      .test("email-match", "Required", function (value) {
        if (
          (value || "").length === 0 &&
          (this.parent.current_password || "").length > 0
        ) {
          return false;
        } else {
          return true;
        }
      }),
    confirm_password: yup
      .string()
      .nullable()
      .min(8, "Your password is too short.")
      .test("email-match", "Does not match new password", function (value) {
        return this.parent.new_password === value;
      }),
  }),
  phone: yup.string().typeError("Phone number is required").matches(phoneRegex, "Invalid").required("Phone number is required"),
  city: yup.string().required(),
  school: yup.string().required(),
  difficulty: yup
    .mixed()
    .oneOf([levelValues.BEGINNER, levelValues.INTERMEDIATE, levelValues.ADVANCED])
    .required(),
});

export const contactUsSchema = yup.object().shape({
  email: yup.string().typeError("Email is required").email("Invalid format").required("Email is required"),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().typeError("Phone number is required").matches(phoneRegex, "Invalid").required("Phone number is required"),
  message: yup.string().required(),
});


export const referFriendSchema = () =>  yup.lazy((values) => {
    const emails = values.data.map(item => item.email);

    return yup.object().shape({
        data: yup.array().of(
            yup.object().shape({
                first_name: yup.string().required("First name is required"),
                last_name: yup.string().required("Last name is required"),
                email: yup
                    .string()
                    .typeError("Email is required")
                    .email("Invalid format")
                    .required("Email is required")
                    .test(
                        "existsCheck",
                        "Email already exists",
                        (value) => (new Set(emails).size === emails.length)
                    ),
                phone: yup.string().required("Phone number is required"),
            })
        )
    });
});

export const firstStepMentorSchema = yup.object().shape({
  fname: yup.string().required("Required"),
  lname: yup.string().required("Required"),
  email: yup.string().typeError("Required").email("Invalid format").required("Required"),
  phone: yup.string().typeError("Required").matches(phoneRegex, "Invalid").required("Required"),
  gender: yup.mixed().oneOf(["m", "f"]).required("Required"),
  dob: yup.date().typeError("Invalid format").required("Required"),
  calendly_url: yup.string().matches(
        /^(https:\/\/calendly.com)(\/?[0-9a-z])*\/?$/,
        'Invalid URL. Format must contain "https://calendly.com"'
    ).required("Required"),
});

export const secondStepMentorSchema = yup.object().shape({
  country: yup.string().required("Required"),
  school: yup.string().required("Required"),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  bio: yup.string().required("Required")
});