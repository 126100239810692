
import { useCallback } from "react";
import { useTopicLibraryBase } from './library-base';
import { useTopic, usePodcastTopic } from './api';
import { useTopicLibraryAppToast } from './useAppLibraryToast';
import { useTopicLibraryWebToast } from "./useWebLibraryToast";

export const useTopicBasePage = (slug,props = {}) => {
  const { isSavedTopic, toggleTopicLibrary, isAuthenticated } = useTopicLibraryBase(props);
  const  { data } = useTopic({ slug })
  const onSaveTopic = useCallback(() => {
    toggleTopicLibrary(data)
 },[data]) 

  return { data, isSavedTopic, onSaveTopic, isAuthenticated }
}

export const useTopicPage = (slug) => {
  const toast = useTopicLibraryWebToast();
  return useTopicBasePage(slug, toast);
}

export const usePodcastTopicPage = (slug) => {
  const toast = useTopicLibraryAppToast();
  const { isSavedTopic, toggleTopicLibrary, isAuthenticated } = useTopicLibraryBase(toast);
  const  { data } = usePodcastTopic(slug)
  const onSaveTopic = useCallback(() => {
    toggleTopicLibrary(data)
 },[data,isAuthenticated]) 

  return { data, isSavedTopic, onSaveTopic, isAuthenticated }
}

export const useTopicAppPage = (slug) => {
  const toast = useTopicLibraryAppToast();
  return useTopicBasePage(slug,toast);
}
