import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  mentorSignUp
} from "@podcasts/api";
import { get } from "lodash";

const initialState = {
  step: 1,
  user: {
    // step 1
    email: "",
    fname: "",
    lname: "",
    phone: "",
    gender: "",
    dob: "",
    calendly_url: "",
    //Step 2
    country: "",
    school: "",
    city: "",
    state: "",
    bio: ""
  },
  error: "",
  errorTitle: "",
  buttons: [
    { label: "", link: "" }
  ],
  resetPasswordToken: null,
  getUserSuccessMessage: null,
  sendConfirmationEmail: null,
};

export const mentorSignUpSubmit = createAsyncThunk(
  "mentor/signUp",
  async (options, { rejectWithValue, getState }) => {
    try {
      const user = {...get(getState(), "mentor.user"), ...options};
      console.log(user);
      const { data } = await mentorSignUp(user);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetState = createAsyncThunk(
  "mentor/resetState",
  async (options, { rejectWithValue, getState }) => {
    try {
      return {};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getIncompleteUser = createAsyncThunk(
    "mentor/getIncompleteUser",
    async (options, { rejectWithValue, getState }) => {
      try {
        const { data } = await getIncompleteUserData(options);
        return data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);


export const appSlice = createSlice({
  name: "mentor",
  initialState,
  reducers: {
    // first step
    setFirstStep: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.step = 2;
    },
    setSecondStep: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    nextStep(state) {
      if (state.step <= 5) {
        state.step = state.step + 1;
      }
    },
    prevStep(state) {
      if (state.step >= 1) {
        state.step = state.step - 1;
      }
    },
    setStep(state, options) {
      if (+options.payload <= 5 && +options.payload >= 1) {
        state.step = options.payload;
      }
    },
  },
  extraReducers: {

    // second step
    [mentorSignUpSubmit.pending]: (state, action) => {},
    [mentorSignUpSubmit.fulfilled]: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.step = 3;
    },
    [mentorSignUpSubmit.rejected]: (state, action) => {
      console.log(action);
      state.error = action.payload.message;
    },
    [resetState.fulfilled]: (state, action) => {
      state.error = "";
      state.errorTitle = "";
      state.step = 1;
      state.resetPasswordToken = null;
      state.user = { ...state.user, email: null, referral_method: null };
    }
  },
});

export const { setStep, nextStep, prevStep, setFirstStep, setSecondStep } = appSlice.actions;
export const mentorReducer = appSlice.reducer;
