// import styles from './Footer.module.scss';
import React from "react";
import styles from "./CheckBox.module.scss";
import mainS from "../../../assets/scss/global.module.scss";

interface InputProps {
  label?: string;
  name: string;
  value: any;
  type?: string;
  onChange?: any;
  onBlur?: any;
  error?: string | null;
  checked?: boolean;
}

export function CheckBox({
  label = "",
  name,
  checked,
  value,
  onChange,
  onBlur,
  error,
}: InputProps) {
  return (
    <div className={styles["inputContainer"]}>
      <div>
        <label dangerouslySetInnerHTML={{ __html: label }}></label>
        <div className={styles["customRadio"]}>
          <input
            type="checkbox"
            defaultChecked={checked}
            value={value}
            name={name}
            onChange={onChange}
          />
          <span></span>
        </div>
      </div>
      {error && <span className={mainS["errorMessage"]}>{error}</span>}
    </div>
  );
}

export default CheckBox;
