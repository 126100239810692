import { Button } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import styles from "../..//ResetPasswordPage/ResetPasswordPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { appSelector } from "@podcasts/store/application";
import React from "react";
import { forgetPassword } from "@podcasts/store/auth";

export function ErrorPage() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errorTitle, error, buttons, user } = useSelector(appSelector) as any;
  const { email } = user;

  const handleForgetPasswordEmail = () => {
    dispatch(forgetPassword({ email }));
  }

  return (
    <div className={`${styles["login-wrapp"]}`}>
      <div className={`${styles["login-decor"]}`}>
        <div></div>
      </div>
      <div className={`${styles["form"]} }`}>
        <div className="flex-column d-flex align-items-center">
          <h2 className={styles["header"]}>{ errorTitle}</h2>
          <p className={styles["header-subtitle"]}>
            {error}
          </p>
          <div className={`${styles["button-row"]} ${buttons.length === 2 ? `${styles["register-error"]}` : ""}` }>
            {
              buttons.map(button => (
                button.label === "resend-password" ? (
                  <Button
                    name="Resend my password reset email"
                    onClick={handleForgetPasswordEmail}
                  />
                ) : (
                  <Button
                    name={button.label}
                    onClick={() => {navigate(button.link)}}
                  />
                )
              ))
            }
          </div>
        </div>
      </div>
      <div className={`${styles["login-decor"]}`}>
        <div></div>
      </div>
    </div>
  );
}
export default ErrorPage;
