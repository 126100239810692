import TopicsAllPage from "./inners/TopicsAllPage/TopicsAllPage";
import { Routes, Route } from "react-router-dom";
import TopicDetailsPage from "./inners/TopicDetailsPage/TopicDetailsPage";

export function TopicsPage() {
  return (
    <Routes>
      <Route path={`/`} element={<TopicsAllPage title="Topics" />} />
      <Route path={`/:slug`} element={<TopicDetailsPage />} />
    </Routes>
  );
}

export default TopicsPage;
