import styles from "./RecentPage.module.scss";
import { useRecentlyViewedPage } from '@podcasts/hooks'
import {  useState, useRef } from "react";
import PaginatedPodcastsTable from "../../components/PodcastsTable/PaginatedPodcastsTable";

export function RecentPage() {
  const per_page = 12;
  const [order_by, setOrder_by] = useState("");
  const myRef: any = useRef(null);
  const [order, setOrder] = useState("");

  const { 
    data: { data = [], last_page, current_page },
    onPageChange
  } = useRecentlyViewedPage()

  // On initial page load, data displays in default (unsorted) order of most recently viewed (from most recent to least recent). On  subsequent page loads, data is sorted by params selected by the user.
  // useEffect(() => {
  //   order === "" ? dispatch(getRecentlyViewed({page: 1, per_page})) : dispatch(getRecentlyViewed({ page: 1, order, order_by, per_page}));
  // }, [order, order_by]);

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth", block: 'center' });

  const handleSortChange = (e: any) => {
    // let sortBy = e.target.innerText.toLowerCase();
    // if (sortBy === order_by) {
    //   let sortOrder = order === "asc" ? "desc" : "asc";
    //   setOrder(sortOrder);
    // } else {
    //   setOrder_by(sortBy);
    //   setOrder("asc");
    // }
  }

  return (
    <>
      <div ref={myRef} className={`${styles["icon-title"]} d-flex align-items-center`}>
        <h2>Recently Viewed</h2>
      </div>
      <PaginatedPodcastsTable
        items={data}
        handleSortChange={handleSortChange}
        order_by={order_by}
        order={order}
        pages={last_page}
        page={current_page}
        pageName={"recently_viewed"}
        onPageChange={(page: number) => {
          onPageChange(page)
          executeScroll();
        }}
      />
    </>
  );
}

export default RecentPage;
