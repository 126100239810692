import { useCallback, useState } from "react";
import { useSpeakerLibraryBase } from './library-base';
import { useSavedSpeakers } from './api';
import { useSpeakerLibraryAppToast } from './useAppLibraryToast';
import { useSpeakerLibraryWebToast } from './useWebLibraryToast';

export const useSavedSpeakersPageBase = (props) => {
  const {
    per_page = 12, 
    order_by = 'lname', 
    defaultOrder = 'asc',
    onAddFinish,
    onRemoveFinish,
    onUnathorized,
  } = props;

  const [page,setPage] = useState(1);
  const [order, setOrder] = useState(defaultOrder)
  const { isSavedSpeaker, toggleSpeakerLibrary, isAuthenticated, saved_speakers_ids }  = useSpeakerLibraryBase({ onAddFinish, onRemoveFinish, onUnathorized});
  const { 
    data,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    isFetching,
    isRefetching } = useSavedSpeakers({ page, per_page, order_by, order, saved_speakers_ids});

  const onNextPage = useCallback(() => {
    if (!isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage]);


  const onSaveSpeaker = useCallback((item) => {
    toggleSpeakerLibrary(item)
  },[]);

  const onPageChange = useCallback((p) => {
    setPage(p)
   },[])

   const toggleOrder = useCallback(() => {
    setOrder(order === "asc" ? "desc" : "asc");
    setPage(1)
  },[order])
 

  return {
      data,
      order,
      setOrder,
      isSavedSpeaker,
      fetchNextPage,
      isFetchingPreviousPage,
      isFetchingNextPage,
      isFetching,
      isRefetching,
      onNextPage,
      onPageChange,
      onSaveSpeaker,
      isAuthenticated,
      toggleOrder
  }
}

export const useSavedSpeakersPage = ({ per_page = 12, order_by = 'lname', defaultOrder = 'asc' }) => {
  const toast = useSpeakerLibraryWebToast();
  return useSavedSpeakersPageBase({ per_page, order_by, defaultOrder,...toast })
}

export const useSavedSpeakersAppPage = ({ per_page = 12, order_by = 'lname', defaultOrder = 'asc' }) => {
   const toast = useSpeakerLibraryAppToast();
   return useSavedSpeakersPageBase({ per_page, order_by, defaultOrder, ...toast })
}