import SpeakersItem from "./inners/SpeakersPage/SpeakersPage";
import { Routes, Route } from "react-router-dom";
import SpeakerPage from "./inners/SpeakerPage/SpeakerPage";

export function SpeakersPage() {
  return (
    <Routes>
      <Route path={`/`} element={<SpeakersItem />} />
      <Route path={`/:slag`} element={<SpeakerPage />} />
    </Routes>
  );
}

export default SpeakersPage;
