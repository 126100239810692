// import styles from './Footer.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { mentorSelector } from "@podcasts/store/mentor";
import styles from "./MentorApply.module.scss";
import { FirstStep, SecondStep, ThankYou } from "./Steps";
import {
  prevStep,
  setFirstStep,
  setSecondStep,
  mentorSignUpSubmit
} from "@podcasts/store/mentor";
import { pick } from "lodash";
import { useQueryParam } from "../../hooks/useQueryParam";
import { useEffect } from "react";
import { usePrevious } from "../../hooks/usePrevious";
import { showToastMessage } from "../../utils/common";
import { useIsAuthenticated } from "@podcasts/hooks";
import { useNavigate } from "react-router-dom";

export function MentorApplyPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { step, user, getUserSuccessMessage: successMessage } = useSelector(mentorSelector) as any;
  const prevSuccessMessage = usePrevious(successMessage);
  const token = useQueryParam("token");
  const isAuthenticated = useIsAuthenticated();


  useEffect(() => {
    if (isAuthenticated) {
      showToastMessage("No need to register, you already have an account!", "error");
      navigate("/podcasts/featured");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (successMessage && successMessage !== prevSuccessMessage) {
      showToastMessage(successMessage);
    }
  }, [successMessage]);

  const firsStepInitialValue = pick(user, [
    "email",
    "fname",
    "lname",
    "phone",
    "gender",
    "dob",
    "calendly_url"
  ]);
  const secondStepInitialValue = pick(user, [
    "country",
    "school",
    "city",
    "state",
    "bio"
  ]);

  const commonProp: any = {
    onPrev: () => {
      dispatch(prevStep());
    },
  };

  const renderSteps = (n: number): any => {
    switch (n) {
    case 1:
      return (
        <FirstStep
          {...commonProp}
          initialValues={firsStepInitialValue}
          onNext={(values: any) => {
            dispatch(setFirstStep(values));
          }}
        ></FirstStep>
      );
    case 2:
      return (
        <SecondStep
          {...commonProp}
          initialValues={secondStepInitialValue}
          onNext={(values: any) => {
            dispatch(mentorSignUpSubmit(values));
          }}
          onPrev={(values: any) => {
            dispatch(setSecondStep(values));
            dispatch(prevStep());
          }}
        ></SecondStep>
      );
    case 3:
      return (<ThankYou />)
    }
  }
  return (
    <div
      className={`${styles["spaceBetweenHeaderFooter"]} container mobile-container`} >
      {renderSteps(step)}
    </div>
  );
  
}

export default MentorApplyPage;
