import { Link, useNavigate } from "react-router-dom";
import styles from "./TopicsItem.module.scss";
import PodcastCount from "../../../../../../components/PodcastCount/PodcastCount";
import { useIsAuthenticated } from "@podcasts/hooks";

export function TopicsItem({
  title,
  podcasts_count,
  slug,
  image,
  saved,
  type = "topics",
  description,
  bio,
  onToggleFavorite = () => {},
}: any) {

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const isFullBoxClickable = ["courses", "topics", "speakers"].includes(type);

  const handleViewPodcasts = () => {
    if (isFullBoxClickable) {
      navigate(`/podcasts/${type}/${slug}`);
    }
  }

  return (
    <div className={`${styles["item"]} orange-after-box ${isFullBoxClickable ? styles["cursor-pointer"] : ""}`} onClick={handleViewPodcasts}>
      <div className={`${type === 'speakers' ? styles["item-speaker"] : ''} ${styles["item-header"]}`}>
        <img src={image} alt="" />
      </div>
      <div className={`${styles["item-body"]}`}>
        <p className={styles["item-body-name"]}>{title}</p>
        <div className={`${styles["item-body-episode"]}`}>
          <p>
            <PodcastCount count={podcasts_count}/>
          </p>
          <p>{description ? description : bio}</p>
        </div>
        <div
          className={`${styles["item-body-link"]} d-flex justify-content-between align-items-center`}
        >
          <Link to={`/podcasts/${type}/${slug}`}>View podcasts</Link>
          {isAuthenticated && (
            <button
              onClick={onToggleFavorite}>
              <i
                className={`${
                  saved ? "icon-bookmark-full" : "icon-bookmark-empty"
                }`}
              ></i> 
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopicsItem;
