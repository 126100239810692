import axios from "axios";
const qs = require("qs");

export const getAllNotesApi = async (options = {}) => {
  return await axios.get(`students/notes?${qs.stringify(options)}`);
};

export const deleteNodeApi = async (id) => {
  return await axios.delete(`students/notes/${id}`);
};

// export const getNotesByPodcastId = async (id) => {
//   console.log(id,'id')
//   return await axios.get(`students/notes/${id}`);
// };


export const getNotesByPodcastId = async ({ slug, ...rest}) => {
  return await axios.get(`students/notes/${slug}?${qs.stringify(rest)}`);
};

export const createNote = async (podcastId, note) => {
  return await axios.post(`students/notes/${podcastId}`, { note });
};

export const updateNoteApi = async (id, note) => {
  console.log(note,id,'====')
  return await axios.put(`students/notes/${id}`, { note });
};

export const sendNotesToMentorApi = async (podcastSlug) => {
  return await axios.post(`students/notes/${podcastSlug}/submit`);
};
