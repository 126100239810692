import { useDispatch, useSelector } from "react-redux";
import { useCallback } from 'react'
import { dashboardSelector } from "@podcasts/store/dashboard";
import { saveTopic, saveSpeaker, saveCourse, savePodcast } from "@podcasts/store/dashboard"
import { useIsAuthenticated } from './useIsAuthenticated'

const defaultProps =  { onAddFinish:() => {}, onRemoveFinish:() => {}, onUnathorized: () => { } };

export const usePodcastsLibraryBase = ({ onAddFinish, onRemoveFinish, onUnathorized  } = defaultProps) => {
    const isAuthenticated = useIsAuthenticated()
    const dispatch = useDispatch();
    const { saved_podcasts_ids } = useSelector(dashboardSelector);

    const isSavedPodcast = useCallback((id) => {
     return saved_podcasts_ids.includes(id);
    },[saved_podcasts_ids]);
    
    const togglePodcastLibrary = async (prop) => {

        console.log(isAuthenticated,'isAuthenticatedisAuthenticated')
        if(isAuthenticated) {
            const { payload } = await dispatch(savePodcast(prop));
            if (payload.includes(prop.id)) {
               onAddFinish(prop)
             } else {
               onRemoveFinish(prop)
            }
        } else {
              onUnathorized(prop)
        }
      };
    
      return { togglePodcastLibrary, isSavedPodcast, saved_podcasts_ids, isAuthenticated};
}

export const useTopicLibraryBase = ({ onAddFinish, onRemoveFinish, onUnathorized  } = defaultProps) => {
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated()
    const { saved_topics_ids } = useSelector(dashboardSelector);

    const isSavedTopic = useCallback((id) => {
     return saved_topics_ids.includes(id);
    },[saved_topics_ids]);
    
    const toggleTopicLibrary = useCallback(async (prop) => {
        if(isAuthenticated) {
            const { payload } = await dispatch(saveTopic(prop));
            if (payload.includes(prop.id)) {
                onAddFinish(prop)
             } else {
                onRemoveFinish(prop)
            }
        } else {
               onUnathorized(prop)
        }
      },[isAuthenticated]);
    
      return { toggleTopicLibrary, isSavedTopic, saved_topics_ids, isAuthenticated };
}

export const useSpeakerLibraryBase = ({ onAddFinish, onRemoveFinish, onUnathorized  } = defaultProps) => {
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated()
    const { saved_speakers_ids } = useSelector(dashboardSelector);

    const isSavedSpeaker = useCallback((id) => {
     return saved_speakers_ids.includes(id);
    },[saved_speakers_ids]);

    const toggleSpeakerLibrary = useCallback(async (prop) => {
        if(isAuthenticated) {
            const { payload } = await dispatch(saveSpeaker(prop));
            if (payload.includes(prop.id)) {
                onAddFinish(prop)
             } else {
                onRemoveFinish(prop)
            }
        } else {
                onUnathorized(prop)
        }
      },[isAuthenticated,onAddFinish]);
    
      return { toggleSpeakerLibrary, isSavedSpeaker, saved_speakers_ids, isAuthenticated };
}


export const useCoursesLibraryBase = ({ onAddFinish, onRemoveFinish, onUnathorized  } = defaultProps) => {
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated()
    const { saved_courses_ids } = useSelector(dashboardSelector);

    const isSavedCourse = useCallback((id) => {
     return saved_courses_ids.includes(id);
    },[saved_courses_ids]);

    const toggleCourseLibrary = useCallback(async (prop) => {
        if(isAuthenticated) {
            const { payload } = await dispatch(saveCourse(prop));
            if (payload.includes(prop.id)) {
                onAddFinish(prop)
             } else {
               onRemoveFinish(prop)
            }
        } else {
               onUnathorized(prop)
        }
      },[isAuthenticated]);
    
      return { toggleCourseLibrary, isSavedCourse, saved_courses_ids, isAuthenticated };
}
