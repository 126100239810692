import styles from "./SeriesDetailsPage.module.scss";
import { useParams } from "react-router-dom";
import { InnerPageHeader } from "../../../TopicsPage/components/InnerPageHeader/InnerPageHeader";
import { useRef } from "react";
import SearchablePodcastTable from "../../../../components/PodcastsTable/SearchablePodcastTable";
import { useCoursePage } from "@podcasts/hooks";

const SeriesDetailsPage = () => {
  const { slug } = useParams();
  const myRef: any = useRef(null);
  const { data: course = {}, isSavedCourse, onSaveCourse } = useCoursePage(slug);
    
  return (
    <div ref={myRef} className={`${styles["container"]}`}>
      <InnerPageHeader
        {...course}
        backBtn="courses"
        backUrl="/podcasts/courses"
        saveBtn="course"
        backgroundImg={course?.image}
        handleClick={onSaveCourse}
        isSaved={isSavedCourse(course.id)}
      />
      <SearchablePodcastTable params={{ course_slug: [slug] }}/>
    </div>
  );
};

export default SeriesDetailsPage;
