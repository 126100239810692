import PlayIcon from "../../../../../../components/PlayIcon/PlayIcon";
import styles from "./PodcastTrackItem.module.scss";
import { Link } from "react-router-dom";
import { useIsAuthenticated } from "@podcasts/hooks";

export function PodcastTrackItem({
  title,
  speaker,
  level,
  duration,
  saved,
  slug,
  onToggleFavorite = () => { },
}: any) {
  
  const isAuthenticated = useIsAuthenticated();
  return (
    <div
      className={`${styles["item"]} d-flex align-items-center justify-content-between`}
    >
      <div className="d-flex align-items-center">
        <button
          className={`${styles["star-btn"]} normalize-btn`}
        >
          {isAuthenticated && (
            <div onClick={onToggleFavorite} >
              <i className={`${saved ? "icon-full-star" : "icon-empty-star"}`}
              ></i>
            </div>
          )}
        </button>
        <div className={`${styles["podcast"]}`}>
          <p className={`${styles["podcast-title"]}`}>{title}</p>
          <p
            className={`${styles["podcast-info"]} d-flex align-items-center`}
          >
            <span>
              {speaker?.title} {speaker?.fname} {speaker?.lname}
            </span>
            {level && <><span className={`${styles["separator"]}`}></span>
              <span>{level}</span></>}
          </p>
        </div>
      </div>
      <div className={`${styles["play-box"]} d-flex align-items-center`}>
        <span className={`${styles["track-time"]}`}>{duration}</span>
        <Link to={`/podcasts/${slug}`}>
          <PlayIcon />
        </Link>
      </div>
    </div>
  );
}

export default PodcastTrackItem;
