import { useCallback, useState } from "react";
import { useCoursesLibraryBase } from './library-base';
import { useSavedCourses } from './api';
import { useCourseLibraryAppToast } from './useAppLibraryToast';
import { useCourseLibraryWebToast } from './useWebLibraryToast';

export const useSavedCoursesBasePage = (props) => {
  
    const {
      per_page = 12, 
      order_by = 'title', 
      defaultOrder = 'asc',
      onAddFinish,
      onRemoveFinish,
      onUnathorized,
    } = props;

    const [page,setPage] = useState(1);
    const [order, setOrder] = useState(defaultOrder)
    const { isSavedCourse, toggleCourseLibrary, isAuthenticated, saved_courses_ids }  = useCoursesLibraryBase({ onAddFinish, onRemoveFinish, onUnathorized});
    const { 
      data,
      fetchNextPage,
      isFetchingPreviousPage,
      isFetchingNextPage,
      isFetching,
      isRefetching } = useSavedCourses({ page, per_page, order_by, order, saved_courses_ids});
  
    const onNextPage = useCallback(() => {
      if (!isFetchingPreviousPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, [isFetchingPreviousPage, isFetchingNextPage]);
  
    const onSaveCourse = useCallback((item) => {
        toggleCourseLibrary(item)
    },[]);

    const onPageChange = useCallback((p) => {
      setPage(p)
     },[])


   const toggleOrder = useCallback(() => {
    setOrder(order === "asc" ? "desc" : "asc");
    setPage(1)
  },[order])
   
    return {
        data,
        order,
        setOrder,
        isSavedCourse,
        order,
        toggleOrder,
        fetchNextPage,
        isFetchingPreviousPage,
        isFetchingNextPage,
        isFetching,
        isRefetching,
        onNextPage,
        onPageChange,
        onSaveCourse,
        isAuthenticated
    }
}

export const useSavedCoursesPage = ({ per_page = 12, order_by = 'title', defaultOrder = 'asc' }) => {
  const toast = useCourseLibraryWebToast()
  return useSavedCoursesBasePage({ per_page, order_by, defaultOrder,...toast})
}

export const useSavedCoursesAppPage = ({ per_page = 12, order_by = 'title', defaultOrder = 'asc' }) => {
   const toast = useCourseLibraryAppToast();
   return useSavedCoursesBasePage({ per_page, order_by, defaultOrder, ...toast })
}