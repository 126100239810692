import FilterIcon from "../../../../../../components/FilterIcon/FilterIcon";
import styles from "./NoteItem.module.scss";
import moment from "moment";
import { DropDown } from "../../../../../../components";
import { MyEditor } from "../MyEditor/MyEditor";
import { useState } from "react";

export function NoteItem({
  note = "",
  created_at = "",
  is_sent = false,
  onDelete,
  onUpdate,
}: any) {
  const [isEdit, setIsEdit] = useState(false);
  const [editedNote, setEditedNote] = useState(note);

  const onDismiss = () => {
    setIsEdit(false);
    setEditedNote(note);
  };

  const onEdit = (value: string) => {
    setEditedNote(value);
  };

  return (
    <div className={styles["note"]}>
      <div
        className={`${styles["note-header"]} d-flex justify-content-between align-items-center`}
      >
        <div className={`${styles["date"]} d-flex align-items-center`}>
          <span>{moment(created_at).format("LL")}</span>
          {is_sent ? (
            <div className={styles["active"]}>
              <FilterIcon active={true} />
            </div>
          ) : null}
        </div>
        <div className={styles["action"]}>
          {isEdit ? (
            <i className="icon-close" onClick={onDismiss}></i>
          ) : (
            <DropDown
              list={[
                {
                  label: "Delete",
                  styles: { color: "red" },
                  onClick: onDelete,
                },
                {
                  label: "Edit",
                  onClick: () => {
                    setIsEdit(true);
                  },
                },
              ]}
              child={
                <button className="normalize-btn">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              }
            />
          )}
        </div>
      </div>
      {isEdit ? (
        <section>
          <MyEditor value={editedNote} onChange={onEdit}></MyEditor>
          <button
            onClick={() => {
              setIsEdit(false);
              onUpdate(editedNote);
            }}
          >
            Save
          </button>
        </section>
      ) : (
        <div
          className={styles["note-body"]}
          dangerouslySetInnerHTML={{ __html: note }}
        ></div>
      )}
    </div>
  );
}
