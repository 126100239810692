import styles from "./CircleOrangeImg.module.scss";

interface CircleOrangeImgProps {
  src: string;
}

export function CircleOrangeImg({ src = "" }: CircleOrangeImgProps) {
  return (
    <div className={`${styles["img-box"]}`}>
      <div>
        <img src={src} alt="" />
      </div>
    </div>
  );
}

export default CircleOrangeImg;
