import React from "react";
import styles from "./AboutPage.module.scss";

import imageLeft from '../../assets/images/about/left-img.jpg';
import imageMiddle from '../../assets/images/about/middle-img.jpg';
import imageRight from '../../assets/images/about/right-img.jpg';

export function AboutPage() {

  return (
    <div>
      <div className={`${styles["about-page"]} container mobile-container`}>
        <section className={styles["about-top-text"]}>
          <h1>
            <span className="orange-yellow-color">About</span> us
          </h1>
          <p className="text-center">
            Jewish ethics, morals and values have shaped and refined
            individuals, nations and societies throughout the generations.
            However, many Jews today have never had the opportunity to explore
            Jewish ethics, morals and values in a mature and sophisticated way.
          </p>
        </section>
        <section className={styles["about-pictures"]}>
          <div className="d-flex justify-content-between">
            <div className={styles["circle-img"]}>
              <img src={imageLeft} alt="" />
            </div>
            <div className={styles["triangle-img"]}>
              <img src={imageMiddle} alt="" />
              <svg width="0" height="0">
                <defs>
                  <clipPath id="triangle">
                    <path d="M75.0743 11.5441C82.2676 -2.04728 101.469 -2.77459 109.67 10.2337L202.237 157.065C210.919 170.837 200.387 188.659 184.134 187.696L19.0373 177.907C4.50898 177.046 -4.26389 161.45 2.54408 148.586L75.0743 11.5441Z" />
                  </clipPath>
                  <clipPath id="triangleMob">
                    <path d="M33.7077 6.27204C37.3044 -0.523654 46.905 -0.887296 51.0054 5.61686L91.5291 69.8961C95.8704 76.7823 90.6041 85.6934 82.4779 85.2116L10.2022 80.9263C2.93807 80.4956 -1.44835 72.6977 1.95563 66.266L33.7077 6.27204Z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className={styles["border-img"]}>
              <img src={imageRight} alt="" />
              <svg width="0" height="0">
                <defs>
                  <clipPath id="border">
                    <path d="M189.951 54.0643C186.942 31.2167 168.707 13.3847 145.798 10.8868L55.5114 1.04257C25.9272 -2.18309 0.0918732 20.9885 0.0918732 50.748V156.386C0.0918732 186.609 26.6997 209.923 56.6615 205.952L157.797 192.547C185.156 188.921 204.403 163.815 200.8 136.453L189.951 54.0643Z" />
                  </clipPath>
                  <clipPath id="borderMob">
                    <path d="M86.2686 20.0312C85.366 13.1769 79.8956 7.82732 73.0228 7.07797L17.0657 0.976772C8.19039 0.00907344 0.439804 6.96054 0.439804 15.8884V81.7203C0.439804 90.7874 8.42214 97.7816 17.4107 96.5902L80.1386 88.2762C88.3462 87.1884 94.1203 79.6564 93.0394 71.4479L86.2686 20.0312Z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </section>
        <section className={styles["about-questions"]}>
          <div className="row bs-row">
            <div className="col-md-6">
              <div className={`${styles["about-question"]} d-flex`}>
                <div className={styles["about-question-img"]}>
                  <span
                    className="icon-noun-podcast"
                    style={{ fontSize: "40px" }}
                  ></span>
                </div>
                <div className={styles["about-question-text"]}>
                  <h4>Why are we running Podcast Fellowship?</h4>
                  <p>
                    The Podcast Fellowship provides this opportunity for mature
                    exploration. By running Podcast Fellowship we seek to give
                    all Jews access to timeless Jewish wisdom.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`${styles["about-question"]} d-flex`}>
                <div className={styles["about-question-img"]}>
                  <span
                    className="icon-noun-audio"
                    style={{ fontSize: "32px" }}
                  ></span>
                </div>
                <div className={styles["about-question-text"]}>
                  <h4>What is the Podcast Fellowship?</h4>
                  <p>
                    The Podcast Fellowship is a paid educational framework
                    aiming to empower its participants with Jewish literacy. The
                    Fellowship allows advancement through the material at one’s
                    own pace, based on one’s own unique interests and
                    background.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`${styles["about-question"]} d-flex`}>
                <div className={styles["about-question-img"]}>
                  <span
                    className="icon-magen-david"
                    style={{ fontSize: "36px" }}
                  ></span>
                </div>
                <div className={styles["about-question-text"]}>
                  <h4>Who is Podcast Fellowship for?</h4>
                  <p>
                    The Podcast Fellowship is for Jewish college-aged students
                    and young professionals with limited or no Jewish
                    educational background who would like to enhance their life
                    and ability to represent Judaism and Jewish values.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`${styles["about-question"]} d-flex`}>
                <div className={styles["about-question-img"]}>
                  <span
                    className="icon-noun-torah"
                    style={{ fontSize: "50px" }}
                  ></span>
                </div>
                <div className={styles["about-question-text"]}>
                  <h4>Why become a Podcast Fellow?</h4>
                  <p>
                    Become a Podcast Fellow if you are interested in advancing
                    your Jewish literacy and earning a generous stipend.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AboutPage;
