import SidebarList from "./components/SidebarList/SidebarList";
import styles from "./RightSidebar.module.scss";
import { Route, Routes } from "react-router-dom";
import Notes from "./components/Notes/Notes";
import { AuthGuard } from "../../../../components/AuthGuard/AuthGuard";
import {
  useTopics,
  useSpeakers,
  useCourses,
  useSavedCourses,
  useSavedTopics,
  useSavedSpeakers,
  useSavedPodcasts,
} from "@podcasts/hooks";
import { useSelector } from "react-redux";
import { searchSelector } from "@podcasts/store/search";

let commonQuery: object = { page: 1, per_page: 3, order_by: "title", order: "asc" };

const TopicsSideBarItem = () => {
  const { level } = useSelector(searchSelector) as any;
  commonQuery = { ...commonQuery, level } as object;

  const {
    data: { data: topics = [] }
  } = useTopics(commonQuery);

  return(<SidebarList title={"Topics"} addressViewAll={"topics"} list={topics}/>)
}

const SpeakersSideBarItem = () => {
  const { level } = useSelector(searchSelector) as any;
  commonQuery = { ...commonQuery, level } as object;

  const {
    data: { data: speakers = [] }
  } = useSpeakers(commonQuery);

  return(<SidebarList title={"Speakers"} addressViewAll={"speakers"} list={speakers}/>)
}


const CoursesSideBarItem = () => {
  const { level } = useSelector(searchSelector) as any;
  commonQuery = { ...commonQuery, level } as object;

  const {
    data: { data: courses = [] }
  } = useCourses(commonQuery);

  return(<SidebarList title={"Courses"} addressViewAll={"courses"} list={courses}/>)
}

const SavedTopicsSideBarItem = () => {
  const {
    data: { data: topics = [] }
  } = useSavedTopics(commonQuery);

  return(<SidebarList title={"Saved Topics"} addressViewAll={"saved-topics"}  addressForPodcast={"topics"} list={topics}/>)
}

const SavedCoursesSideBarItem = () => {
  const {
    data: { data = [] }
  } = useSavedCourses(commonQuery);

  return(<SidebarList title={"Saved Courses"} addressViewAll={"saved-courses"}  addressForPodcast={"courses"} list={data}/>)
}

const SavedSpeakersSideBarItem = () => {
  const {
    data: { data: savedSpeakers = [] }
  } = useSavedSpeakers(commonQuery);

  return(<SidebarList title={"Saved Speakers"} addressViewAll={"saved-speakers"}  addressForPodcast={"speakers"} list={savedSpeakers}/>)
}


const SavedPodcastsSideBarItem = () => {
  const {
    data: { data: savedSpeakers = [] }
  } = useSavedPodcasts(commonQuery);

  return(<SidebarList title={"Saved Podcasts"} addressViewAll={"saved-podcasts"}  list={savedSpeakers}/>)
}


export function RightSidebar() {
  return (
    <div className={styles["right-sidebar"]}>
      <div className={styles["right-sidebar-top"]}>
        <Routes>
          <Route
            path={"featured"}
            element={<><TopicsSideBarItem/><SpeakersSideBarItem/></>}
          />
          <Route
            path={"courses"}
            element={<><TopicsSideBarItem /><SpeakersSideBarItem/></>}
          />
          <Route
            path={"speakers"}
            element={<><TopicsSideBarItem/><CoursesSideBarItem/></>}
          />
          <Route
            path={"topics"}
            element={<><SpeakersSideBarItem/><CoursesSideBarItem/></>}
          />
          <Route
            path={"search"}
            element={<><TopicsSideBarItem /><SpeakersSideBarItem /></>}
          />
          <Route
            path={"saved-podcasts"}
            element={<><SavedTopicsSideBarItem /><SavedSpeakersSideBarItem/></>}
          />
          <Route
            path={"saved-topics"}
            element={<><SavedCoursesSideBarItem /><SavedSpeakersSideBarItem/></>}
          />
          <Route
            path={"saved-courses"}
            element={<><SavedTopicsSideBarItem /><SavedSpeakersSideBarItem/></>}
          />
          <Route
            path={"saved-speakers"}
            element={<><SavedPodcastsSideBarItem/><SavedTopicsSideBarItem/></>}
          />
          <Route
            path={"recent"}
            element={<><SavedPodcastsSideBarItem/><SavedTopicsSideBarItem /></>}
          />
          <Route
            path={"courses/*"}
            element={<AuthGuard
              instead={
                <CoursesSideBarItem/>
              }>
              <SavedCoursesSideBarItem/>
            </AuthGuard>}
          />
          <Route
            path={"speakers/*"}
            element={<AuthGuard
              instead={
                <SpeakersSideBarItem />
              }>
              <SavedSpeakersSideBarItem/>
            </AuthGuard>}
          />
          <Route
            path={"topics/*"}
            element={<AuthGuard
              instead={
                <TopicsSideBarItem/>
              }>
              <SavedTopicsSideBarItem/>
            </AuthGuard>}
          />
          <Route
            path={":id"}
            element={<AuthGuard instead={
              <><TopicsSideBarItem /><SpeakersSideBarItem/></>
            }><Notes componentTitle="Notes" /></AuthGuard>}
          />
        </Routes>
      </div>
    </div>
  );
}

export default RightSidebar;