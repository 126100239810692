import Slider from "react-slick";
import styles from "./SliderFirst.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SliderFirstItem } from "./SliderFirsrItem";
import { useRef } from "react";

export function SliderFirst({ list = []}: any) {
 
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderRef: any = useRef();

  const ReactSlider = Slider as any;

  return (
    <div className="row bs-row">
      <div className="col-lg-4 col-md-6 col-sx-12">
        <h2 className={`${styles["title"]} orange-yellow-color`}>Topics</h2>
        <p className={`${styles["description"]}`}>
          Delve into a vibrant collection of timely and relevant Jewish topics,
          exploring contemporary issues, social justice, ethical dilemmas, and
          the intersection of tradition and modernity.
        </p>
        <div className={`${styles["btn-wrap"]}`}>
          <button
            onClick={() => {
              sliderRef.current.slickNext();
            }}
            className={`${styles["prev"]}`}
          >
            <i className="icon-chevron-right"></i>
          </button>
          <button
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
            className={`${styles["next"]}`}
          >
            <i className="icon-chevron-right"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-8 col-md-6 col-sx-12">
        <ReactSlider ref={sliderRef} className="first-slider" {...settings}>
          {list.map((s: any, i: number) => (
            <SliderFirstItem key={i} {...s} />
          ))}
        </ReactSlider>
      </div>
      <div className={`${styles["btn-wrap-mobile"]}`}>
        <button
          onClick={() => {
            sliderRef.current.slickNext();
          }}
          className={`${styles["prev"]}`}
        >
          <i className="icon-chevron-right"></i>
        </button>
        <button
          onClick={() => {
            sliderRef.current.slickPrev();
          }}
          className={`${styles["next"]}`}
        >
          <i className="icon-chevron-right"></i>
        </button>
      </div>
    </div>
  );
}
