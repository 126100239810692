import { range, map } from "lodash";
import styles from "./Pagination.module.scss";


export const Pagination = ({ pages = 0, page = 0, onPageChange }: any) => {
  return (
    <>
      {pages > 1 && (
        <div className={`${styles["pagination"]}`}>
          <div className={`${styles["arrow-box"]}`}>
            <button
              className={`${styles["prev"]} normalize-btn`}
              onClick={() => {
                onPageChange(page === 1);
              }}
              disabled={page === 1}
            >
              <i className="icon-chevron-right"></i>
              <i className="icon-chevron-right"></i>
            </button>
            <button
              className={`${styles["prev"]} normalize-btn`}
              onClick={() => {
                onPageChange(page - 1);
              }}
              disabled={page === 1}
            >
              <i className="icon-chevron-right"></i>
            </button>
          </div>

          <ul>
            {map(range(pages), (p, i) => (
              <li
                key={i}
                onClick={() => {
                  onPageChange(i + 1);
                }}
                className={page === i + 1 ? styles["active"] : ""}
              >
                {p + 1}
              </li>
            ))}
          </ul>

          <div className={`${styles["arrow-box"]}`}>
            <button
              className="normalize-btn"
              onClick={() => {
                onPageChange(page + 1);
              }}
              disabled={page === pages}
            >
              <i className="icon-chevron-right"></i>
            </button>
            <button
              className="normalize-btn"
              onClick={() => {
                onPageChange((page = pages));
              }}
              disabled={page === pages}
            >
              <i className="icon-chevron-right"></i>
              <i className="icon-chevron-right"></i>
            </button>
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default Pagination;
