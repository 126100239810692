import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

export const useLibraryWebToast = ({ addedTitle, removedTitle }) => {
 const navigate = useNavigate();

  const onAddFinish = useCallback(() => {
    showToastMessage(addedTitle);
  },[addedTitle]);


  const onRemoveFinish = useCallback(() => {
   showToastMessage(removedTitle);
   },[]);

  const onUnathorized = useCallback(() => {
    // toast.warn(`You need to be logged in to do this action`, {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //     className: "foo-bar",
    //     theme: "colored",
    //   });
    //   navigate("/login");
   },[]);

   return { onAddFinish, onRemoveFinish, onUnathorized }
}

export const showToastMessage = (message, type = "success", position = "TOP_RIGHT", hideProgressBar = false) => {
//  toast[type](message, {
//   position: toast.POSITION[`${position}`],
//   className: "foo-bar",
//   theme: "colored",
//   hideProgressBar,
//  });
}

export const useTopicLibraryWebToast = () => useLibraryWebToast({ addedTitle: "Added to saved topics", removedTitle: "Removed from saved topics" });
export const useSpeakerLibraryWebToast = () => useLibraryWebToast({ addedTitle: "Added to saved speakers", removedTitle: "Removed from saved speakers" });
export const useCourseLibraryWebToast = () => useLibraryWebToast({ addedTitle: "Added to saved courses", removedTitle: "Removed from saved courses" });
export const usePodcastLibraryWebToast = () => useLibraryWebToast({ addedTitle: "Added to saved podcasts", removedTitle: "Removed from saved podcasts" });
