import { Link } from "react-router-dom";
import styles from "./ReferralsTable.module.scss";
import moment from "moment";

interface ReferralsTableProps {
  header: any[];
  rows: any[];
  order: string;
  setOrder: Function;
  setOrderBy: Function;
}

export function PaymentsTable({ header = [], rows = [], order, setOrder, setOrderBy }: ReferralsTableProps) {
  const handlePaymentOrder = (order, orderBy) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  }

  return (
    <div className={`${styles["profile-payments-table"]}`}>
      <table className="table table-hover mb-0">
        <thead>
          <tr>
            {header.map((th: any, i) => (
              <th
                key={i}
                scope="col"
              >
                {th.filter ? (
                  <button className={`${styles["filter-btn"]}`} onClick={() => handlePaymentOrder(order, th.order)}>
                    {th.name}&nbsp;
                    {th.filter && (
                      <div className={`${order === "desc" ? styles["up"] : styles["down"]}`}>
                        <i className="icon-chevron-sown"></i>
                      </div>
                    )}
                  </button>
                ) : th.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((br: any, i: number) => (
            <tr key={i}>
              <td scope="row">{br.created_at}</td>
              <td>{moment(br.refer.acceptance_date).format("L")}</td>
              <td>${br.amount}</td>
              <td>{br.refer.user.name}</td>
              <td>{br.refer.school}</td>
              <td>{br.refer.city}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={`${styles["load-box"]}`}>
        <Link
          to={"#"}
          className="blue-icon-btn d-flex justify-content-center align-items-center"
        >
          <span>Loading more</span>
          <i className="icon-loading"></i>
        </Link>
      </div>
    </div>
  );
}

export default PaymentsTable;
