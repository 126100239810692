import React from "react";
import styles from "./EditImage.module.scss";
import CircleOrangeImg from "../../../../../../components/CircleOrangeImg/CircleOrangeImg";

export function EditImage({ image, onClick, edit = false }: any) {
  return (
      <div className={`${styles["img-box"]}`}>
        <div className={`${styles["img-radius"]}`} >
          <img src={image} alt="" className={`${edit && styles["edit"]}`} />
          <svg width="0" height="0">
            <defs>
              <clipPath id="profile">
                <path d="M156.775 34.3395C155.276 22.9142 146.162 13.9934 134.707 12.7397L27.7527 1.03387C12.9572 -0.585455 0.0327301 11.0016 0.0327301 25.8855V152.476C0.0327301 167.593 13.3449 179.251 28.3298 177.258L148.263 161.301C161.931 159.483 171.547 146.94 169.753 133.268L156.775 34.3395Z" />
              </clipPath>
            </defs>
          </svg>
          {edit && (
            <div className={`${styles["edit-box"]}`}>
              <p>Choose your image</p>
              <p>
                Recommended size: <span>500x500</span>
              </p>
              <input type='file'  className={`${styles["file-input"]}`} onChange={(e:any) => { 
                const value = URL.createObjectURL(e.target.files[0]);
                onClick(value)
              }} />
            </div>
          )}
        </div>
        <div className={`${styles["img-edit-mobile"]}`}>
          <CircleOrangeImg src="https://www.blexar.com/avatar.png" />
          <button className="normalize-btn">
            <i className="icon-edit"></i>
          </button>
        </div>
      </div>
  );
}
