import Button from "../../../../../../components/Button/Button";
import styles from "./FeaturedSliderItem.module.scss";
import { Link } from "react-router-dom";
interface FeaturedSliderItemProps {
  title: string;
  image: string;
  userImg: string;
  speaker: any;
  id: number;
  slug: string;
  topics: any;
}

export function FeaturedSliderItem({
  title,
  image,
  userImg,
  speaker,
  id,
  slug,
  topics,
}: FeaturedSliderItemProps) {
  return (
    <div className={`${styles["slide"]} orange-after-box`}>
      <div style={{ backgroundImage: `url('${topics[0]?.image}')` }}>
        <div className="d-flex align-items-end justify-content-between">
          <div className={`${styles["slide-info"]}`}>
            <p className={`${styles["top-topic"]}`}>
              {topics[0]?.title}
            </p>
            <p className={`${styles["track-name"]}`}>{title}</p>
            <div className={`${styles["author"]} d-flex align-items-center`}>
              <div className={`${styles["author-avatar"]}`}>
                <img src={speaker?.image} alt={`${speaker?.title} ${speaker?.fname} ${speaker?.lname}`} />
              </div>
              <p>
                {speaker?.title} {speaker?.fname} {speaker?.lname}
              </p>
            </div>
          </div>
          <div className={`${styles["slide-btn"]}`}>
            <Link to={`/podcasts/${slug}`}>
              <Button name="Listen now" onClick={() => {}} white={true} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedSliderItem;
